<script>
    import {accessToken, games, gameTypes, featureFlags, RELATIVE_PATH, IS_ELECTRON, validAccessToken, userKV, leagueUsers} from '../stores.js';

    import Loading from './Loading.svelte';

    import { onMount, createEventDispatcher } from "svelte";
    import { cubicOut } from 'svelte/easing';
    import { fade} from 'svelte/transition';
    import { isDef } from '../utils.js';
    import { getServerIdMap } from '../pages/gameserver/data.js'

    let ready = false;
    let rootElement = null;

    $: $IS_ELECTRON && rootElement && rootElement.style.setProperty('--login-background', `url("${$RELATIVE_PATH}/bg.jpg")`);

    const dispatch = createEventDispatcher();

    onMount( async() => {
        let initRequests = [getGames(), getGameTypes(), getFeatureFlags(), getKV(), getLeagueUsers()];
        if (isDef($accessToken) && $validAccessToken) {
            initRequests.push(getServerIdMap($accessToken));
        }
        await Promise.all(initRequests);

        setTimeout(() => ready = true, 50);

        dispatch('finishInitialize')
    })

    async function getGames() {
		const response = await fetch('http://localhost:8000/api/games');
		if (response.ok) {
			games.set(await response.json());
		}
        return Promise.resolve(true)
	}

	async function getGameTypes() {
		const response = await fetch('http://localhost:8000/api/gameTypes')
		if (response.ok) {
			gameTypes.set(await response.json());
		}
        return Promise.resolve(true)
	}

    async function getFeatureFlags() {
        let headers = {};
        if (isDef($accessToken) && $validAccessToken) {
            headers['Authorization'] = 'Bearer ' + $accessToken
        }

        const response = await fetch('http://localhost:8000/api/featureFlags', {
            headers: headers
        });

        if (response.ok) {
            featureFlags.set(await response.json());
        }

        return Promise.resolve(true)
    }

    async function getLeagueUsers() {
        const response = await fetch('http://localhost:8000/api/users/league');
        if (response.ok) {
            let respBody = await response.json();
            for (const lu of respBody['league_users']) {
                $leagueUsers[lu['id']] = lu;
            }
        }
    }

    async function getKV() {
        if (!isDef($accessToken) || !$validAccessToken) {
            return;
        }

        const response = await fetch('http://localhost:8000/api/user/kv', {
            headers: {
                'Authorization': 'Bearer ' + $accessToken
            }
        });

        if (response.ok) {
            const body = await response.json();
            console.log(body);
            userKV.set(body);
        }

        return Promise.resolve(true)
    }

    $: $accessToken && getFeatureFlags() && getKV();

    $: ready && getFeatureFlags($userKV.beta);
</script>

<style>
    div {
        z-index: 999999;
        background-color: var(--background-dark);
        width: 100%;
        height: 100%;
        position: fixed;
        padding-top: calc(var(--nav-height) + var(--electron-titlebar-height) + 64);
        top: 0;
        left: 0;
        text-align: center;
        vertical-align: center;
        background-image: var(--login-background);
		background-repeat: repeat;
		background-size: 1000px;
		background-position: 0px 0px;
        /* animation: animatedBackground 30s ease-out infinite alternate; */
    }
</style>

{#if !ready}
    <div out:fade|global={{duration: 600, easing: cubicOut}} bind:this={rootElement} class="scrolling-bg">
        <Loading/>
    </div>
{/if}
