<script lang="ts">
    import { createEventDispatcher } from 'svelte';
    import { accessToken } from '../stores.js';

    enum UploadStatus {
        Waiting,
        Pending,
        Error,
        Done
    }

    const dispatch = createEventDispatcher();

    export let status: UploadStatus = UploadStatus.Waiting;
    export let label: string = "File"
    export let accept: string = "*";

    let fileInput;
    let data: object;
    let files: FileList;
    $: if (files !== undefined) {
        upload()
    }

    async function upload() {
        const formData = new FormData();
        formData.append('file', files[0]);

        status = UploadStatus.Pending
        const upload = await fetch('http://localhost:8000/api/upload', {
            method: 'POST',
            body: formData,
            headers: {
                Authorization: `Bearer ${$accessToken}`
            }
        });

        if (upload.status == 200) {
            data = await upload.json();
            status = UploadStatus.Done;
            dispatch('data', {
                data: data
            });
        }
    }
</script>

<style>
    input {
        display: none;
    }
    label {
        border: var(--input-border-width) solid var(--input-border-color);
        padding: 0.5em;
    }
</style>

{#if status !== UploadStatus.Done}
{#if status === UploadStatus.Waiting}
    <label>
        <input type="file" accept={accept} bind:files>
        Upload {label}
    </label>
    {:else if status === UploadStatus.Pending}
        &#8987; Uploading
    {:else if status === UploadStatus.Error}
        &#58163; Error
    {/if}
{:else}
    &#9989; Uploaded
{/if}