<script>
    import { onMount } from "svelte";

    import {accessToken} from '../../stores.js';

    import {getServerIdMap} from '../../pages/gameserver/data.js'

    import Ping from './Ping.svelte'

    async function checkPings() {
        let headers = {
            'Authorization': 'Bearer ' + $accessToken
        }
        if ('ENVIRONMENT' == 'local') { // check for environment
            headers['X-Forwarded-For'] = '127.0.0.1'
        }
        const response = await fetch('http://localhost:8000/api/ping', {
            'method': 'HEAD',
            headers: headers
        })

        if (response.status == 404) {
            return false
        } else {
            return true
        }
    }

    async function savePings() {
        const response = await fetch('http://localhost:8000/api/ping', {
            'method': 'POST',
            headers: {
                'Authorization': 'Bearer ' + $accessToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'pings': pingMap
            })
        })

        if (response.status != 200) {
            console.error("Request to save pings failed")
        }
    }

    let exists = true
	onMount(async () => {
        await getServerIdMap($accessToken)
        exists = await checkPings();
    })

    let pingMap = {};
</script>

{#if !exists}
    <Ping bind:pingMap on:finish={async () => savePings()} startIn={5000}/>
{/if}    
