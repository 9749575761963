<script lang="ts">
    import {isMobile, RELATIVE_PATH} from '../../stores.js';
    import {tooltip} from '../Tooltip';
    import {Link} from 'svelte-routing';
    import Avatar from '../Avatar.svelte';
    import UserLink from '../UserLink.svelte';
    import TimeSince from '../TimeSince.svelte';
    import {isDef} from '../../utils.js';
    
    // Primary Information
    export let locked;
    export let pinned;
    export let deleted;

    export let hrefSection: string = null;
    export let section: string = null;
    export let numberThreads: number = null;
    export let numberPosts: number = null;
    
    // Primary User
    export let creatorUserID: number = null;
    export let creator: string = null;
    export let creatorAvatarHash: string = null;
    export let creatorOnline: boolean = false;

    // Preview Information
    export let thread: string = null;
    export let threadLink: string = null;
    export let lastPost: string = null;

    // Preview User
    export let posterUserID: number = null;
    export let poster: string = null;
    export let posterAvatarHash: string = null;
    export let posterOnline: boolean = false;
</script>

<style>
    div {
        display: flex;
        /* flex-direction: column; */
        justify-content: stretch;
        align-items: center;
        background-color: var(--background-dark);
        padding: 0.5em;
        margin: 0.5em;
        border-radius: 3px;
    }
    .item {
        display: inline-block;
        width: 25%;
    }

    .avatar {
        display: flex;
        /* justify-content: center; */
        align-items: center;
    }
</style>

<div class="grid">
    <span class="col-5">
        {#if locked}
            <img src="{$RELATIVE_PATH}/icons/lock.svg" alt="Locked" height="16" use:tooltip={"Locked"}/>
        {/if}

        {#if pinned}
            <img src="{$RELATIVE_PATH}/icons/pinned.svg" alt="Pinned" height="18" use:tooltip={"Pinned"}/>
        {/if}

        {#if deleted}
            <img src="{$RELATIVE_PATH}/icons/trash.svg" alt="Deleted" height="16" use:tooltip={"Deleted"}/>
        {/if}

        <!-- {#if locked || pinned || deleted}
            <br/>
        {/if} -->
        <Link to={hrefSection}><strong>{section}</strong></Link>
        {#if creator !== null}
            <br>
            <UserLink avatar={creatorAvatarHash} username={creator} userId={creatorUserID} online={creatorOnline}/>
        {/if}
    </span>

    {#if numberThreads !== null}
        <span class="col-2">
            {numberThreads} Threads
        </span>
    {/if}

    {#if !$isMobile && numberPosts !== null}
        <span class="col-2">
            {numberPosts} Posts
        </span>
    {/if}

    <span class="col-5">
        {#if thread !== null || lastPost !== null}
            <span class="right">
                <Link to={threadLink}>
                    {#if thread !== null}
                        {thread}<br/>
                    {/if}

                    {#if isDef(lastPost)}
                        <TimeSince dateString={lastPost}/><br/>
                    {/if}
                </Link>
                <UserLink avatar={posterAvatarHash} username={poster} userId={posterUserID} online={posterOnline}/>
            </span>
        {:else}
            <span class="right">No posts yet...</span>
        {/if}
    </span>
</div>
