<script>
    import { tap } from 'svelte-gestures';

    import {sidebarOpen, isMobile, RELATIVE_PATH} from '../stores.js';

    function clickHandle(e) {
        sidebarOpen.set(!($sidebarOpen));
    }
</script>

{#if $isMobile}
    <button id="sidebar-toggle" use:tap on:tap={clickHandle}>
        <img src="{$RELATIVE_PATH}/icons/tribar.svg" alt="Menu" width="20" height="20"/>
    </button>
{:else}
    <button id="sidebar-toggle" on:click={clickHandle}>
        <img src="{$RELATIVE_PATH}/icons/tribar.svg" alt="Menu" width="20" height="20"/>
    </button>
{/if}
