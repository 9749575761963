<script>
    import { accessToken, userData, notifications, socket, socketConnected, currentLobby, lobbyMessages, ticket, IS_ELECTRON, ipc, inGame, inGameId, launch } from '../stores.js';
	import { validateAccessToken, logout } from '../Auth.js';
    import { OnlineStatus } from '../models/Enums.ts'

    import { navigate } from 'svelte-routing';
    import io from 'socket.io-client';

    $: if ($accessToken != null && $IS_ELECTRON != null && validateAccessToken($accessToken)) {
        if ($socket != null) {
            $socket.disconnect();
            socket.set(null);
        }
        if ('ENVIRONMENT' != 'local') {
            connectSocket();
        }
    } else if ($socket && $socket != null && $socket.connected) {
        $socket.close();
        socket.set(null);
    };

    $: console.log("IS_ELECTRON:", $IS_ELECTRON)

    function connectSocket() {
        const queryParams = {
            accessToken: $accessToken,
        }
        if ($IS_ELECTRON) {
            queryParams.isElectron = $IS_ELECTRON;
        }
        socket.set(io('SOCKET_IO_URL', {
            path: '/ws/socket.io',
            query : queryParams,
            extraHeaders: {
                'X-Forwarded-For': '127.0.0.1',
                'justatest': 'lol'
            },
            transports: ['websocket']
        }));

        $socket.on('connect', (_) => {
            socketConnected.set(OnlineStatus.Online);
            console.log("Connected");
            if ($currentLobby !== null) {
                try {
                    $socket.emit('lobbyp', $currentLobby.match.match.id, {p: 'join-lobby'});
                } catch (exception) {
                    console.error(exception)
                }
            }
        });

        $socket.on('disconnect', (_) => {
            socketConnected.set(OnlineStatus.Disconnected);
            console.debug("Disconnected");
        });

        $socket.on('invite', (invite) => {
            console.log("invite");
            console.log(invite);
            console.log($userData);
            userData.update(userdata => {
                userdata.invites.push(invite);
                return userdata;
            });
        });

        $socket.on('invite-response', (response) => {
            console.log("invite-response");
            console.log(response);
        });

        $socket.on('notification', (message) => {
            console.log("notification");
            console.log(message);
            message.d = new Date().getTime();
            console.log(message);
            notifications.update(n => {
                n.unshift(message);
                return n;
            });
        });

        $socket.on('lobby-launch', (message) => {
            console.log(message);
            $ipc.send('foreground');
            navigate(`/match/${message['match_id']}/lobby`, { replace: false });
        });

        // $socket.on('lobby-players', function(players) {
        //     lobbyPlayers.set([...players])
        // });

        // $socket.on('enter-lobby', function(userId) {
        //     lobbyPlayers.set([...$lobbyPlayers, userId]);
        // });

        // $socket.on('leave-lobby', function(userId) {
        //     lobbyPlayers.set($lobbyPlayers.filter(id => id !== userId));
        // });

        $socket.on('get-ticket', function(newTicket) {
            console.log(newTicket);
            ticket.set(newTicket);
        });

        $socket.on('lobbyp', function(protocolMessage) {
            if (protocolMessage) {
                // Message queue FIFO
                if (Array.isArray(protocolMessage)) {
                    lobbyMessages.set([...protocolMessage.reverse(), ...$lobbyMessages]);
                } else {
                    lobbyMessages.set([protocolMessage, ...$lobbyMessages]);
                }
            }
        });

        $socket.on('logout', function() {
            console.log("Another session detected, logging out user.");
            logout();
        });

        $socket.on('demo', function(message) {
            $ipc.send('playDemo', message.game_id, message.ticket)
        });

        $socket.on('connect-server', function(message) {
            if (!$inGame) {
                inGameId.set(message.game_id);
                ticket.set(message.ticket);
                launch.set(true);
            }
        });
    }
</script>
