<script>
    import {tooltip} from './Tooltip'

    import { onDestroy, onMount } from 'svelte';

    export let date = null;
    export let dateString = null
    export let short = false

    let secondsPast = null;
    let interval = null;
    let intervalMilliseconds = 1000;

    const minuteSec = 60;
    const hourSec = 3600;

    onMount(() => {
        if (dateString && !date) {
            date = new Date(`${dateString}Z`)
        }
        secondsPast = (Date.now() - date.getTime()) / 1000;
        updateInterval();
    });

    onDestroy(() => {
        clearInterval(interval);
        interval = null;
    });

    function updateInterval() {
        if (interval != null) {
            clearInterval(interval)
        }
        interval = setInterval(intervalHandler, intervalMilliseconds);
    }

    function intervalHandler() {
        if (secondsPast > minuteSec && secondsPast < hourSec && intervalMilliseconds != minuteSec * 1000) {
            intervalMilliseconds = minuteSec * 1000;
            updateInterval();
        } else if (secondsPast > hourSec && intervalHandler != hourSec * 1000) {
            intervalMilliseconds = hourSec * 1000;
            updateInterval();
        }
        secondsPast = (Date.now() - date.getTime()) / 1000;
    }

    function timeSince(secondsPast) {
        if(secondsPast < 60){
            const seconds = parseInt(secondsPast)
            return seconds == 1 ? '1 second ago' : `${seconds} seconds ago`;
        }
        if(secondsPast < 3600){
            const minutes = parseInt(secondsPast/60)
            return minutes == 1 ? '1 minute ago' : `${minutes} minutes ago`;
        }
        if(secondsPast < 86400){
            const hours = parseInt(secondsPast/3600)
            return hours == 1 ? '1 hour ago' : `${hours} hours ago`;
        }
        if(secondsPast < 604800){
            const days = parseInt(secondsPast/86400)
            return days == 1 ? '1 day ago' : `${days} days ago`
        }
        if(secondsPast < 2419200){
            const weeks = parseInt(secondsPast/604800)
            return weeks == 1 ? '1 week ago' : `${weeks} weeks ago`
        }
        if(secondsPast < 29030400) {
            const months = parseInt(secondsPast/2419200)
            return months == 1 ? '1 month ago' : `${months} months ago`
        }
        if(secondsPast >= 29030400) {
            const years = parseInt(secondsPast/29030400)
            return years == 1 ? '1 year ago' : `${years} years ago`
        }
    }

    function timeSinceShort(secondsPast) {
        if(secondsPast < 60){
            const seconds = parseInt(secondsPast)
            return `${seconds}s`;
        }
        if(secondsPast < 3600){
            const minutes = parseInt(secondsPast/60)
            return `${minutes}m`;
        }
        if(secondsPast < 86400){
            const hours = parseInt(secondsPast/3600)
            return `${hours}h`;
        }
        if(secondsPast < 604800){
            const days = parseInt(secondsPast/86400)
            return `${days}D`
        }
        if(secondsPast < 2419200){
            const weeks = parseInt(secondsPast/604800)
            return `${weeks}W`
        }
        if(secondsPast < 29030400) {
            const months = parseInt(secondsPast/2419200)
            return `${months}M`
        }
        if(secondsPast >= 29030400) {
            const years = parseInt(secondsPast/29030400)
            return `${years}Y`
        }
    }
</script>

{#if date}
    <span use:tooltip={date.toLocaleString()} class="nowrap">
        🕑{short ? timeSinceShort(secondsPast) : timeSince(secondsPast)}
    </span>
{/if}
