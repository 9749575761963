<script lang="ts">
    import {Link} from 'svelte-routing';
    import Avatar from '../../components/Avatar.svelte';
    import UserLink from '../../components/UserLink.svelte';
    import Editor from '../../components/forum/Editor.svelte';
    import Loading from '../../components/Loading.svelte';
    import Page404 from '../Page404.svelte'
    import TimeSince from '../../components/TimeSince.svelte';

    import {checkThreadAdminPermission} from '../../models/Enums.ts';

    import {accessToken, accessTokenBody, validAccessToken, userData, RELATIVE_PATH} from '../../stores.js';

    export let gameSlug = null;
    export let sectionSlug = null;
    export let threadSlug = null;

    let edit = null;
    let reply = false;
    let data = null;
    let posts = null;

    async function getThread() {
        const headers = {}
        if ($validAccessToken) {
            headers['Authorization'] = 'Bearer ' + $accessToken
        }

        const response = await fetch(`http://localhost:8000/api/forum/${gameSlug}/${sectionSlug}/${threadSlug}`, {
            headers: headers
        })

        if (response.ok) {
            data = await response.json()
            posts = data.posts
        } else if (response.status === 404) {
            throw 'Thread not found'
        }
    }

    async function toggleFlag(threadId: number, operation: string) {
        const response = await fetch(`http://localhost:8000/api/forum/thread/${threadId}/${operation}`, {
            method: 'PUT',
            headers: {
                authorization: 'Bearer ' + $accessToken
            }
        })
        
        if (response.ok) {
            switch (operation) {
                case 'delete':
                    data['info']['deleted'] = !data['info']['deleted'];
                    break;
                case 'pin':
                    data['info']['pinned'] = !data['info']['pinned'];
                    break;
                case 'lock':
                    data['info']['locked'] = !data['info']['locked'];
                    break;
            }
        }
    }

    function postUpdated(event, data, index) {
        edit = null;
        data['posts'][index]['post'] = event.detail;
    }

    function postCreated(event) {
        reply = null;
        console.log(event.detail);
        posts = [...posts, event.detail];
    }
</script>

<style>

    .post h1 {
        margin: none !important;
        margin-top: 0 !important;
    }
    .post {
        background-color: var(--background-dark);
        padding: 1em;
        margin: 0.5em;
    }

    .poster {
        display: flex;
        align-items: center;
    }

    hr {
        margin-bottom: 1em;
        margin-top: 0.5em;
        display: block;
        height: 1px;
        border: 0;
        border-top: 1px solid #333;
        margin: 1em 0;
        padding: 0;
    }
</style>

{#await getThread()}
    <Loading/>
{:then _}
    <div>
        <div style="margin: 0.5em;">
            <Link to="/forum">Forum</Link> /
            {#if data['info']['game_name'] === null}
                FTW /
            {:else}
                <Link to="/game/{gameSlug}">{data['info']['game_name']}</Link> /
            {/if}
            <Link to="/forum/{gameSlug}/{sectionSlug}">{data['info']['section']}</Link> /
        </div>

        <h1>{data['info'].thread}</h1>

        {#if $validAccessToken && checkThreadAdminPermission($accessTokenBody['r'], data['permissions'].filter(p => p['permission'] == 'delete_thread'))}
            <button class="button" on:click={() => toggleFlag(data['info']['id'], 'delete')}>
                <img src="{$RELATIVE_PATH}/icons/lock.svg" alt="Locked" height="16" class="inline sub"/>&nbsp;
                {#if data['info']['deleted']}
                    Undelete
                {:else}
                    Delete
                {/if}
            </button>
        {/if}
        {#if $validAccessToken && checkThreadAdminPermission($accessTokenBody['r'], data['permissions'].filter(p => p['permission'] == 'pin_thread'))}
            <button class="button" on:click={() => toggleFlag(data['info']['id'], 'pin')}>
                <img src="{$RELATIVE_PATH}/icons/pinned.svg" alt="Pinned" height="18" class="inline sub"/>&nbsp;
                {#if data['info']['pinned']}
                    Unpin
                {:else}
                    Pin
                {/if}
            </button>
        {/if}
        {#if $validAccessToken && checkThreadAdminPermission($accessTokenBody['r'], data['permissions'].filter(p => p['permission'] == 'lock_thread'))}
            <button class="button" on:click={() => toggleFlag(data['info']['id'], 'lock')}>
                <img src="{$RELATIVE_PATH}/icons/trash.svg" alt="Deleted" height="16" class="inline sub"/>&nbsp;
                {#if data['info']['locked']}
                    Unlock
                {:else}
                    Lock
                {/if}
            </button>
        {/if}
    </div>

    {#each posts as post, i}
        {@const canEdit = $userData && $validAccessToken && post['username'] == $userData['username'] || ($accessTokenBody && $accessTokenBody.r == 'admin')}
        
        <div class="post">
            <div style="display: flex; flex-direction: row; content-align: center; justify-content: space-between;">
                <UserLink avatar={post['avatar_hash']} username={post['username']} userId={post['user_id']} online={post['user_online']}/>
                
                <span>
                    <TimeSince dateString={post['date_created']}/>
                </span>
                </div>
            <hr/>
            {#if canEdit}
                {#if edit === post['id']}
                    <Editor edit={edit === post['id']} threadId={data['info']['id']} text={post['post']} postId={post['id']} on:postUpdated={(e) => postUpdated(e, data, i)}/>
                {/if}
            {/if}
            {#if edit !== post['id']}
                <div>
                    {@html post['post']}
                </div>
            {/if}

            <hr/>

            {#if true || $validAccessToken}
                <button class="button" on:click={() => {reply = post['id']; edit = null}}>Reply</button>
            {/if}
            {#if reply == post['id']}
                <Editor threadId={data['info']['id']} on:postCreated={(e) => postCreated(e)}/>
            {/if}
            {#if true || canEdit}
                <button class="button" on:click={() => {edit = post['id']; reply = null;}}>Edit</button>
            {/if}
        </div>
    {/each}

    {#if true || $validAccessToken}
        <button class="button" on:click={() => {reply = true; edit = null}}>Reply</button>

        {#if reply === true}
            <Editor threadId={data['info']['id']} on:postCreated={(e) => postCreated(e)}/>
        {/if}
    {/if}
{:catch err}
    <Page404 item="thread"/>
{/await}