<script>
    import {RELATIVE_PATH, globalLoadingAnimation} from '../stores.js';
	import { fade } from 'svelte/transition';
</script>

<style>
    .global-loading-animation {
        display: inline-block;
        position: absolute;
        left: calc(50vw - 64px);
        top: 25vh;
        width: 128px;
        height: 128px;
        user-select: none;
		z-index: 999999999999;
        pointer-events: none;
    }
</style>

{#if $globalLoadingAnimation > 0}
	<img src="{$RELATIVE_PATH}/loading.svg" alt="Loading" class="global-loading-animation" transition:fade|global={{duration: 1000, }}/>
{/if}
