<script>
    import { accessToken, RELATIVE_PATH, userData } from '../stores.js';
    import { deslugify, isDef, titlecase } from '../utils.js';
    import { navigate } from 'svelte-routing';

    import Page404 from './Page404.svelte';

    import Page from '../components/Page.svelte';
    import NavLink from '../components/NavLink.svelte';
    import Loading from '../components/Loading.svelte';
    import MatchScroller from '../components/MatchScroller.svelte';
    import FeatureFlag from '../components/FeatureFlag.svelte';
    import LikeButton from '../components/LikeButton.svelte';
    import UserLink from '../components/UserLink.svelte';
    import TimeSince from '../components/TimeSince.svelte';
    import UserSeasons from '../components/stats/UserSeasons.svelte';
    import GameLog from '../components/stats/GameLog.svelte';

    export let teamSlug = '';
    export let page = null;

    let team = null;
    let members = null;
    let loading = true;

    let inviteUsername = '';
    let error = undefined;

    const rolesOrder = ['leader', 'member', 'invited', 'inactive'];

    export async function getTeam(teamSlug) {
        const response = await fetch('http://localhost:8000/api/team/' + teamSlug);
        loading = false;
        if (response.ok) {
            const teamResponse = await response.json()
            team = teamResponse.team;
            members = teamResponse.members
        } else {
            const body = await response.json();
            error = body.detail;
        }
    }

    $: getTeam(teamSlug)

    $: console.log(team)

    $: myTeam = team != null && $userData != null && $userData.teams.length && $userData.teams.filter(myTeam => myTeam.id == team.id).shift();
    $: role = myTeam && myTeam.role;
    $: invited = $userData && team != null && $userData.invites.filter(invite => team.id == invite.id).length > 0;
    $: console.log(myTeam);
    $: console.log(role);

    async function invite(e) {
        e.preventDefault();
        const response = await fetch('http://localhost:8000/api/team/invite', {
            method: 'POST',
            body: JSON.stringify({team_id: team.id, username: inviteUsername}),
            headers: {
                'Authorization': 'Bearer ' + $accessToken
            }
        });
        if (response.ok) {
            navigate('/team/' + teamSlug);
        } else {
            const body = await response.json();
            error = body.detail;
        }
    }
    
    async function removeMember(event) {
        const split = event.target.id.split(':');
        const user_id = split[1];
        const confirm_message = split[0] == 'quit' ? 
            ("Are you sure you want to quit: " + team.team_name) : 
            'Are you sure you want to remove: ' + members.filter(user => user.id = user_id)[0].username;
        if (confirm(confirm_message)) {
            const response = await fetch('http://localhost:8000/api/team/remove', {
                method: 'DELETE',
                body: JSON.stringify({team_id: team.id, user_id: user_id}),
                headers: {
                    'Authorization': 'Bearer ' + $accessToken
                }
            });
            if (response.ok) {
                console.log(members)
                members = members.filter(member => member.user_id != user_id);
                console.log(members)
            } else {
                const body = await response.json();
                console.log(body);
            }
        }
    }

    async function inviteUpdate(event) {
        if (parseInt(event.detail.text) == team.id) {
            await getTeam(teamSlug);
        }
    }
</script>

<style>
    ul {
        list-style: none;
    }
</style>

<Page title={isDef(team) ? `${team.team_name} (${team.tag})` : 'Team'}>
    {#if loading}
        <Loading/>
    {:else if team == null}
        <Page404 item='team'/>
    {:else}
        {#if page == null}
            <h1>{team.team_name} ({team.tag}) <LikeButton likeType="team" likeId={team.id}/></h1>
            <!-- {#if role == 'leader' || role == 'captain'}
                <NavLink to="/team/{teamSlug}/modify"><span class="button">Modify Team ✏️</span></NavLink>
                <NavLink to="/team/{teamSlug}/invite"><span class="button">Invite member ➕</span></NavLink>
            {/if}
            {#if $userData != null && team != null && members != null}
                {#if members.filter(member => member.user_id == $userData.id).length > 0 && role != 'leader' && !invited}
                    <button id="{'quit:' + $userData.id}" on:click={removeMember} class="button">Quit Team ✌️</button>
                {/if}
                {#if invited}
                    <span class="badge">Invited</span>
                    <InviteButtons teamId={team.id} on:update={inviteUpdate}/>
                {/if}
            {/if} -->
            <table>
                <thead class="headings">
                    <tr>
                        <th>Member</th>
                        <th>Status</th>
                        <th>Join Date</th>
                    </tr>
                </thead>
                <tbody>
                    {#each members.sort((a, b) => {
                        // Sort by role first
                        const aIndex = rolesOrder.indexOf(a.role);
                        const bIndex = rolesOrder.indexOf(b.role);
                        if (aIndex !== bIndex) {
                          return aIndex - bIndex;
                        }
                        // If the roles are the same, sort by date_joined in ascending order
                        return new Date(`${a.join_date}Z`) - new Date(`${b.join_date}Z`);
                      }) as member}
                        <tr>
                            <td class="left">
                                <!-- <OnlineIndicator status={member.online ? OnlineStatus.Online : OnlineStatus.Offline}/> -->
                                <UserLink username={member.username} avatar={member.avatar} online={member.online}/>
                            </td>
                            <td>
                                {#if member.role == 'leader'}
                                    <img src="{$RELATIVE_PATH}/icons/crown.svg" alt="Leader" height="20" style="vertical-align: sub;"/>
                                {/if}
                                {titlecase(member.role)}
                            </td>
                            <td><TimeSince dateString={member.join_date}/></td>
                            {#if $userData != null && role == 'leader' && member.user_id != $userData.id}
                                <button id="{'remove_user:' + member.user_id}" on:click={removeMember} class="button">Remove 🗑️</button>
                            {/if}
                        </tr>
                    {/each}
                </tbody>
            </table>

            {#key team.id}
                <MatchScroller teamId={team.id} matchType="upcoming"/>
                <MatchScroller teamId={team.id} matchType="recent"/>

                <FeatureFlag flag="team-user-seasons">
                    <UserSeasons teamId={team.id}/>
                </FeatureFlag>

                <FeatureFlag flag="team-game-log">
                    <GameLog teamId={team.id}/>
                </FeatureFlag>"

                <FeatureFlag flag="team-stats">
                    <h1>Match Records</h1>

                    <h1>All Time Records</h1>
                </FeatureFlag>
            {/key}


        {:else if page == 'modify' && team != null && role}
            <NavLink to="/team/{teamSlug}"><span>⬅️ Back to {team.tag}</span></NavLink>
            <h2>Modify {team.team_name}</h2>

        {:else if page == 'invite' && team != null && role}
            <NavLink to="/team/{teamSlug}"><span>⬅️ Back to {team.tag}</span></NavLink>
            <form class="loginForm" on:submit={invite}>
                <h2>Invite to {team.team_name}</h2>
                <input type="text" placeholder="Invite Username" bind:value={inviteUsername} required autofocus/>
                <button type="submit">Invite</button>
                {#if error != undefined}
                    <p class:error>{error}</p>
                {/if}
            </form>
        {:else}
            <Page404 item='team'/>
        {/if}
    {/if}
</Page>