<script>
    import {featureFlags, userKV} from '../stores.js';
    import {isDef} from '../utils.js';
    import {tooltip} from './Tooltip';

    export let flag=undefined;
</script>

<style>
    .beta {
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.15);
        border-radius: 100%;
        cursor: help;
        font-size: 0.85em;
    }
</style>

{#if isDef(flag) && flag in $featureFlags && $featureFlags[flag]}
    <span style="position: relative;">
        {#if $userKV.beta && $featureFlags[flag] === 'beta'}
            <span class="beta" use:tooltip={"Early Access"}>🧪</span>
        {/if}
        <slot></slot>
    </span>
{:else}
    <slot name="else"></slot>
{/if}
