<script lang="ts">
    import ForumSection from '../../components/forum/Section.svelte';
    import Loading from '../../components/Loading.svelte';
    import Page from '../../components/Page.svelte';

    async function getForums() {
        const response = await fetch('http://localhost:8000/api/forum')

        if (response.ok) {
            const formed_data = {}
            const responseData = await response.json()
            for (var data in responseData) {
                var key = responseData[data]['game_name'];
                if (key === null) {
                    key = 'FTW';
                }

                if (!(key in formed_data)) {
                    formed_data[key] = [];
                }
                formed_data[key].push(responseData[data]);
            }
            return formed_data;
        }
    }
</script>

<Page title="Forum">
    {#await getForums()}
        <Loading/>
    {:then data}
        <h1>Forums</h1>
            <h2>FTW</h2>
            {#each data['FTW'] as section}
                <ForumSection hrefSection="/forum/ftw/{section['section_slug']}"
                                section={section['section']}
                                thread={section['thread']}
                                threadLink="/forum/ftw/{section['section_slug']}/{section['thread_slug']}"
                                lastPost={section['date_modified']}
                                numberThreads={section['num_threads']}
                                posterUserID = {section['poster_user_id']}
                                poster={section['poster']}
                                posterAvatarHash={section['poster_avatar_hash']}
                                posterOnline={section['poster_online']}/>
            {/each}
            {#each Object.keys(data).filter(k => k !== 'FTW') as game}
                <h2>{game}</h2>
                {#each data[game] as section}
                    <ForumSection hrefSection="/forum/{section['game_slug']}/{section['section_slug']}"
                                section={section['section']}
                                lastPost={section['date_modified']}
                                thread={section['thread']}
                                threadLink="/forum/{section['game_slug']}/{section['section_slug']}/{section['thread_slug']}"
                                numberThreads={section['num_threads']}
                                posterUserID={section['poster_user_id']}
                                poster={section['poster']}
                                posterAvatarHash={section['poster_avatar_hash']}
                                posterOnline={section['poster_online']}/>
                {/each}
            {/each}
    {/await}
</Page>