<script>
    import {RELATIVE_PATH} from '../stores.js';

    export let teamId = null;
    export let userId = null;
    export let matchRoundId = null;
    export let currentImage = null;

    export let height = 200;
    export let width = 200;
</script>

<div style="height: {height}px; width: {width}px;">
    {#if currentImage !== null}
        <img src={`http://localhost:8000/api/upload/${currentImage}`} alt="uploaded"/>
    {:else}
        <img src="{$RELATIVE_PATH}/icons/image.svg" alt="upload"/>
    {/if}
</div>
