<script>
    import { createEventDispatcher } from 'svelte';
    import { RELATIVE_PATH } from '../stores';
    import OnlineIndicator from './OnlineIndicator.svelte';
    import {isDef} from '../utils.js';

    export let label = undefined;
    export let badge = undefined;
    export let icon = undefined;
    export let iconOnly = false;
    export let onlineStatus = undefined;
    export let noclose = false;

    let open = false;

    const dispatch = createEventDispatcher();


    export let right = false;
    export let left = false;
    export let wide = false;

    function close(event) {
        if (!noclose) {
            open = false;
        } else {
            let target = event.target;
            while (target) {
                const id = target.id;
                if (id && id == `content-${label}`) {
                    return false;
                }
                target = target.parentNode;
            }
            open = false;
        }
    }

    function position() {
        const w = window.innerWidth;
        const h = window.innerHeight;
        const dropdown = document.getElementById("content-" + label);
        const parent = dropdown.parentNode;
        const { top, right, bottom, left } = parent.getBoundingClientRect();

        // dropdown.style.minWidth = `${width}px`;

        const width = wide == false ? 180 : 300;

        if (right + width > w) {
            dropdown.style.right = `0px`;
        }
    }

    $: if (open) {
        position();
        setTimeout(function(){
            window.addEventListener('click', close);
        }, 1);
    } else {
        window.removeEventListener('click', close);
    }
</script>

<style>
    .button-content {
        display: flex;
        justify-content: center;
        vertical-align: middle;
        align-items: center;
        font-weight: 600;
        align-content: center;
        justify-content: center;
        display: flex;
    }

    .content {
        display: none;
        position: absolute;
        background-color: var(--dropdown-background-color);
        margin:0;
        padding:0.5em 0;
        box-shadow: 0px 1px 7px 0px rgba(0,0,0,0.2);
        z-index: 100;
    }

    .normal {
        min-width: 160px;
    }

    .wide {
        min-width: 300px;
    }

    .open {display: block;}

    :global(.dropdown-menu) {
		padding: 0;
	}
	:global(.dropdown-link) {
		padding: 0.25em;
		padding-left: 0.5em;
		text-align: left;
	}
    :global(.dropdown-link:last-of-type) {
		padding: 0.25em;
		padding-left: 0.5em;
		text-align: left;
	}
	:global(.dropdown-link:hover) {
		background-color: var(--dropdown-hover-background-color);
	}
	:global(.dropdown-title) {
		padding: 0.25em;
		font-weight: 600;
		text-align: left;
		vertical-align: middle;
	}
	
	:global(.dropdown-hr) {
		margin-top: 1rem;
		margin-bottom: 1rem;
		border: 0;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
	}

    .caret {
        fill: var(--dropdown-color);
        display: inline-block
    }
</style>

<button class="border" class:left class:right on:click={() => open = true} on:click>
    <span class="button-content">
        {#if onlineStatus}
            <OnlineIndicator status={onlineStatus}/>
        {/if}

        {#if !iconOnly}
            {label}
        {/if}

        {#if badge && badge > 0}
            <span class="badge">{badge}</span>
        {/if}
        
        {#if isDef(icon)}
            <img src="{$RELATIVE_PATH}/icons/{icon}.svg" alt="{icon}" style="width: 1.25em; height: 1.25em; display: inline-block;"/>
        {:else}
            <svg class="caret" style="width: 1.25em; height: 1.25em; display: inline-block;" viewBox="0 0 32 32" aria-hidden="true"><path d="M16.003 18.626l7.081-7.081L25 13.46l-8.997 8.998-9.003-9 1.917-1.916z"/></svg>
        {/if}
    </span>
    
    <span id="content-{label}" class="content border" class:open class:wide class:normal={!wide} style="padding-bottom: 0 !important; top: 100%;">
        <slot></slot>
    </span>
</button>
