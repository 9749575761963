<script lang="ts">
    import {RELATIVE_PATH} from '../stores.js';
    import {tick} from 'svelte';
    import {tooltip} from './Tooltip.ts';

    export let text: string;
    export let buttonOnly = false;
    export let tooltipText = "Click to copy";

    let copied: boolean = false;

    async function copyText(e: Event) {
        console.log("COPIED")
        e.preventDefault();
        await tick();

        var dummy = document.createElement("textarea");
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand("copy");
        document.body.removeChild(dummy);

        copied = true;
        setTimeout(() => copied = false, 2000);
    }
</script>

<style>
    span {
        cursor: pointer;
        display: inline-block;
    }
</style>

{#if text && text.length > 0}
    <span use:tooltip={copied ? "Copied!" : tooltipText} on:click={copyText}>
        {#if !buttonOnly}{text}{/if}
        <img src="{$RELATIVE_PATH}/icons/copy.svg" alt="Click to copy" width="14" height="14"/>
    </span>
{/if}
