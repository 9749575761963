<script lang="ts">
    import {accessToken, accessTokenBody, userData, validAccessToken} from '../../stores.js';
    import {deslugify, titlecase} from '../../utils.js';
    import {Link, navigate} from 'svelte-routing';
    import ForumSection from '../../components/forum/Section.svelte';
    import Editor from '../../components/forum/Editor.svelte';
    import Loading from '../../components/Loading.svelte';
    import Page from '../../components/Page.svelte';

    // URL Slugs
    export let gameSlug = null;
    export let sectionSlug = null;
    
    let createThread = false;

    async function getSection() {
        const headers = {}
        if ($validAccessToken) {
            headers['Authorization'] = 'Bearer ' + $accessToken
        }

        const response = await fetch(`http://localhost:8000/api/forum/${gameSlug}/${sectionSlug}`, {
            headers: headers
        })

        if (response.ok) {
            return await response.json()
        }
    }

    function checkCreateThreadPermission(userRole, create_thread_permission) {
        if (create_thread_permission.length === 0) {
            return true;
        } else if (create_thread_permission.length === 1) {
            if (create_thread_permission === null || create_thread_permission === undefined) {
                return true
            } else if (create_thread_permission[0]['user_role_required'] !== null) {
                return create_thread_permission[0]['user_role_required'].includes(userRole)
            }
        }
        return false;
    }

    function threadCreated(e) {
        console.log(e.detail);
        createThread = false;
        navigate(`/forum/${gameSlug}/${sectionSlug}/${e.detail}`)
    }
</script>

<Page title="{gameSlug != 'ftw' ? titlecase(deslugify(gameSlug)) + " - " : ''} {titlecase(deslugify(sectionSlug))}">
    <div style="margin: 0.5em;">
        <Link to="/forum">Forum</Link>
        {#if gameSlug === 'ftw'}
        / FTW
        {:else}
        / <Link to="/game/{gameSlug}">{gameSlug}</Link>
        {/if}
    </div>

    {#await getSection()}
        <Loading/>
    {:then data}

        <h1>{titlecase(deslugify(sectionSlug))}</h1>

        {#if $validAccessToken && checkCreateThreadPermission($accessTokenBody['r'], data['permissions'].filter(p => p['permission'] == 'create_thread'))}
            <button class="button" on:click={() => createThread = true}>Create Thread</button>
        {/if}

        {#if createThread}
            <Editor thread={true} sectionId={data['section_id']} on:threadCreated={threadCreated}/>
        {/if}

        {#if data['pinned'].length > 0}
            {#each data['pinned'] as thread}
                <ForumSection   locked={thread['locked']}
                                deleted={thread['deleted']}
                                pinned={thread['pinned']}
                                hrefSection="/forum/{gameSlug}/{sectionSlug}/{thread['thread_slug']}"
                                section={thread['thread']}
                                creator={thread['creator']}
                                creatorUserID={thread['creator_user_id']}
                                creatorAvatarHash={thread['creator_avatar_hash']}
                                creatorOnline={thread['creator_online']}
                                numberPosts={thread['num_posts']}
                                lastPost={thread['post_date_modified']}
                                threadLink="/forum/{gameSlug}/{sectionSlug}/{thread['thread_slug']}"
                                poster={thread['poster']}
                                posterUserID={thread['poster_user_id']}
                                posterOnline={thread['poster_online']}
                                posterAvatarHash={thread['poster_avatar_hash']}/>
            {/each}
        {/if}

        {#if data['sections'].length > 0}
            {#each data['sections'] as thread}
                <ForumSection   locked={thread['locked']}
                                deleted={thread['deleted']}
                                pinned={thread['pinned']}
                                hrefSection="/forum/{gameSlug}/{sectionSlug}/{thread['thread_slug']}"
                                section={thread['thread']}
                                creator={thread['creator']}
                                creatorOnline={thread['creator_online']}
                                creatorUserID={thread['creator_user_id']}
                                creatorAvatarHash={thread['creator_avatar_hash']}
                                numberPosts={thread['num_posts']}
                                lastPost={thread['post_date_modified']}
                                threadLink="/forum/{gameSlug}/{sectionSlug}/{thread['thread_slug']}"
                                poster={thread['poster']}
                                posterUserID={thread['poster_user_id']}
                                posterOnline={thread['poster_online']}
                                posterAvatarHash={thread['poster_avatar_hash']}/>
            {/each}
        {:else}
            <p>No posts yet...</p>
        {/if}
    {/await}
</Page>