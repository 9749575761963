<script>
    import GameLog from '../components/stats/GameLog.svelte';
    import WeaponStats from '../components/stats/WeaponStats.svelte';
    import MetaStats from '../components/stats/MetaStats.svelte';
    import MapStats from '../components/stats/MapStats.svelte';
    import Page from '../components/Page.svelte'
    import FeatureFlag from '../components/FeatureFlag.svelte';

    import {deslugify, titlecase} from '../utils.js';
    import { games } from '../stores';

    export let gameSlug = null;

    $: console.log(gameSlug)
</script>


<Page title="Stats | {gameSlug}">
    <h1>{titlecase(deslugify(gameSlug))} Stats</h1>
    <GameLog {gameSlug} baseUrl={`/stats/${gameSlug}`}/>
    <WeaponStats {gameSlug}/>
    <FeatureFlag flag="map-stats">
        <MapStats/>
    </FeatureFlag>
    <MetaStats/>
</Page>
<!-- <h1>Stats</h1>
<h2>Player Stats</h2>
<h2>Map Stats</h2> -->