<script>
    import {closePage} from '../PanelLayout.svelte';
    import {tooltip} from '../Tooltip.ts';
</script>

<style>
    .content {
        padding: 0.5em;
        overflow-y: auto;
    }

    .header {
        background-color: var(--background-mid);
        display: flex;
        justify-content: space-between;
        vertical-align: middle;
        align-items: center;
        padding-left: 0.5em;
    }
</style>

<div class="header split">
    <slot name="nav"/>
    <button on:click={closePage} use:tooltip={"Close"}><span>❌</span></button>
</div>

<div class="content overflow-scroll">
    <slot name="content"/>
</div>
