import * as Sentry from '@sentry/svelte';

export let serverIdMap = {};

export async function getServerIdMap(accessToken) {
    if (Object.keys(serverIdMap).length > 0) {
        return serverIdMap;
    }
    // Manual mappings:
    const newServerIdMap =  {
        'ewr': 'https://nj-us-ping.vultr.com',
        'ord': 'https://il-us-ping.vultr.com',
        'dfw': 'https://tx-us-ping.vultr.com',
        'sea': 'https://wa-us-ping.vultr.com',
        'lax': 'https://lax-ca-us-ping.vultr.com',
        'atl': 'https://ga-us-ping.vultr.com',
        'ams': 'https://ams-nl-ping.vultr.com',
        'lhr': 'https://lon-gb-ping.vultr.com',
        'fra': 'https://fra-de-ping.vultr.com',
        'sjc': 'https://sjo-ca-us-ping.vultr.com',
        'syd': 'https://syd-au-ping.vultr.com',
        'yto': 'https://tor-ca-ping.vultr.com',
        'cdg': 'https://par-fr-ping.vultr.com',
        'nrt': 'https://hnd-jp-ping.vultr.com',
        'waw': 'https://waw-pl-ping.vultr.com',
        'mad': 'https://mad-es-ping.vultr.com',
        'icn': 'https://sel-kor-ping.vultr.com',
        'mia': 'https://fl-us-ping.vultr.com',
        'sgp': 'https://sgp-ping.vultr.com',
        'sto': 'https://sto-se-ping.vultr.com',
        'mex': 'https://mex-mx-ping.vultr.com',
        'sao': 'https://sao-br-ping.vultr.com',
        'mel': 'https://mel-au-ping.vultr.com',
        'hnl': 'https://hon-hi-us-ping.vultr.com',
        'bom': 'https://bom-in-ping.vultr.com',
        'jnb': 'https://jnb-za-ping.vultr.com',
        'tlv': 'https://tlv-il-ping.vultr.com',
        'blr': 'https://blr-in-ping.vultr.com',
        'del': 'https://del-in-ping.vultr.com',
        'scl': 'https://scl-cl-ping.vultr.com',
        'itm': 'https://osk-jp-ping.vultr.com',
        'man': 'https://man-uk-ping.vultr.com'
    }
    let locations = await getServerLocations(accessToken);
    console.log(locations)
    for (const location in locations) {
        const server = locations[location];
        if (location && !(server.id in newServerIdMap)) {
            Sentry.captureMessage(`Missing server ID mapping for ${server.DCID} ${server.name} ${server.country}`)
            // console.log('adding:', server)
            // newServerIdMap[server.DCID] = `https://${server.regioncode.toLowerCase()}-${server.country.toLowerCase()}-ping.vultr.com`
            continue
        }
    }
    console.log(newServerIdMap)
    serverIdMap = newServerIdMap;
    return serverIdMap
}

export async function getServerLocations(accessToken) {
    const response = await fetch('http://localhost:8000/api/gameserver/locations');

    if (response.status === 200) {
        return await response.json();
    }
}

export async function getServers(accessToken) {
    const response = await fetch('http://localhost:8000/api/gameserver', {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
    if (response.status === 200) {
        return await response.json()
    }
}

export async function getServer(accessToken, id) {
    const response = await fetch(`http://localhost:8000/api/gameserver/${id}`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    });

    if (response.status === 200) {
        return await response.json()
    }
}

export async function destroyServer(accessToken, id) {
    try {
        const response = await fetch(`http://localhost:8000/api/gameserver/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (exception) {
        return false;
    }
}

export async function reinstallServer(accessToken, id) {
    try {
        const response = await fetch(`http://localhost:8000/api/gameserver/${id}`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${accessToken}`
            }
        });
        if (response.status === 200) {
            return true;
        } else {
            return false;
        }
    } catch (exception) {
        return false;
    }
}

export function pingColor(latency) {
    if (latency < 100) {
        return "green";
    } else if (latency < 200) {
        return "darkorange"
    } else {
        return "red"
    }
}

export const countryMap = {
    'AF': 'Afghanistan', 'AX': 'Åland Islands', 'AL': 'Albania', 'DZ': 'Algeria', 'AS': 'American Samoa', 'AD': 'Andorra', 'AO': 'Angola', 'AI': 'Anguilla',
    'AQ': 'Antarctica', 'AG': 'Antigua and Barbuda', 'AR': 'Argentina', 'AM': 'Armenia', 'AW': 'Aruba', 'AU': 'Australia', 'AT': 'Austria',
    'AZ': 'Azerbaijan', 'BS': 'Bahamas', 'BH': 'Bahrain', 'BD': 'Bangladesh', 'BB': 'Barbados', 'BY': 'Belarus', 'BE': 'Belgium', 'BZ': 'Belize',
    'BJ': 'Benin', 'BM': 'Bermuda', 'BT': 'Bhutan', 'BO': 'Bolivia (Plurinational State of)', 'BQ': 'Bonaire Sint Eustatius Saba', 'BA': 'Bosnia and Herzegovina',
    'BW': 'Botswana', 'BV': 'Bouvet Island', 'BR': 'Brazil', 'IO': 'British Indian Ocean Territory', '': 'Vatican City – See Holy See, The.',
    'BN': 'Brunei Darussalam', 'BG': 'Bulgaria', 'BF': 'Burkina Faso', 'BI': 'Burundi', 'CV': 'Cabo Verde', 'KH': 'Cambodia', 'CM': 'Cameroon',
    'CA': 'Canada', 'KY': 'Cayman Islands', 'CF': 'Central African Republic', 'TD': 'Chad', 'CL': 'Chile', 'CN': 'China', 'CX': 'Christmas Island',
    'CC': 'Cocos (Keeling) Islands', 'CO': 'Colombia', 'KM': 'Comoros', 'CD': 'Congo (the Democratic Republic of the)', 'CG': 'Congo',
    'CK': 'Cook Islands', 'CR': 'Costa Rica', 'CI': "Côte d'Ivoire", 'HR': 'Croatia', 'CU': 'Cuba', 'CW': 'Curaçao', 'CY': 'Cyprus', 'CZ': 'Czechia',
    'DK': 'Denmark', 'DJ': 'Djibouti', 'DM': 'Dominica', 'DO': 'Dominican Republic', 'EC': 'Ecuador', 'EG': 'Egypt', 'SV': 'El Salvador',
    'GQ': 'Equatorial Guinea', 'ER': 'Eritrea', 'EE': 'Estonia', 'SZ': 'Eswatini', 'ET': 'Ethiopia', 'FK': 'Falkland Islands',
    'FO': 'Faroe Islands', 'FJ': 'Fiji', 'FI': 'Finland', 'FR': 'France', 'GF': 'French Guiana', 'PF': 'French Polynesia', 
    'TF': 'French Southern Territories', 'GA': 'Gabon', 'GM': 'Gambia', 'GE': 'Georgia', 'DE': 'Germany', 'GH': 'Ghana', 'GI': 'Gibraltar',
    'GR': 'Greece', 'GL': 'Greenland', 'GD': 'Grenada', 'GP': 'Guadeloupe', 'GU': 'Guam', 'GT': 'Guatemala', 'GG': 'Guernsey', 'GN': 'Guinea', 'GW': 'Guinea-Bissau',
    'GY': 'Guyana', 'HT': 'Haiti', 'HM': 'Heard Island and McDonald Islands', 'VA': 'Holy See', 'HN': 'Honduras', 'HK': 'Hong Kong', 'HU': 'Hungary',
    'IS': 'Iceland', 'IN': 'India', 'ID': 'Indonesia', 'IR': 'Iran (Islamic Republic of)', 'IQ': 'Iraq', 'IE': 'Ireland', 'IM': 'Isle of Man', 'IL': 'Israel',
    'IT': 'Italy', 'JM': 'Jamaica', 'JP': 'Japan', 'JE': 'Jersey', 'JO': 'Jordan', 'KZ': 'Kazakhstan', 'KE': 'Kenya', 'KI': 'Kiribati',
    'KP': "North Korea", 'KR': 'South Korea', 'KW': 'Kuwait', 'KG': 'Kyrgyzstan',
    'LA': "Lao People's Democratic Republic", 'LV': 'Latvia', 'LB': 'Lebanon', 'LS': 'Lesotho', 'LR': 'Liberia', 'LY': 'Libya', 'LI': 'Liechtenstein',
    'LT': 'Lithuania', 'LU': 'Luxembourg', 'MO': 'Macao', 'MK': 'North Macedonia', 'MG': 'Madagascar', 'MW': 'Malawi', 'MY': 'Malaysia', 'MV': 'Maldives',
    'ML': 'Mali', 'MT': 'Malta', 'MH': 'Marshall Islands', 'MQ': 'Martinique', 'MR': 'Mauritania', 'MU': 'Mauritius', 'YT': 'Mayotte', 'MX': 'Mexico',
    'FM': 'Micronesia (Federated States of)', 'MD': 'Moldova (the Republic of)', 'MC': 'Monaco', 'MN': 'Mongolia', 'ME': 'Montenegro', 'MS': 'Montserrat',
    'MA': 'Morocco', 'MZ': 'Mozambique', 'MM': 'Myanmar', 'NA': 'Namibia', 'NR': 'Nauru', 'NP': 'Nepal', 'NL': 'Netherlands', 'NC': 'New Caledonia',
    'NZ': 'New Zealand', 'NI': 'Nicaragua', 'NE': 'Niger', 'NG': 'Nigeria', 'NU': 'Niue', 'NF': 'Norfolk Island', 'MP': 'Northern Mariana Islands',
    'NO': 'Norway', 'OM': 'Oman', 'PK': 'Pakistan', 'PW': 'Palau', 'PS': 'Palestine, State of', 'PA': 'Panama', 'PG': 'Papua New Guinea', 'PY': 'Paraguay',
    'PE': 'Peru', 'PH': 'Philippines', 'PN': 'Pitcairn', 'PL': 'Poland', 'PT': 'Portugal', 'PR': 'Puerto Rico', 'QA': 'Qatar', 'RE': 'Réunion',
    'RO': 'Romania', 'RU': 'Russia', 'RW': 'Rwanda', 'BL': 'Saint Barthélemy', 'SH': 'Saint Helena Ascension Island Tristan da Cunha',
    'KN': 'Saint Kitts and Nevis', 'LC': 'Saint Lucia', 'MF': 'Saint Martin (French part)', 'PM': 'Saint Pierre and Miquelon', 'VC': 'Saint Vincent and the Grenadines',
    'WS': 'Samoa', 'SM': 'San Marino', 'ST': 'Sao Tome and Principe', 'SA': 'Saudi Arabia', 'SN': 'Senegal', 'RS': 'Serbia', 'SC': 'Seychelles', 'SL': 'Sierra Leone',
    'SG': 'Singapore', 'SX': 'Sint Maarten (Dutch part)', 'SK': 'Slovakia', 'SI': 'Slovenia', 'SB': 'Solomon Islands', 'SO': 'Somalia', 'ZA': 'South Africa',
    'GS': 'South Georgia and the South Sandwich Islands', 'SS': 'South Sudan', 'ES': 'Spain', 'LK': 'Sri Lanka', 'SD': 'Sudan', 'SR': 'Suriname', 'SJ':
    'Svalbard Jan Mayen', 'SE': 'Sweden', 'CH': 'Switzerland', 'SY': 'Syrian Arab Republic', 'TW': 'Taiwan', 'TJ': 'Tajikistan',
    'TZ': 'Tanzania, the United Republic of', 'TH': 'Thailand', 'TL': 'Timor-Leste', 'TG': 'Togo', 'TK': 'Tokelau', 'TO': 'Tonga', 'TT': 'Trinidad and Tobago',
    'TN': 'Tunisia', 'TR': 'Türkiye', 'TM': 'Turkmenistan', 'TC': 'Turks and Caicos Islands', 'TV': 'Tuvalu', 'UG': 'Uganda', 'UA': 'Ukraine',
    'AE': 'United Arab Emirates', 'GB': 'United Kingdom', 'UM': 'United States Minor Outlying Islands',
    'US': 'United States of America', 'UY': 'Uruguay', 'UZ': 'Uzbekistan', 'VU': 'Vanuatu', 'VE': 'Venezuela (Bolivarian Republic of)', 'VN': 'Vietnam',
    'VG': 'Virgin Islands (British)', 'VI': 'Virgin Islands (U.S.)', 'WF': 'Wallis and Futuna', 'EH': 'Western Sahara', 'YE': 'Yemen', 'ZM': 'Zambia',
    'ZW': 'Zimbabwe'
}
