import { writable } from 'svelte/store';
import { OnlineStatus } from './models/Enums.ts';

export const styles = writable({})

export const defaultUserKV = {
    hideButtonLabels: false
}

export const accessToken = writable(null);
export const accessTokenBody = writable(null);
export const validAccessToken = writable(false);

export const currentLocation = writable(undefined);

export const games = writable([]);
export const gameTypes = writable([]);

export const userData = writable(null);
export const userKV = writable(defaultUserKV);

export const notifications = writable([]);

export const socket = writable(null);
export const socketConnected = writable(undefined);
// export const lobbyPlayers = writable([]);
export const currentLobby = writable(null);
export const lobbyMessages = writable([]);
export const ticket = writable(null);

export const IS_ELECTRON = writable(null);
export const RELATIVE_PATH = writable('FTW_STATIC_HOST');
export const electronUpdate = writable(false);
export const ipc = writable(null);
export const onlyPage = writable(true);

export const sidebarOpen = writable(undefined);
export const isMobile = writable(undefined);

export const launch = writable(false);
export const updating = writable(false);
export const inGame = writable(false);
export const inGameId = writable(null);

export const panePage = writable(null);
export const paneWidth = writable(500);

export const servers = writable(null);
export const serverPings = writable({});
export const likedServers = writable(null);

export const selectedGameId = writable(1);
export const featureFlags = writable({});

export const globalLoadingAnimation = writable(false);

export const leagueUsers = writable({});

export const mention = writable(false);
export const mentionString = writable("");
export const mentionCallback = writable(null);

export const searchHistory = writable({pages: [], users: [], teams: []});
