<script>
    import {IS_ELECTRON, RELATIVE_PATH, sidebarOpen, isMobile, selectedGameId, games, validAccessToken} from '../stores.js';

    import GamesButton from './GamesButton.svelte';
    import SidebarButton from './SidebarButton.svelte';

    import { slugify, isDef } from '../utils.js';

    import FeatureFlag from './FeatureFlag.svelte';
    import SidebarGameLaunch from './electron/SidebarGameLaunch.svelte';

    $: currentGame = $games.find(g => g.id == $selectedGameId) || null;
	$: currentGameSlug = currentGame != null ? slugify(currentGame.game_name) : null;

    $: $isMobile === true ? sidebarOpen.set(false) : sidebarOpen.set(true);

    $: lastOpen = $sidebarOpen && Date.now();

    function clickHandler(e) {
        if ($isMobile && Date.now() - lastOpen > 10) {
            for (const ele in e.path) {
                if (e.path[ele].id == "sidebar-toggle") {
                    return;
                }
            }
            if ($sidebarOpen) {
                sidebarOpen.set(false);
            }
        }
    }
</script>

<style>
    sidebar {
		height: calc(100%);
		width: var(--sidebar-width);
		position: fixed;
		z-index: 100;
		top: 0;
		left: 0;
		background-color: var(--dark-color);
		overflow-x: hidden;
		padding-top: 2px;
		display: flex;
		justify-content: top;
		align-items: center;
		flex-direction: column;
	}

    .bottom-anchor {
        padding: 0;
        margin: 0;
        padding-bottom: 0.25em;
        margin-top: auto;
    }

    hr {
        width: 30% !important;
        border: 0;
        border-top: 2px solid var(--background-dark);
        margin: 1em 0;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }

    .sidebar-button-wrapper:not(:first-child) {
		margin-bottom: 1em;
	}

    .mobile {
        transition: var(--sidebar-transition);
    }

    .closed {
        left: calc(-1 * var(--sidebar-width)) !important;
    }

    .open {
        left: 0 !important;
    }
</style>

<svelte:window on:click={clickHandler}/>

<sidebar class:electron={$IS_ELECTRON} class:mobile={$isMobile} class:closed={$isMobile && !$sidebarOpen} class:open={$isMobile && $sidebarOpen}>
    <img src={$RELATIVE_PATH + "/logo.svg"} alt="FTWGL Logo" height="14" style="display: block; margin-top: {$IS_ELECTRON ? 4 : 20}px; margin-bottom: {$IS_ELECTRON ? 8 : 10}px">

    {#if !$IS_ELECTRON}
        <hr style="border 1px solid rgba(255,255,255,0.1); margin-top: 0.75em"/>
    {/if}

    {#if $validAccessToken}
        <SidebarButton name="Home" url="/" image={`${$RELATIVE_PATH}/bullets.svg`}/>
        <hr style="border 1px solid rgba(255,255,255,0.1);"/>
    {/if}

    {#if currentGame}
        <span class="sidebar-button-wrapper"><SidebarButton
            name="Recent Matches"
            image={`${$RELATIVE_PATH}/icons/recent.svg`}
            url={`recent/${currentGameSlug}`}/></span>
        <span class="sidebar-button-wrapper"><SidebarButton
            name="Leagues"
            image={`${$RELATIVE_PATH}/icons/tournament.svg`}
            url={`leagues/${currentGameSlug}`}/></span>
        <span class="sidebar-button-wrapper"><SidebarButton
            name="Server Browser"
            image={`${$RELATIVE_PATH}/icons/globe.png`}
            url={`gameservers/browse/${currentGameSlug}`}/></span>
        <span class="sidebar-button-wrapper"><SidebarButton
            name="Stats"
            image={`${$RELATIVE_PATH}/icons/chart.svg`}
            url={`stats/${currentGameSlug}`}/></span>

        <FeatureFlag flag="forum">
            <span class="sidebar-button-wrapper"><SidebarButton
                name="Forum"
                url="/forum"
                image={`${$RELATIVE_PATH}/icons/forum.svg`}/></span>
        </FeatureFlag>
    {/if}

    <div class="bottom-anchor">
        <hr/>
        {#if isDef(currentGame) && $IS_ELECTRON}
            <SidebarGameLaunch game={currentGame}/>
        {/if}
        <FeatureFlag flag="game-install">
            <GamesButton/>
        </FeatureFlag>
    </div>
</sidebar>
