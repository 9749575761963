<script>
    import {onMount} from 'svelte';
    import GamePath from './GamePath.svelte'
    import {makeGamePathKey} from '../../utils.js';
    import ButtonLabel from '../ButtonLabel.svelte';
    import {tooltip} from '../Tooltip'
    import {accessToken, ipc, electronUpdate, launch, updating, inGame, inGameId, IS_ELECTRON} from '../../stores.js';

    export let gameId = null;
    export let gameName = null;
    export let matchId = null;
    export let gameserverId = null;
    export let address = null;
    
    let path = null;

    $: key = makeGamePathKey(gameId)

    onMount(async () => {
        if (gameId) {
            path = await $ipc.send('getSetting', key);
        }
        $ipc.on(key, async (_, value) => {
            path = value;
        });
    });

    async function launcherUpdateOrLaunchGame(event) {
        event.preventDefault();

        if ($electronUpdate) {
            $ipc.send('do-update');
            updating.set(true);
            return;
        }

        if (address) {
            const body = {
                'address': address
            }
            console.log(body)
            const response = await fetch("http://localhost:8000/api/v1/launch/pub", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${$accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                return;
            }
        } else {
            console.log(`Connect to ${gameserverId}`)
            const body = {
                'gameserver_id': gameserverId
            }
            const response = await fetch("http://localhost:8000/api/v1/match/connect", {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${$accessToken}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            });
            if (!response.ok) {
                return;
            }
        }
        inGameId.set(gameId);
        launch.set(true);
    }
</script>

{#if $IS_ELECTRON}
    {#if !path}
        <span use:tooltip={`Set ${gameName} Directory to Join Game`} style="margin-top: 1em;">
        <GamePath gameId={gameId} hidePath/>
        </span>
    {:else}
        <button on:click={launcherUpdateOrLaunchGame} disabled={$inGame || $launch} style="margin: 0;">
            {#if $electronUpdate}
                <ButtonLabel label="Update">↻</ButtonLabel>
            {:else if $updating}
                <ButtonLabel label="Updating...">⌛</ButtonLabel>
            {:else}
                {#if $launch && !$inGame}
                    <ButtonLabel label="Launching">🚀</ButtonLabel>
                {:else if $inGame}
                    <ButtonLabel label="In Game">🔌</ButtonLabel>
                {:else}
                    <ButtonLabel label="Join Game">🎮</ButtonLabel>
                {/if}
            {/if}
        </button>
    {/if}
{/if}
