<script>
    import {isMobile, IS_ELECTRON} from "../stores.js";
    import SidebarToggle from "./SidebarToggle.svelte";
</script>

<style>
    nav {
			display: grid;
			grid-auto-flow: column;
			align-items: center;
			justify-content: space-between;
			position: fixed;
			background-color: var(--background-dark);
			transition: var(--sidebar-transition);
			min-height: var(--nav-height);
			z-index: 100;
			top: 0;
			left: 0;
			width: 100%;
			padding: 0 12px;
			gap: 8px;
		}

    nav .right {
			margin-left: auto;
			display: grid;
			grid-auto-flow: column;
			align-items: center;
			gap: 8px;
		}

    nav.desktop {
			width: calc(100% - var(--sidebar-width)) !important;
			left: var(--sidebar-width);
			justify-content: flex-end;
		}

	.electron {
		margin-top: var(--electron-titlebar-height);
	}
</style>

<nav class:electron={$IS_ELECTRON} class:desktop={!$isMobile}>
    {#if $isMobile}
        <SidebarToggle/>
    {/if}
		<slot></slot>
    <slot name="left"/>
    <span class="right">
			<slot name="right"/>
    </span>
</nav>
