<script>
    import {selectedGameId} from '../../stores.js'
    import Loading from '../Loading.svelte';

    import {titlecase, deslugify} from '../../utils.js';

    async function getStats() {
        const response = await fetch(`http://localhost:8000/api/stats/meta?game_id=${$selectedGameId}`);
        if (response.ok) {
            return await response.json();
        }
    }
</script>

{#await getStats()}
    <Loading/>
    <div class="skeleton" style="height: 180px"/>
    <div class="skeleton" style="height: 36px; margin-top: 2em;"/>
    <div class="skeleton" style="height: 290px;"/>
{:then stats}
    {#each Object.keys(stats) as stat}
        <h2>{titlecase(stat)}</h2>
        <div class="box-container overflow-scroll">
            {#each Object.keys(stats[stat]) as key}
                <div class="card inline-card">
                    <strong>{titlecase(deslugify(key))}</strong>
                    <h2>{stats[stat][key]}</h2>
                </div>
            {/each}
        </div>
    {/each}
{/await}
