/* Usage example:
   `<NavLink to="/"><span class="navlink" use:tooltip={"This is my tooltip"}>Home</span></NavLink>`
*/
export var TooltipPosition;
(function (TooltipPosition) {
    TooltipPosition[TooltipPosition["Left"] = 0] = "Left";
    TooltipPosition[TooltipPosition["Top"] = 1] = "Top";
    TooltipPosition[TooltipPosition["Right"] = 2] = "Right";
    TooltipPosition[TooltipPosition["Bottom"] = 3] = "Bottom";
})(TooltipPosition || (TooltipPosition = {}));
export function tooltip(node, input) {
    if (input === undefined || input === null || input == "")
        return;
    if (typeof (input) === "string") {
        const newInput = { text: input, pos: TooltipPosition.Bottom };
        input = newInput;
    }
    else if (input.pos === undefined) {
        input.pos = TooltipPosition.Bottom;
    }
    createTootip(node, input.text, input.pos);
}
function createTootip(node, text, pos) {
    let tooltip;
    const tooltips = document.querySelectorAll(".tooltip");
    if (tooltips.length > 0) {
        for (let i = 0; i < tooltips.length; i++) {
            if (i == 0) {
                tooltip = tooltips[i];
            }
            else {
                tooltips[i].remove();
            }
        }
    }
    else {
        tooltip = document.createElement('div');
        tooltip.style.opacity = "0";
        tooltip.style.top = '-200px';
        tooltip.style.left = '-200px';
        tooltip.classList.add("tooltip");
        document.body.appendChild(tooltip);
    }
    function position() {
        tooltip.innerHTML = text;
        const parentDimensions = node.getBoundingClientRect();
        const toolTipDimensions = tooltip.getBoundingClientRect();
        // Calculate Y position
        let tooltipY;
        if (pos == TooltipPosition.Left || pos == TooltipPosition.Right) {
            const parentHeight = parentDimensions.bottom - parentDimensions.top;
            const tooltipHeight = toolTipDimensions.bottom - toolTipDimensions.top;
            tooltipY = parentDimensions.top + ((parentHeight / 2) - (tooltipHeight / 2));
        }
        else if (pos == TooltipPosition.Top)
            tooltipY = parentDimensions.top - (toolTipDimensions.bottom - toolTipDimensions.top);
        else if (pos == TooltipPosition.Bottom)
            tooltipY = parentDimensions.bottom;
        if (tooltipY < 0) // Check if above screen
            tooltip.style.top = `0`;
        else if // Check if below screen
         (tooltipY > window.outerHeight)
            tooltip.style.top = `${parentDimensions.top - (toolTipDimensions.bottom - toolTipDimensions.top)}px`;
        else
            tooltip.style.top = `${tooltipY}px`;
        // Calculate X position
        let tooltipX;
        if (pos == TooltipPosition.Left) {
            tooltipX = parentDimensions.left;
        }
        else if (pos == TooltipPosition.Right) {
            tooltipX = parentDimensions.right;
        }
        else {
            tooltipX = ((parentDimensions.right + parentDimensions.left) / 2) - (toolTipDimensions.width / 2);
        }
        if (tooltipX < 0) {
            tooltip.style.left = `0px`;
        }
        else if (tooltipX + toolTipDimensions.width > window.innerWidth) {
            // Set the left position of the tooltip to ensure that it is fully inside the window
            tooltip.style.left = `${window.innerWidth - toolTipDimensions.width - 5}px`;
        }
        else {
            tooltip.style.left = `${tooltipX}px`;
        }
    }
    function append() {
        position();
        tooltip.style.opacity = "0";
        setTimeout(() => tooltip.style.opacity = "1");
    }
    function remove() {
        tooltip.style.opacity = "0";
    }
    // Add touch event listeners for mobile devices
    node.addEventListener("touchstart", append);
    node.addEventListener("touchend", remove);
    // Add hover event listeners for desktop devices
    node.addEventListener("mouseenter", append);
    node.addEventListener("mouseleave", remove);
    window.addEventListener('click', remove);
    window.addEventListener('popstate', remove);
    return {
        update(text) {
            tooltip.innerHTML = text;
            position();
        },
        destroy() {
            tooltip.remove();
            // Add touch event listeners for mobile devices
            node.removeEventListener("touchstart", append);
            node.removeEventListener("touchend", remove);
            // Add hover event listeners for desktop devices
            node.removeEventListener("mouseenter", append);
            node.removeEventListener("mouseleave", remove);
            window.removeEventListener('click', remove);
            node.removeEventListener('popstate', remove);
        }
    };
}
