<script lang="ts">
    import {accessToken, userData} from '../../stores.js';

    import ImageUploader from '../../components/ImageUploader.svelte';
    import FileUpload from '../../components/FileUpload.svelte';

    import NavLink from '../../components/NavLink.svelte';

    export let matchId, match;

    export let maps = [
        '',
        'ut4_abbey',
        'ut4_casa',
        'ut4_sanc',
        'ut4_tohunga_b8',
        'ut4_derelict_b5',
        'ut4_prague',
        'ut4_turnpike',
        'ut4_overgrown_b2'
    ]

    async function updateWithDemoData(roundIndex: number, data: object) {
        try {
            let self_score: number, other_score: number;

            const myTeamIndex = match['teams'].map(t => $userData.teams.map(ut => ut.id).includes(t.id)).indexOf(true)

            // Extract scores
            const selfMatch: Array<object> = data['match_stats']['Players'].filter(p => p['Auth'] == data['self_urtauth']);
            if (selfMatch.length === 1) { //Player in match
                switch(selfMatch[0]['Team']) {
                    case 'red':
                        self_score = data['match_stats']['ScoreRed']
                        other_score = data['match_stats']['ScoreBlue']
                    case 'blue':
                        self_score = data['match_stats']['ScoreBlue']
                        other_score = data['match_stats']['ScoreRed']
                }
            } else { //Player not found in match
                //TODO: Try searching via name tag

                //Default
                self_score = data['match_stats']['ScoreRed']
                other_score = data['match_stats']['ScoreBlue']
            }

            // Set scores
            console.log(data['match_stats']['ScoreRed'], data['match_stats']['ScoreBlue'], self_score, other_score)
            match.rounds[roundIndex].scores = [self_score, other_score]

            // Set demo
            match.rounds[roundIndex]['demo_hash'] = data['file_hash']

            // Set map
            match.rounds[roundIndex]['map'] = data['match_stats']['Map']

            // Set round
            if (!('round' in match.rounds[roundIndex])) {
                match.rounds[roundIndex]['round'] = roundIndex + 1;
            }

            // Set match_id
            match.rounds[roundIndex]['match_id'] = matchId;

            await sendRoundData(match.rounds[roundIndex])
        } catch (Exception) {
            console.log(Exception)
        }
    }

    async function sendRoundData(round) {
        console.log(round);
        const response = await fetch('http://localhost:8000/api/match-round', {
            method: 'id' in round ? 'PUT' : 'POST',
            body: JSON.stringify(round),
            headers: {
                'Authorization': `Bearer ${$accessToken}`,
                'Content-Type': 'application/json'
            }
        })
        if (response.status == 200) {
            if (!('id' in round)) { // get the id from the response
                const creationResponse = await response.json()
                round.id = creationResponse['id']
            }
        }
    }
    
</script>

<NavLink to="/match/{matchId}"><span>⬅️ Back to match</span></NavLink>

<div>
    <form>
        <table>
            <thead>
                <tr>
                    <th>Round</th>
                    <th>Map</th>
                    {#each match.teams as team}
                        <th>{team['tag']}</th>
                    {/each}
                    <th>Screenshot</th>
                    <th>Demo</th>
                </tr>
            </thead>
            <tbody>
                {#each match['rounds'] as _, i}
                    <tr>
                        <td>{i+1}</td>
                        <td>
                            <select bind:value={match['rounds'][i]['map']}>
                                {#each maps as map}
                                    <option value={map}>{map}</option>
                                {/each}
                            </select>
                        </td>
                        {#each match.teams as team, j}
                            <td>
                                <input type="number" bind:value={match['rounds'][i]['scores'][j]}/>
                            </td>
                        {/each}
                        <td>
                            <ImageUploader/>
                        </td>
                        <td>
                            <FileUpload label="demo" accept=".urtdemo" on:data={(e) => updateWithDemoData(i, e.detail.data)}/>
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>
    </form>

    <button on:click={() => match.rounds = [...match.rounds, {scores: ['', '']}]}>Add Round</button>
        <!-- <div>
            <Link to="/team/{slugify(team.team_name)}">{team.team_name} ({team.tag})</Link>
        </div> -->
        <!-- {#each submitRounds as round}
        {/each} -->
</div>
<!-- <button on:click={(e) => submitRounds.push(match.teams.map(t => {tid: t.id}))}>Add Round</button> -->