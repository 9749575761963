<style>
    * { 
        -webkit-text-size-adjust: none; 
        --drawer-width: 240px;
    }

    #drawer {
        width: var(--drawer-width) !important;
        margin: 0;
        width: 25%;
        position: fixed;
        max-height: calc(100vh - var(--nav-height)) !important;
        overflow-y: scroll !important;
    }

    @media (max-width: 679px) {
        #drawer {
            left: calc(var(--drawer-width) * -1);
        }
    }

    @media (min-width: 680px) {
        #drawer-toggle,
        #drawer-toggle-label,
        #drawer-toggle-label:before {
            display: none;
            opacity: 0;
            pointer-events: none;
            left: calc(var(--drawer-width) * -1);
        }
    }

    #drawer-toggle:checked ~ #drawer-toggle-label { 
        left: var(--drawer-width);
        height: 100%; 
        width: calc(100% - var(--drawer-width)); 
    }
    #drawer-toggle:checked ~ #content {
        margin-left: var(--drawer-width);
        opacity: 0.4;
    }

    #drawer-toggle:checked ~ #drawer { 
        left: 0px;
    } 
    
    #drawer-toggle { 
        position: absolute; 
        opacity: 0; 
    }
    #drawer-toggle-label { 
        -webkit-touch-callout: none; 
        -webkit-user-select: none; 
        -khtml-user-select: none; 
        -moz-user-select: none; 
        -ms-user-select: none; 
        user-select: none; 
        left: 0px; 
        height:50px; 
        width: 50px; 
        display: block; 
        position: fixed; 
        background: rgba(255,255,255,.0);
        z-index: 1; 
    }

    #drawer-toggle-label:before { 
        content: ''; 
        display: block; 
        position: absolute; 
        height: 2px; 
        width: 24px; 
        background: #8d8d8d; 
        left: 13px; 
        top: 18px; 
        box-shadow: 0 6px 0 #8d8d8d, 0 12px 0 #8d8d8d; 
    }

    #content {
        margin-left: var(--drawer-width);
    }

    @media (max-width: 680px) {
        #content {
          margin-left: 0;
          padding: 0.5em;
        }
	}
</style>

<input type="checkbox" id="drawer-toggle" name="drawer-toggle"/>
<label for="drawer-toggle" id="drawer-toggle-label"></label>

<div id="drawer" class="overflow-scroll">
    <slot name="drawer"></slot>
</div>

<div id="content">
    <slot name="content"></slot>
</div>
