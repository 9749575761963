<script>
	import { accessToken, validAccessToken, userData, socketConnected, IS_ELECTRON } from '../stores.js';
	import { slugify, isDef } from '../utils.js';
	import { logout } from "../Auth.js";

	import AdminOnly from './AdminOnly.svelte';
	import Avatar from './Avatar.svelte';
	import Dropdown from './Dropdown.svelte';
	import NavLink from './NavLink.svelte';
	import {tooltip} from './Tooltip'

	import { navigate } from 'svelte-routing';
	import localforage from 'localforage';

	$: console.log(`SocketConnected: ${$socketConnected}`)

    async function getMe(accessToken) {
		if (accessToken != null) {
			const response = await fetch('http://localhost:8000/api/me', {
				method: 'GET',
				headers: {
					authorization: 'Bearer ' + accessToken
				}
			});
			if (response.ok) {
				userData.set(await response.json());

				const ip = await localforage.getItem('ip')
				if (!isDef(ip)) {
					localforage.setItem('ip', $userData.ip)
				} else if (ip != $userData.ip) {
					localforage.removeItem('serverPings')
				}
			} else {
				localStorage.clear();
				validAccessToken.set(false);
				if ($IS_ELECTRON) {
					navigate('/login', {replace: true});
				}
			}
		}
	}

	$: getMe($accessToken);
	// $: badgeCount = ($userData && $userData.invites.length) || 0;
	const badgeCount = 0;
</script>

{#if $userData != null}
	{#if $userData.username != null}
		<span style="border-left: 2px solid rgba(255,255,255,0.15); height: 65%; vertical-align: middle"></span>
		<Dropdown label={$userData.username} badge={badgeCount} onlineStatus={$socketConnected} right>
			<span class="dropdown-menu">
				<div style="margin-left: auto; margin-right: auto;">
					<span use:tooltip={`Profile`}>
						<NavLink to="/user/{encodeURIComponent($userData.username)}">
							<Avatar avatar={$userData.avatar} color={$userData.avatar_color} username={$userData.username} userId={$userData.id} glow noPresence/>
						</NavLink>
					</span>
				</div>

				<div class="dropdown-title">My Teams</div>
				{#if $userData.teams.length == 0}
					<p><em>Not in a team</em></p>
				{:else}
					{#each $userData.teams as team}
						{#if isDef(team.team_name)}
							<NavLink to="/team/{slugify(team.team_name)}"><div class="dropdown-link">{team.team_name}</div></NavLink>
						{/if}
					{/each}
				{/if}
				{#if $userData.invites.length > 0}
					<NavLink to="/invites"><div class="button dropdown-link">Team Invites <span class="badge">{badgeCount}</span></div></NavLink>
				{/if}

				<AdminOnly>
					<NavLink to="/create-team"><div class="button dropdown-link">Create Team ➕</div></NavLink>
				</AdminOnly>
				
				<hr class="dropdown-hr">
				<AdminOnly>
					<NavLink to="/admin"><div class="button dropdown-link">Admin Panel 🛠️</div></NavLink>
				</AdminOnly>
				<NavLink to="/gameservers"><div class="button dropdown-link">Game Servers 🎮</div></NavLink>
				<NavLink to="/settings"><div class="button dropdown-link">Settings ⚙️</div></NavLink>
				<a on:click={logout} href="/logout"><div class="button dropdown-link">Log out 👋</div></a>
			</span>
			<div style="color: #444; font-size: 0.65em;"><em>VERSION_STRING</em></div>
		</Dropdown>
	{:else}
		<NavLink to="/settings"><div class="border right button">Set Username</div></NavLink>
	{/if}
{/if}
