<script context="module">
    import {isDef} from "../../utils.js";

    export function applyFiltersToURL(request_url, leagueUserId, gameMode, filters) {
        if (isDef(gameMode.game_type_id)) {
            request_url += `&game_type_id=${gameMode.game_type_id}`
        } else {
            request_url += `&game_mode_id=${gameMode.game_mode_id}`
        }
        if (filters.gameTypeFilter != 'all') {
            request_url += `&game_type_id=${filters.gameTypeFilter}`
        }
        if (filters.leagueFilter != 'all') {
            request_url += `&league_id=${filters.leagueFilter}`
        }
        if (leagueUserId == null && filters.leagueUserIdFilter != 'all') {
            request_url += `&league_user_id=${filters.leagueUserIdFilter}`
        }
        if (leagueUserId != null) {
            request_url += `&league_user_id=${leagueUserId}`
        }
        if (filters.timeRange != 'a') {
            let now = new Date()
            switch(filters.timeRange) {
                case '24h':
                    now.setHours(now.getHours() - 24);
                    break;
                case 'w':
                    now.setDate(now.getDate() - 7);
                    break;
                case 'm':
                    now.setDate(now.getDate() - 30);
                    break;
                case 'q':
                    now.setDate(now.getDate() - 90);
                    break;
                case 'y':
                    now.setDate(now.getDate() - 365);
                    break;
            }
            request_url += `&date_start=${now.toISOString()}`
        }
        if (filters.mapId != 'all') {
            request_url += `&map_id=${filters.mapId}`
        }

        return request_url
    }
</script>

<script>
    import {createEventDispatcher} from 'svelte';
    import { games, gameTypes, selectedGameId } from "../../stores.js";
    import { readURLParams, generateRandomString } from "../../utils.js";
    import { onMount } from "svelte";
    import { tooltip } from '../Tooltip.js';
    import Loading from '../Loading.svelte';

    // export let baseUrl;
    export let userIds = 'all';
    export let leagueUserId = null;
    export let leagueId = null;
    export let teamId = null;

    export let disableLeagueFilter = false;
    export let disableMapFilter = false;
    export let disableGameTypeFilter = false;

    const idPrefix = generateRandomString(8); // to prevent collisions on html ids

    let ready = false;
    let matchFilters;
    let leagueUserIdFilter = 'all';
    let gameTypeFilter = 'all';
    let leagueFilter = 'all';
    let mapId = 'all';
    let timeRange = 'a';
    let gameModeID = null;

    let dispatch = createEventDispatcher();

    onMount(() => {
        let params = readURLParams();

        if (leagueUserId == null) {
            leagueUserIdFilter = isDef(params.l) ? parseInt(params.l) : 'all';
        } else {
            leagueUserIdFilter = leagueUserId;
        }

        leagueUserIdFilter = isDef(params.l) ? parseInt(params.l) : 'all';
        gameTypeFilter = isDef(params.gt) ? parseInt(params.gt) : 'all';
        // if (userIds == 'all') userIds = isDef(params.u) ? params.u : [];
        timeRange = isDef(params.tr) ? params.tr : 'a';
    });

    function fireReady() {
        ready = true;
        dispatch('ready');
    }

    $: resetGameTypeFilter(leagueUserIdFilter);
    $: resetLeagueFilter(leagueUserIdFilter, gameTypeFilter);

    function resetGameTypeFilter(leagueUserId) {
        if (!ready) {
            return;
        }
        if (!getUniqueGameTypeIds(leagueUserId).includes(gameTypeFilter)) {
            gameTypeFilter = 'all';
        }
    }
    
    function resetLeagueFilter(leagueUserId, gameTypeId) {
        if (!ready) {
            return;
        }
        leagueFilter = 'all';
    }

    async function getFilters(gameID, userIds, leagueUserId) {
        let request_url = `http://localhost:8000/api/stats/filters?game_id=${gameID}`;

        if (isDef(leagueUserId)) {
            request_url += `&league_user_id=${leagueUserId}`
        }

        if (isDef(userIds) && userIds != 'all') {
            request_url += `&user_ids=${userIds}`
        }
        
        if (isDef(leagueId)) {
            request_url += `&league_id=${leagueId}`
        }

        if (isDef(teamId)) {
            request_url += `&team_id=${teamId}`
        }

        const response = await fetch(request_url);
        if (response.ok) {
            matchFilters = await response.json();

            const playedGameModes = new Set(matchFilters['leagues'].flatMap(l => l['game_mode_ids']))
            for (const gm of matchFilters['game_modes']) {
                if (playedGameModes.has(gm['game_mode_id'])) {
                    gameModeID = gm.game_mode_id
                    dispatch('gameMode', gm)
                    break;
                }
            }

            const statisticMap = matchFilters['statistics'].reduce((acc, item) => ({ ...acc, [item.id]: item}), {})
            dispatch('statisticMap', statisticMap);

            filterChanged(); // first time init
            fireReady();
            return matchFilters;
        }
    }

    function getUniqueGameTypeIds(leagueUserId) {
        return [...new Set(matchFilters['leagues'].filter(l => leagueUserId == 'all' ? true : l['league_user_id'] == leagueUserId).flatMap(l => l['game_type_id']))]
    }

    // function updateURL(leagueUserFilter, gameTypeFilter, userIds, order, direction, page, timeRange) {
    //     if (!ready) {
    //         return
    //     }
    //     // Create the URL string as before
    //     let url = baseUrl;
    //     let queryParams = [];
    //     if (leagueUserFilter != 'all') {
    //         queryParams.push(`l=${encodeURIComponent(leagueUserFilter)}`);
    //     }
    //     if (isDef(gameTypeFilter) && gameTypeFilter != 'all') {
    //         queryParams.push(`gt=${encodeURIComponent(gameTypeFilter)}`);
    //     }
    //     if (userIds != 'all' && userIds.length > 0) {
    //         queryParams.push(`u=${encodeURIComponent(userIds.join(','))}`);
    //     }
    //     if (order != 'match_round_id') {
    //         queryParams.push(`o=${encodeURIComponent(order)}`);
    //     }
    //     if (direction != 'DESC') {
    //         queryParams.push(`d=${encodeURIComponent(direction)}`);
    //     }
    //     if (page != 1) {
    //         queryParams.push(`p=${encodeURIComponent(page)}`);
    //     }
    //     if (queryParams.length > 0) {
    //         url += '?' + queryParams.join('&');
    //     }
    //     if (timeRange != 'a') {
    //         url += `&tr=${encodeURIComponent(timeRange)}`;
    //     }

    //     // Use history.replaceState to update the current URL
    //     history.replaceState({}, '', url);
    // }

    function filterChanged() {
        dispatch('filterChanged', {
            leagueUserIdFilter,
            gameTypeFilter,
            leagueFilter,
            mapId,
            timeRange
        })
    }
</script>

{#await getFilters($selectedGameId, userIds, leagueUserId)}
        <Loading/>
        <div class="skeleton" style="height: 32px;"></div>
        <div class="skeleton"></div>
        <div class="skeleton" style="height: 505px;"></div>
{:then filters}
    {#if isDef($games) && $games.length > 0 && isDef($gameTypes) && $gameTypes.length > 0}
        {@const playedGameModes = new Set(filters['leagues'].flatMap(l => l['game_mode_ids']))}
        <div style="display:flex">
            <!-- GAME MODE FILTERS -->
            <span style="margin: 3px;" class="button-group">
                {#each filters['game_modes'] as gm}
                    {#if isDef(gm.statistic_ids) && gm.statistic_ids.length > 0}
                        {#if playedGameModes.has(gm['game_mode_id'])}
                            <input id="{idPrefix}:gm:{gm['game_mode_id']}" type="radio" bind:group={gameModeID} value={gm['game_mode_id']} on:change={dispatch('gameMode', gm)}  hidden/>
                            <label for="{idPrefix}:gm:{gm['game_mode_id']}" class="tag" use:tooltip={gm['game_mode_name']}>{gm['game_mode_abbrev']}</label>
                        {/if}
                    {/if}
                {/each}
            </span>

            <!-- LEGEND -->
            <span style="margin-left: auto; float:right;">
                {#if userIds == 'all' || !isDef(userIds)}
                    <span class="tag pr" use:tooltip={"World Record"}>WR</span>
                {:else}
                    <span class="tag pr" use:tooltip={"Personal Best"}>PB</span>
                {/if}
                <span class="tag max" use:tooltip={"Maximum Page Value"}>Max</span>
            </span>
        </div>

        <div style="display:flex; margin-bottom: 3px;">
            <!-- LEAGUE FILTERS -->
            {#if !disableLeagueFilter && leagueUserId == null}
                <select bind:value={leagueUserIdFilter} class="tag" style="color: white; height: 2em;" on:change={filterChanged}>
                    <option value="all">All Leagues</option>
                    {#each [...new Set(filters['leagues'].flatMap(l => l['league_user_id']))] as league_user_id}
                        <option value={league_user_id}>
                            {filters['leagues'].filter(l => l['league_user_id'] == league_user_id)[0]['username'].replace('FTW', 'Scrim')}
                        </option>
                    {/each}
                </select>
            {/if}

            <!-- GAME TYPE FILTERS -->
            {#if !disableGameTypeFilter}
                <select bind:value={gameTypeFilter} class="tag" style="color: white; height: 2em;" on:change={filterChanged}>
                    <option value="all">All Game Types</option>
                    {#each getUniqueGameTypeIds(leagueUserIdFilter) as game_type_id}
                        <option value={game_type_id}>
                            {$gameTypes.filter(gt => gt.id == game_type_id)[0].game_type}
                        </option>
                    {/each}
                </select>
            {/if}

            <!-- MAP FILTERS -->
            {#if !disableMapFilter}
                <select bind:value={mapId} class="tag" style="color: white; height: 2em;" on:change={filterChanged}>
                    <option value="all">All Maps</option>
                    {#each filters['maps'] as map}
                        <option value={map.map_id}>
                            {map.name}
                        </option>
                    {/each}
                </select>
            {/if}

            <!-- TIME RANGE FILTERS -->
            <span style="margin-left: auto; float:right;">
                <span class="button-group">
                    <input id="{idPrefix}:tr:24h" type="radio" bind:group={timeRange} value="24h" on:change={filterChanged} hidden/>
                    <label for="{idPrefix}:tr:24h" class="tag" use:tooltip={"24 Hours"}>24H</label>

                    <input id="{idPrefix}:tr:w" type="radio" bind:group={timeRange} value="w" on:change={filterChanged} hidden/> 
                    <label for="{idPrefix}:tr:w" class="tag" use:tooltip={"Week"}>W</label>

                    <input id="{idPrefix}:tr:m" type="radio" bind:group={timeRange} value="m" on:change={filterChanged} hidden/> 
                    <label for="{idPrefix}:tr:m" class="tag" use:tooltip={"Month"}>M</label>

                    <input id="{idPrefix}:tr:q" type="radio" bind:group={timeRange} value="q" on:change={filterChanged} hidden/> 
                    <label for="{idPrefix}:tr:q" class="tag" use:tooltip={"Quarter (3M)"}>Q</label>

                    <input id="{idPrefix}:tr:y" type="radio" bind:group={timeRange} value="y" on:change={filterChanged} hidden/> 
                    <label for="{idPrefix}:tr:y" class="tag" use:tooltip={"Year"}>Y</label>

                    <input id="{idPrefix}:tr:a" type="radio" bind:group={timeRange} value="a" on:change={filterChanged} hidden/> 
                    <label for="{idPrefix}:tr:a" class="tag" use:tooltip={"All-Time"}>All</label>
                </span>
            </span>
        </div>
    {/if}
{/await}
