<script>
    import {selectedGameId, RELATIVE_PATH} from '../../stores.js'
    import Loading from '../Loading.svelte';
    import {tooltip} from '../Tooltip.ts'
    import SortableTable from '../SortableTable.svelte';
    import {titlecase, deslugify, isDef} from '../../utils.js';
    import Filters from './Filters.svelte';
    import {applyFiltersToURL} from './Filters.svelte';

    let selectedWeapon;
    let gameMode = null;
    let filters = null;

    async function getStats(gameMode, filters) {
        if (!isDef(gameMode) || !isDef(filters)) {
            return
        }

        let request_url = `http://localhost:8000/api/stats/weapons?game_id=${$selectedGameId}`;

        request_url = applyFiltersToURL(request_url, null, gameMode, filters);

        const response = await fetch(request_url);
        if (response.ok) {
            const body = await response.json();
            if (Object.keys(body.stats).length > 0) {
                selectedWeapon = Object.keys(body.stats)[0];
            }
            return body
        }
    }

    const hideWeapons = [
        'Change',
        'Flag',
        'Suicide',
        'Sploded',
        'Telefrag',
        'Unknown',
        'Bleed',
        '(null)',
        'Smited',
        'Kick'
    ]

    function toAcronym(metric) {
        const item = metric.split('-')[0]
        if (item == 'HEADSHOTS') {
            return {col: 'HS', tooltip: 'Headshots'}
        } else if (item == 'KILLS') {
            return {col: 'K', tooltip: 'Kills'}
        } else if (metric == 'GP') {
            return {col: 'GP', tooltip: 'Games Played'}
        }
    }

    function toRank(index) {
        if (index == 0) {
            return {col: '🥇', tooltip: '#1 World Record'}
        } else if (index == 1) {
            return {col: '🥈', tooltip: '#2'}
        } else if (index == 2) {
            return {col: '🥉', tooltip: '#3'}
        } else {
            return index+1;
        }
    }

    function toValue(stat) {
        if ('match_id' in stat && isDef(stat.match_id)) {
            return {type: 'Link', to: `/match/${stat.match_id}`, col: stat.val}
        } else {
            return stat.val
        }
    }

    function getHeadings(metric) {
        if (metric.includes('AVG')) {
            return ['Rank', 'Player', toAcronym('GP'), toAcronym(metric)]
        } else {
            return ['Rank', 'Player', toAcronym(metric)]
        }
    }

    function getSortCol(metric) {
        if (metric.includes('AVG')) {
            return 3
        } else {
            return 2
        }
    }
</script>

<style>
    input {
        display: none;
    }

    input[type=radio]:checked + label {
        background-color: var(--background-highlight);
    }

    label {
        padding: 0.25em;
        border-radius: 5px;
        margin: 0.25em;
    }
    label:hover {
        background-color: var(--background-dark);
    }
</style>

<h2>Weapons{#if isDef(selectedWeapon)}&nbsp;({selectedWeapon}){/if}</h2>

<Filters 
    on:gameMode={(gm) => gameMode = gm.detail}
    on:filterChanged={(f) => {
        filters = f.detail;
    }}
/>

{#await getStats(gameMode, filters)}
    <Loading/>
    <div class="skeleton" style="height: 180px"/>
    <div class="skeleton" style="height: 36px; margin-top: 2em;"/>
    <div class="skeleton" style="height: 290px;"/>
{:then stats}
    {#if isDef(stats) && Object.keys(stats.stats).length > 0}
        {#each Object.keys(stats.stats).filter(w => !hideWeapons.includes(w)) as weapon, i}
            <input id={weapon} type="radio" bind:group={selectedWeapon} value={weapon}/>
            <label for={weapon} use:tooltip={weapon} style="display: inline-block;">
                <img src="{$RELATIVE_PATH}/weapon_sprites/urbanterror/{weapon}.png" height="48" alt={weapon} use:tooltip={weapon}/>
            </label>
        {/each}

        <div class="grid" style="margin-bottom: 2em; padding: 0;">
            <div class="row">
                {#each Object.keys(stats.stats[selectedWeapon]).reverse() as metric}
                    <div class="col-3">
                        <h3>{titlecase(deslugify(metric))}{#if metric.includes('AVG')}*{/if}</h3>
                        <SortableTable headings={getHeadings(metric)} sortCol={getSortCol(metric)}
                            rows={stats.stats[selectedWeapon][metric].map((stat,i) => {
                                if ('gp' in stat) {
                                    return [
                                        toRank(i),
                                        {
                                            type: 'UserLink',
                                            avatar: stats.users[String(stat.user_id)].avatar,
                                            userId: stat.user_id,
                                            username: stats.users[String(stat.user_id)].username,
                                            col: ''
                                        },
                                        {col: stat.gp},
                                        toValue(stat),
                                    ]
                                } else {
                                    return [
                                        toRank(i),
                                        {
                                            type: 'UserLink',
                                            avatar: stats.users[String(stat.user_id)].avatar,
                                            userId: stat.user_id,
                                            username: stats.users[String(stat.user_id)].username,
                                            col: ''
                                        },
                                        toValue(stat),
                                    ]
                                }
                                
                            })}
                        />
                    </div>
                {/each}
            </div>
            <em style="margin-left: 1em">*Average with minimum of 10 Games Played.</em>
        </div>
    {/if}
{/await}
