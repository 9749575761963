<script>
    import {isMobile} from '../stores.js';

    let outerWidth;

    $: checkWidth(outerWidth);

    function checkWidth(width) {
        if (width < 800) {
            isMobile.set(true);
        } else {
            isMobile.set(false);
        }
    }
</script>

<svelte:window bind:outerWidth/>
