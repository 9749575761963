<script>
    import { games } from '../stores.js';
    import { deslugify, slugify, titlecase, parseMatchRound, isDef } from '../utils.js';
    import { Link } from 'svelte-routing';

    import Page404 from './Page404.svelte';
    import Page from '../components/Page.svelte'

    import Loading from '../components/Loading.svelte';
    import NavLink from '../components/NavLink.svelte';
    import TimeSince from '../components/TimeSince.svelte';
    import UserSeasons from '../components/stats/UserSeasons.svelte';
    import GameLog from '../components/stats/GameLog.svelte';

    export let gameName = null;
    export let gameType = null;
    export let season = null;

    let league;

    $: title = isDef(league) ? `${league.league.abbreviation}: ${league.league.season}` : 'League'

    async function getLeagues() {
        const response = await fetch(`http://localhost:8000/api/league/${gameName}/${gameType}/${season}`)

        if (response.ok) {
            league = await response.json();
            return Promise.resolve(league);
        } else {
            throw 'Did not receive 200 response'
        }
    }

    function teamName(teams, team_id, fallback) {
        if (isDef(teams) && isDef(team_id)) {
            const team = teams.find(t => t.id == team_id);
            if (isDef(team) && 'team_name' in team) {
                return team.team_name;
            }
        }
        return fallback;
    }

    // async function getStandings() {
    //     const response = await fetch(`http://localhost:8000/api/league/${league.id}/standings`)

    //     if (response.ok) {
    //         standings = await response.json();
    //     } else {
    //         standings = undefined
    //     }
    // }

    $: game = $games.filter(game => slugify(game.game_name) === gameName)[0] || null;

    $: gameName && gameType && season
</script>

<Page title={title}>
    {#await getLeagues()}
        <Loading/>
    {:then data}
        <div><Link to="/game/{gameName}">{titlecase(deslugify(gameName))}</Link> / {titlecase(deslugify(gameType))} / <strong>Season {titlecase(deslugify(season))}</strong></div>

        {#if data.league.start_date != null}
            <div>League Start: {data.league.start_date.split('T')[0]} | Roster Lock: {data.league.roster_lock.split('T')[0]}</div>
        {/if}

        {#if data.teams !== null}
            <h1>Teams</h1>
            {#each [...new Set(data.teams.map(t => t.division))].sort() as division}
                {#if division !== null}
                    <div>
                        <h2>Division {division}</h2>
                        <table>
                            <thead>
                                <tr class="headings">
                                    <th>Team</th>
                                    <th>Joined</th>
                                    <th>GP</th>
                                    <th>W</th>
                                    <th>L</th>
                                </tr>
                            </thead>
                            <tbody>
                                {#each data.teams.filter(t => t.division == division) as team}
                                    <tr>
                                        <td><NavLink to="/team/{slugify(team.team_name)}">{team.division} {team.team_name} ({team.tag})</NavLink></td>
                                        <td>{data.standings.filter(t => t.team_id == team.id)[0].join_date.split('T')[0]}</td>
                                        <td>{data.standings.filter(t => t.team_id == team.id)[0].gp}</td>
                                        <td>{data.standings.filter(t => t.team_id == team.id)[0].w}</td>
                                        <td>{data.standings.filter(t => t.team_id == team.id)[0].gp - data.standings.filter(t => t.team_id == team.id)[0].w}</td>
                                    </tr>
                                {/each}
                            </tbody>
                        </table>
                    </div>
                {/if}
            {/each}
        {/if}

        <UserSeasons leagueId={data.league.id}/>

        <GameLog leagueId={data.league.id}/>

        <h1>Played Matches</h1>
        <table>
            <thead>
                <tr class="headings">
                    <!-- <th>Round</th> -->
                    <th>Match</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody>
                {#each data.upcoming.filter(m => m.match_date != null).sort((a,b) => new Date(`${b.match_date}Z`).getTime() - new Date(`${a.match_date}Z`).getTime()) as match}
                    <tr>
                        <td>
                            <NavLink to={`/match/${match.id}`}>
                                {teamName(data.teams, match.team_ids[0], 'Team A')}
                                vs. 
                                {teamName(data.teams, match.team_ids[1], 'Team B')}
                            </NavLink>
                        </td>
                        <td>
                            {#if match.match_date}
                                <TimeSince dateString={match.match_date}/>
                            {:else}
                                <em>Unscheduled</em>
                            {/if}
                        </td>
                    </tr>
                {/each}
            </tbody>
        </table>

        {#if data.league.league_type != 'pickup'}
            <h1>Pending Matches</h1>
            <table>
                <thead>
                    <tr class="headings">
                        <!-- <th>Round</th> -->
                        <th>Match</th>
                        <th>Date</th>
                    </tr>
                </thead>
                <tbody>
                    {#each data.upcoming.filter(m => m.match_date == null) as match}
                        <tr>
                            <td>
                                <NavLink to={`/match/${match.id}`}>
                                    {teamName(data.teams, match.team_ids[0], 'Team A')}
                                    vs. 
                                    {teamName(data.teams, match.team_ids[1], 'Team B')}
                                </NavLink>
                            </td>
                            <td>
                                {#if match.match_date}
                                    <TimeSince dateString={match.match_date}/>
                                {:else}
                                    <em>Unscheduled</em>
                                {/if}
                            </td>
                        </tr>
                    {/each}
                </tbody>
            </table>
        {/if}
    {:catch _}
        <Page404 item="league"/>
    {/await}
</Page>
