<script>
    import {ipc, launch, ticket, inGameId, inGame, socket} from '../../stores.js';

    $: $launch && $ticket !== null && $ipc.send('launchGame', $inGameId, $ticket) && launch.set(false);

    $ipc.on('in-game', () => {
        console.log("GAME LAUNCHED")
        inGame.set(true);
        ticket.set(null);
        $socket.emit('in-game', $inGameId);
    });
    $ipc.on('game-exit', ()=> {
        console.log("GAME EXITED")
        launch.set(false);
        inGame.set(false);
        ticket.set(null);
        inGameId.set(null);
        $socket.emit('exit-game');
    });
</script>
