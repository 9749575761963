<script>
    import Ping from './gameserver/Ping.svelte'
    import {RELATIVE_PATH, onlyPage} from '../stores.js'
    import Page from '../components/Page.svelte'
    import Loading from '../components/Loading.svelte'
    import {isDef} from '../utils.js'
    import {tooltip} from '../components/Tooltip.ts'
    import Avatar from '../components/Avatar.svelte'
    import {pingColor, getServerLocations, serverIdMap} from './gameserver/data.js'
    import SortableTable from '../components/SortableTable.svelte';
    import { onMount } from 'svelte';
    import {fly} from 'svelte/transition';
    import { navigate } from 'svelte-routing';

    export let token;

    let pingMap = {};
    let ready = false;
    let submitted = false;
    let startIn = 10000; // 10 seconds until it starts pinging
    let interval;

    async function verify() {
        const response = await fetch(`http://localhost:8000/api/user_token/${token}/verify`)
        if (response.ok) {
            return await response.json()
        }
    }

    async function savePings(username) {
        const response = await fetch('http://localhost:8000/api/user_token/ping', {
            'method': 'POST',
            headers: {
                'Authorization': token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                'pings': pingMap
            })
        })
        if (response.ok) {
            submitted = true;
            startIn = 10000;
            interval = setInterval(() => {
                startIn -= 1000;
                if (startIn < 0) {
                    clearInterval(interval);
                    onlyPage.set(false);
                    navigate(`/user/${username}`);
                }
            }, 1000)
        } else {
            console.error("Request to save pings failed")
        }
    }

    onMount(() => {
        interval = setInterval(() => {
            startIn -= 1000;
            if (startIn < 0) {
                clearInterval(interval)
                ready = true;
            }
        }, 1000)
    })
</script>

<style>
    .logo {
        display: inline-block;
        padding: 0.5em 2em;
        max-width: 64px;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
    }

    .dot-flashing {
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: white;
        color: white;
        animation: dot-flashing 1s infinite linear alternate;
        animation-delay: 0.5s;
        display: inline-block;
        vertical-align: middle;
        align-items: center;
        justify-content: center;
    }
    .dot-flashing::before, .dot-flashing::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
    }
    .dot-flashing::before {
        left: -15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: white;
        color: white;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 0s;
    }
    .dot-flashing::after {
        left: 15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: white;
        color: white;
        animation: dot-flashing 1s infinite alternate;
        animation-delay: 1s;
    }

    @keyframes dot-flashing {
        0% {
            background-color: white;
        }
        50%, 100% {
            background-color: rgba(152, 128, 255, 0.2);
        }
    }

    hr {
        border: 0;
        border-bottom: 2px solid var(--background-highlight);
    }

    button {
        margin: 0.25em;
    }
</style>

<Page title="Calculate Pings">
    <div class="center-pane" style="user-select: none;">
        {#await verify()}
            <Loading/>
        {:then data}
            {#if submitted}
                <div in:fly|global>
                    <h1>Pings Saved</h1>
                    <p style="font-size: 1.5em; margin-top: 0;">👋<br/><Avatar userId={data.user_id} avatar={data.avatar} inline small noPresence/> {data.username}</p>
                    <p>This page will self-destruct in {startIn / 1000}</p>
                </div>
            {:else if isDef(data)}
                {#await getServerLocations()}
                    <Loading/>
                {:then locations}
                    <span in:fly|global>
                        <img class="logo" src="{$RELATIVE_PATH}/bullets.svg" width="50" alt="logo" use:tooltip={"FTW"}/>
                        {#if Object.keys(pingMap).length != Object.keys(locations).length}
                            <div transition:fly|global class="dot-flashing"></div>
                        {/if}
                        {#if isDef(data.creator_avatar)}
                            <img class="logo" src="https://ftwgl.net/api/upload/{data.creator_avatar}?redirect=permanent&ttl=31536000" width="50" alt="flawless" use:tooltip={data.creator_username}/>
                        {:else}
                            <img class="logo" src="{$RELATIVE_PATH}/logos/Discord-Logo-White.svg" width="50" alt="logo" use:tooltip={data.creator_username}/>
                        {/if}
                        
                        <h1>Calculating Pings</h1>
                        <p style="font-size: 1.5em; margin-top: 0;">👋<br/><Avatar userId={data.user_id} avatar={data.avatar} inline small noPresence/> {data.username}</p>
                    </span>

                    {#if Object.keys(pingMap).length == Object.keys(locations).length}
                        <hr/>
                        <button in:fly|global on:click={() => savePings(data.username)} use:tooltip={"Save Pings"}>✔️Submit</button>
                        <button in:fly|global on:click={() => pingMap = {}} use:tooltip={"If known pings look off, recalculate"}>↻Recalculate</button>
                    {:else if ready}
                        <hr/>
                        <p in:fly|global>Calculating pings for <span class="tag monospace">{Object.keys(pingMap).length} / {Object.keys(locations).length}</span> servers.</p>
                        <Ping bind:pingMap/>
                    {/if}
                    {#if startIn > 0}
                        <hr/>
                        <h2 in:fly|global>Starting in {startIn / 1000}</h2>
                    {:else if Object.keys(pingMap).length}
                        <div class="overflow-scroll" style="max-height: 40vh;" in:fly|global>
                            <SortableTable headings={['Country', 'City', 'Ping']} sortCol={2} sortDescending={false}
                            rows={Object.keys(pingMap).map(dcid => [
                                {tooltip: locations[dcid].country, col: `<img src="${$RELATIVE_PATH}/countries/${locations[dcid].country}.svg" height="13"/>`},
                                locations[dcid].city,
                                {type: 'ping', col: pingMap[dcid]}
                            ])}/>
                        </div>
                    {/if}
                {/await}
            {:else}
                <h1>Invalid Link</h1>
                <p>Request a new link to calculate server pings.</p>
                <a href="/" class="ftw"><img class="logo" src="{$RELATIVE_PATH}/bullets.svg" width="40" alt="FTW Logo" style="vertical-align: sub; padding: 0;"/> Go Home</a>
            {/if}
        {/await}
    </div>
</Page>
