<script>
    import {IS_ELECTRON, servers, serverPings, RELATIVE_PATH, likedServers} from '../../stores.js';
    import {closePage} from '../PanelLayout.svelte';
    import SortableTable from '../SortableTable.svelte'
    import {countryMap} from '../../pages/gameserver/data.js'
    import {isDef, getGameType, ipOnly} from '../../utils.js'
    import ClickToCopy from '../ClickToCopy.svelte'
    import GameLaunch from '../electron/GameLaunch.svelte'

    import {pingColor} from '../../pages/gameserver/data.js';
    import {tooltip} from '../Tooltip.ts';
    import LikeButton from '../LikeButton.svelte';

    import PanelTemplate from './PanelTemplate.svelte';
    import Loading from '../Loading.svelte';

    export let data;

    function liked(e) {
        console.log(e)
        likedServers.update(ls => {
            return Object.assign(ls, e.detail)
        })

        const updatedServers = [...$servers]

        updatedServers.forEach(s => {
            if (s['address'] in e.detail) {
                s['liked'] = true;
            }
        })

        servers.set([...updatedServers])
    }

    function unliked(e) {
        delete $likedServers[e.detail]
        const updatedServers = [...$servers]

        updatedServers.forEach(s => {
            if (s['address'] == e.detail) {
                s['liked'] = false;
            }
        })

        servers.set([...updatedServers])
    }

    async function getPlayers(gameId, serverAddress) {
        const response = await fetch(`https://ftwgl.net/api/master/servers/${gameId}/${serverAddress}/players`)

        if (response.ok) {
            const players = await response.json();
            return players;
        } else {
            return [];
        }
    }

    $: server = $servers.find(s => s['address'] == data['serverAddress'])
</script>

<PanelTemplate>
    <span slot="nav">
        {#if $IS_ELECTRON}
           <GameLaunch gameId={data.game.id} gameName={data.game.game_name} address={server['address']}/>
        {/if}
    </span>

    <div slot="content">
        <h1 style="margin-top: 0;">
            {#key server['address']}
                <LikeButton likeType="server" likeId={server['address']} on:liked={liked} on:unliked={unliked}/>
            {/key}
            {server['config']['sv_hostname'].replace(/\^+\d/g, '')}
        </h1>

        <div class="card split">
            <span>
                <span class="monospace">{server['address']} <ClickToCopy text={server['address']} buttonOnly={true}/></span><br/>
                <span class="monospace"><strong>{server['number_players']}</strong>/{server['config']['sv_maxclients']}</span> ({server['number_bots']} bots)
                {#key server.config['g_gametype']}
                    <span class="tag" use:tooltip={getGameType(parseInt(server.config['g_gametype']))[0]}>{getGameType(parseInt(server.config['g_gametype']))[1]}</span>
                {/key}
                {#if $IS_ELECTRON}
                    {#if ipOnly(server['address']) in $serverPings && $serverPings[ipOnly(server['address'])] < 600}
                        <br/><span class="monospace"><span style="color: {pingColor($serverPings[ipOnly(server['address'])])}">{$serverPings[ipOnly(server['address'])]}ms</span></span>
                    {:else}
                        <br/><Loading inline active/>
                    {/if}
                {/if}
            </span>
            <span style="display: flex; vertical-align: middle; justify-content: center; align-items: center;">
                {#key server['country']}
                    <span><img src="{$RELATIVE_PATH}/countries/{server['country']}.svg" alt={server['country']} use:tooltip={countryMap[server['country']]} width="48px"/></span>
                {/key}
            </span>
        </div>

        <h2>Players</h2>
        {#key server.address}
            {#await getPlayers(data.game.id, server.address)}
                <Loading/>
            {:then resp}
                {#if isDef(resp.players) && resp.players.length > 0}
                    <SortableTable headings={['Player', 'Score', 'Ping']} sortCol={1} rows={resp.players.map(player => [
                        player['name'].replace(/\^+\d/g, '').replace(/\"/g, ''),
                        player['score'],
                        {type: 'ping', col: player['ping']}
                    ])}/>
                {:else}
                    <div class="card">No players</div>
                {/if}
            {/await}
        {/key}
    </div>
</PanelTemplate>
