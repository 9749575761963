<script>
    import { accessToken, games, gameTypes, userData } from '../stores.js';
    import { slugify, deslugify, titlecase, isDef } from '../utils.js';
    import Page404 from './Page404.svelte';
    import Page from '../components/Page.svelte'

    import Avatar from '../components/Avatar.svelte';
    import Dropdown from '../components/Dropdown.svelte';
    import NavLink from '../components/NavLink.svelte';
    import Loading from '../components/Loading.svelte';
    import { tooltip } from '../components/Tooltip.ts';
    import { Link } from 'svelte-routing';

    export let gameName = '';

    let teamMemberSizes = null;

    $: isDef($accessToken) && getTeamMemberSizes($accessToken);

    $: game = $games.filter(game => game.slug === gameName)[0] || null;
    // $: game && getLeagues(game.id);
    $: gametypes = game != null && $gameTypes.filter(function(gameType) {
        if (isDef(game)) {
            return gameType.game_id == game.id;
        } else {
            return [];
        }
    });
    $: teamsLeaderOn = $userData != null && $userData.teams.filter(team => 'role' in team && team.role == 'leader');


    async function getLeagues(game_id) {
        const response = await fetch('http://localhost:8000/api/leagues/' + game_id)
        if (response.ok) {
            return await response.json();
        } else {
            return undefined;
        }
    }

    async function findGameTypeById(gameTypeId) {
        return Promise.resolve(gametypes.find(gt => gt.id == gameTypeId));
    }

    async function getTeamMemberSizes(token) {
		if (token != null) {
			const response = await fetch('http://localhost:8000/api/team/member-size', {
				method: 'GET',
				headers: {
					authorization: 'Bearer ' + token
				}
			});
			if (response.ok) {
				teamMemberSizes = await response.json();
			}
		}
    }
    
    async function signUpForLeague(game, gametype, team, league) {
        if (confirm(`Signup "${team.team_name}" for ${game.game_name}, ${gametype.game_type}: ${league.season}`)) {
            const response = await fetch('http://localhost:8000/api/leagues/signup', {
                method: 'POST',
                headers: {
					authorization: 'Bearer ' + $accessToken
				},
                body: JSON.stringify({
                    'team_id': team.id, 
                    'league_id': league.id
                })
            })
            if (response.ok) {
                alert(`${team.team_name} signed up successfully.`)
            }
        }
    }
    
</script>

<style>
    h1:first-of-type {
        margin-top: 0;
    }
    button:disabled {
        background-color: #eee;
        cursor: not-allowed;
        border: 0px;
        color: #666;
    }
    .leagues {
        overflow: scroll;
        white-space: nowrap;
    }
    .league {
        display: inline-block;
        margin: 0.5em;
        background-color: rgba(0,0,0,0.2);
        padding: 0.5em 1em;
        border-radius: 8px;
    }
    .league h2 {
        margin: 0;
    }
    .league ul {
        list-style: none;
        padding: 0;
    }
    h1 {
        margin-left: 0.25em;
    }
    h2 {
        margin-top: 1em;
        margin-left: 0.5em;
    }
</style>

<Page title="Leagues {$games.length > 0 && isDef(game.abbreviation) ? `(${game.abbreviation})` : ''}">
    {#if game != null}
        <h1>{titlecase(deslugify(gameName))}</h1>
        {#await getLeagues(game.id)}
            <Loading/>
        {:then leagues} 
            {#each Object.keys(leagues).reverse() as leagueUser}
                {#if leagues[leagueUser].active.length}
                    <Link to="/user/{leagues[leagueUser].username}">
                        <h2 style="display: flex; align-items: center;">
                                {#if isDef(leagues[leagueUser].avatar_hash)}
                                    <Avatar avatar={leagues[leagueUser].avatar_hash} userId={leagues[leagueUser].id} inline noPresence/>&nbsp;
                                {/if}
                                {leagues[leagueUser].username}
                        </h2>
                    </Link>
                    <div class="leagues overflow-scroll">
                        {#each leagues[leagueUser].active as league}
                            <div class="league">
                                {#await findGameTypeById(league.game_type_id)}&nbsp;
                                {:then gameType} 
                                    <h2><Link to="/leagues/{gameName}/{slugify(gameType.game_type)}/{slugify(league.season)}">
                                        {gameType.game_type}:<br/><em>{league.season}</em></Link>
                                    </h2>
                                {/await}
                            </div>
                        {/each}
                    </div>
                {/if}
            {/each}
        {/await}
    {:else}
        <Page404 item="game"/>
    {/if}
</Page>