<script>
    import {RELATIVE_PATH, isMobile, userKV, games, selectedGameId, searchHistory} from '../stores.js';
    import {tooltip} from './Tooltip';
    import {Link, navigate} from 'svelte-routing';
    import {slugify, isDef, search} from '../utils.js';
    import Avatar from './Avatar.svelte'
    import localforage from 'localforage';
    import { onMount } from 'svelte';

    let showSearch = false
    let searchInput = null;
    let searchTerm = '';
    let results = {pages: [], users: [], teams: []};
    let resultList = []
    let resultSelection = undefined;
    let currentSelection = undefined;
    let debounceTimer;
    let ready = false;

    onMount(async () => {
        const history = await localforage.getItem('searchHistory')
        console.log("LOCAL FORAGE HISTORY", history)
        searchHistory.set(history)
        if (history != results) {
            results = history;
        }
        console.log('searchHistory mount', $searchHistory)
        ready = true;
    })

    $: game = isDef($games) && $games.find(g => g.id == $selectedGameId)

    $: gameUpdated(game)

    $: if (showSearch && searchInput) searchInput.focus()

    $: performSearch(searchTerm)
    $: pageSearch(searchTerm)

    function clearHistory() {
        console.log("CLEAR HISTORY")
        searchHistory.set({pages: [], users: [], teams: []})
        localforage.setItem('searchHistory', $searchHistory)
    }

    let pages = {
        'Home': {
            path: '/',
            icon: '/bullets.svg'
        },
        'Settings': {
            path: '/settings',
            icon: '⚙️'
        },
        'Gameservers: Rentals': {
            path: '/gameservers',
            icon: '🎮'
        }
        // 'Match': '/match'
    }

    function gameUpdated(g) {
        if (isDef(g)) {
            pages['Recent Matches'] = {
                path: `/recent/${game.slug}`,
                icon: '/icons/recent.svg'
            },
            pages['Gameservers: Browse'] = {
                path: `/gameservers/browse/${game.slug}`,
                icon: '/icons/globe.png'
            }
            pages['Stats'] = {
                path: `/stats/${game.slug}`,
                icon: '/icons/chart.svg'
            }
        }
    }

    function fuzzySearch(query) {
        const results = [];

        for (const [key, val] of Object.entries(pages)) {
            if (search(query.toLowerCase(), key.toLowerCase())) {  // Consider adjusting the threshold as needed
                results.push({ key, ...val, key_highlight: highlightSearchTerm(key, query)});
            }
        }

        return results

        // // Sort the results by score in descending order
        // return results.sort((a, b) => b.score - a.score);
    }

    function clickHandler(e) {
        for (const ele in e.path) {
            if (e.path[ele].className && e.path[ele].className.split(' ').includes("searchPane")) {
                return
            }
        }
        showSearch = false;
    }

    function handleKeydown(e) {
        if (e.ctrlKey && (e.key == 'f' || e.key == 'F')) {
            if (!showSearch) {
                e.preventDefault()
                showSearch = true
            } else {
                showSearch = false
            }
        }

        if (!showSearch) {
            return
        }

        if (e.keyCode == 27) { // esc
            showSearch = false
        } else if (resultList) {
            switch (e.keyCode) {
                case 40: // down
                e.preventDefault()
                if (resultSelection == undefined)
                    resultSelection = 0;
                else {
                    resultSelection++
                    if (resultSelection >= resultList.length) resultSelection = 0
                }
                break;

                case 38: // up
                e.preventDefault()
                if (resultSelection == undefined)
                    resultSelection = resultList.length - 1;
                else {
                    resultSelection--;
                    if (resultSelection < 0) resultSelection = resultList.length - 1
                }
                break

                case 13: // enter
                if (resultList.length == 1 || typeof(resultSelection) == 'number' && resultSelection >= 0) {
                    navigateTo(resultSelection);
                }
                e.preventDefault()
                break;
            }
            if (resultSelection in resultList && resultList[resultSelection]) {
                if ('path' in resultList[resultSelection]) {
                    currentSelection = `p:${resultList[resultSelection].key}`
                } else if ('username' in resultList[resultSelection]) { // its a user
                    currentSelection = `u${resultList[resultSelection].id}`
                } else if ('team_name' in resultList[resultSelection]) { // its a team
                    currentSelection = `t${resultList[resultSelection].id}`
                }
            }
        }
    }

    function addToHistory(type, obj) {
    if (type in $searchHistory) {
        var findKey = type === "pages" ? "path" : "id";

        const existsIndex = $searchHistory[type].findIndex(o => o[findKey] === obj[findKey]);

        if (existsIndex !== -1) {
            // Move existing item to front
            const [existingItem] = $searchHistory[type].splice(existsIndex, 1);
            $searchHistory[type].unshift(existingItem);
        } else {
            // Add new item to front
            $searchHistory[type].unshift(obj);
        }

        // Limit history length to 5
        if ($searchHistory[type].length > 5) {
            $searchHistory[type].pop();
        }
    } else {
        $searchHistory[type] = [obj];
    }
    console.log("set searchHistory", $searchHistory);
    localforage.setItem('searchHistory', $searchHistory);
}

    function navigateTo(resultSelection) {
        if ('path' in resultList[resultSelection]) {
            addToHistory('pages', resultList[resultSelection])
            navigate(resultList[resultSelection].path)
            showSearch = false
        } else if ('username' in resultList[resultSelection]) { // its a user
            addToHistory('users', resultList[resultSelection])
            navigate(`/user/${encodeURIComponent(resultList[resultSelection].username)}`);
            showSearch = false
        } else if ('team_name' in resultList[resultSelection]) { // its a team
            addToHistory('teams', resultList[resultSelection])
            navigate(`/team/${slugify(resultList[resultSelection].team_name)}`);
            showSearch = false
        }
    }

    function pageSearch(searchTerm) {
        // if (searchTerm.length == 0) {
        //     results = $searchHistory
        //     resultList = $searchHistory.pages.concat(isDef($searchHistory.users) ? $searchHistory.users : []).concat(isDef($searchHistory.teams) ? $searchHistory.teams : [])
        // }
        if (searchTerm && searchTerm.length > 0) {
            const localResults = fuzzySearch(searchTerm)
            results.pages = localResults
            resultList = localResults.concat(isDef(results.users) ? results.users : []).concat(isDef(results.teams) ? results.teams : [])
            if (resultList.length > 0) {
                resultSelection = 0;
            }
        }
    }

    async function performSearch(searchTerm) {
        if (searchTerm.length == 0) {
            results.users = [];
            results.teams = [];
        }
        clearTimeout(debounceTimer); // debounce
		debounceTimer = setTimeout(async () => {
            if (searchTerm && searchTerm.length > 0) {
                const localResults = fuzzySearch(searchTerm)
                const response = await fetch(`http://localhost:8000/api/search/${searchTerm}`)
                if (response.ok) {
                    results = await response.json();
                    results.users.map(user => user.username_highlight = highlightSearchTerm(user.username, searchTerm))
                    results.teams.map(team => team.team_name_highlight = highlightSearchTerm(team.team_name, searchTerm))
                    results.teams.map(team => team.tag_highlight = highlightSearchTerm(team.tag, searchTerm))
                    results.pages = localResults
                    resultList = localResults.concat(isDef(results.users) ? results.users : []).concat(isDef(results.teams) ? results.teams : [])
                    if (resultList.length > 0) {
                        resultSelection = 0;
                    }
                    return
                }
                results = localResults;
                resultList = localResults;
            } 
            // else {
            //     results = $searchHistory
            //     resultList = $searchHistory.pages.concat(isDef($searchHistory.users) ? $searchHistory.users : []).concat(isDef($searchHistory.teams) ? $searchHistory.teams : [])
            // }
            console.log(results)
		}, 150);
    }

    function highlightSearchTerm(result, searchTerm) {
        let index = result.toLowerCase().indexOf(searchTerm.toLowerCase());
        if (index === -1) {
            return result;
        }

        let highlightedResult = "";
        let startIndex = 0;

        while (index !== -1) {
            highlightedResult += result.slice(startIndex, index) +
                `<span style="color: orange">` + result.slice(index, index + searchTerm.length) + `</span>`;

            startIndex = index + searchTerm.length;
            index = result.toLowerCase().indexOf(searchTerm.toLowerCase(), startIndex);
        }

        highlightedResult += result.slice(startIndex);

        return highlightedResult;
}
</script>

<style>
    .search {
        position: fixed;
        height: 100vh;
        width: 100vw;
        background-color: rgba(1,1,1,0.5);
        backdrop-filter: blur(1px);
        top: 0;
        left: 0;
        z-index: 9999999999999;
    }

    .searchPane {
        position: flex;
        display: block;
        margin: auto;
        max-width: 500px;
        background-color: var(--background-color);
        border-radius: 8px;
        padding: 1em;
    }

    .searchPane input {
        width: 100%;
        border: none;
        background-color: var(--background-dark);
        /* min-width: 450px; */
        margin: 0;
        padding: 0.5em;
        color: white;
        font-size: 1.5em;
    }

    @media (min-width: 800px) {
        .search {
            display: flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
        }
        .searchPane input {
            min-width: 450px;
        }
    }

    .searchPane input:active, .searchPane input:focus-visible {
        border: none;
        outline: none;
    }

    .result {
        padding: 0.5em;
        margin: 0.2em 0;
        border-radius: 3px;
        background-color: var(--background-dark);
        color: white;
    }

    .flex-center {
        vertical-align: middle;
        display: flex;
        align-items: center;
    }
    
    .result:hover {
        background-color: var(--dark-color)
    }

    .result.selected {
        outline: 2px solid orange !important;
    }

    .footer {
        font-size: 0.8em;
        user-select: none;
        display: flex;
        justify-content: space-between;
        text-align: center;
    }

    hr {
        border: 0;
        border-bottom: 2px solid var(--background-highlight);
    }

    .searchType {
        font-size: 0.8em;
        color: var(--text-muted);
    }
</style>

<svelte:window on:keydown={handleKeydown}/>

<button class="border" use:tooltip={$isMobile ? "Search" : "(Ctrl+F)"} on:click={() => showSearch = true}>
    <span class="nav-button">
        <img src="{$RELATIVE_PATH}/icons/search.svg" alt="Search" width="18" height="22"/>
        {#if !$isMobile && !$userKV.hideButtonLabels}
            Search
        {/if}
    </span>
</button>

{#if showSearch && ready}
    <div class="search" on:click={clickHandler}>
        <div class="searchPane">
            <input type="text" placeholder="Search" bind:this={searchInput} bind:value={searchTerm}/>
            <div class="overflow-scroll" style="max-height: 85vh;">
                {#if results.pages.length}
                    {#if searchTerm.length == 0}
                    <div class="split flex-center" style="margin-top: 3px;">
                        <strong>History</strong>
                        <button on:click={() => clearHistory()}>Clear</button>
                    </div>
                    {/if}

                    {#each results.pages as page}
                        <Link to={page.path}>
                            <div class="result split flex-center" class:selected={currentSelection == `p:${page.key}`}>
                                <div>
                                    {#if 'icon' in page}
                                        {#if page.icon[0] != '/'}
                                            {page.icon}
                                        {:else}
                                            <img style="padding-top: 3px" width="20" src="{$RELATIVE_PATH}{page.icon}"/>
                                        {/if}
                                        &nbsp;
                                    {/if}

                                    {#if searchTerm.length == 0}
                                        {page.key}
                                    {:else}
                                        {@html page.key_highlight}
                                    {/if}
                                </div>
                                <div class="searchType right right-text monospace">Page</div>
                            </div>
                        </Link>
                    {/each}
                {/if}

                {#if results.users.length}
                    {#each results.users as user}
                        <Link to="/user/{encodeURIComponent(user.username)}">
                            <div class="result split flex-center" class:selected={currentSelection == `u${user.id}`}>
                                <div>
                                    <Avatar userId={user.id} avatar={user.avatar} online={user.online} inline small/>&nbsp;
                                    {#if searchTerm.length == 0}
                                        {user.username}
                                    {:else}
                                        {@html user.username_highlight}
                                    {/if}
                                </div>
                                <div class="searchType right right-text monospace">User</div>
                            </div>
                        </Link>
                    {/each}
                {/if}
                {#if results.teams.length}
                    {#each results.teams as team}
                        <Link to="/team/{slugify(team.team_name)}">
                            <div class="result split flex-center" class:selected={currentSelection == `t${team.id}`}>
                                {#if searchTerm.length == 0}
                                    <div>{@html team.team_name} ({@html team.tag})</div>
                                {:else}
                                    <div>{@html team.team_name_highlight} ({@html team.tag_highlight})</div>
                                {/if}
                                <div class="searchType right right-text monospace">Team</div>
                            </div>
                        </Link>
                    {/each}
                {/if}

                {#if !$isMobile}
                    <hr/>
                    <div class="footer">
                        <span><strong>↑↓</strong> to navigate</span>
                        <span><strong>↵</strong> to open</span>
                        <span><strong>esc</strong> to dismiss</span>
                    </div>
                {/if}
            </div>
        </div>
    </div>
{/if}
