<script lang="ts">
    import {isMobile, RELATIVE_PATH} from '../../stores.js'

    import Chart from '../../components/Chart.svelte';
    import UserLink from '../../components/UserLink.svelte';

    import Loading from '../../components/Loading.svelte';
    import DemoButton from '../../components/DemoButton.svelte';
    import {tooltip, TooltipPosition} from '../../components/Tooltip';
    import TimeSince from '../../components/TimeSince.svelte';
    import SortableTable from '../../components/SortableTable.svelte';
    import FeatureFlag from '../../components/FeatureFlag.svelte';

    import {titlecase, zeroPad, isDef} from '../../utils.js';

    export let match = undefined;
    export let gameId: number = undefined;
    export let gameName: string = undefined;
    export let matchRound: any = undefined
    export let demos = undefined;
    export let avatars = undefined;
    

    enum ChartMetric {
        KILLS = "Kills",
        DAMAGE_GIVEN = "Damage Given",
        DAMAGE_TAKEN = "Damage Taken",
        HEADSHOTS = "Headshots"
    }
    let chartMetric: ChartMetric = ChartMetric.DAMAGE_GIVEN;
    let chartPerRound = false;
    let chartPlayerFilter = [];
    let chartSmooth = true;
    let timeLimit = 300;
    const blueColors = ['#4aa6ff', '#1873cc', '#125699', '#0f487f', '#0c3966', '#061c33', '#030e19']
    const redColors = ['#cc0000', '#b30000', '#990000', '#660000', '#330000', '#190000']

    $: demoMap = demos !== undefined && demosToDemoMap(demos, matchRound.id)
    $: multiviewDemo = demos !== undefined && getMultiviewDemo(demos, matchRound.id)
    $: avatarMap = avatars !== undefined && avatarsToAvatarMap(avatars)
    $: acUsageMap = matchRound.ac_usage !== undefined && acUsageToMap(matchRound.ac_usage)
    $: aimOrSkeet = ['AIM', 'SKEET'].includes(match.gt_abbreviation)
    let playerNumberToAuth = {}

    enum DuelMetric {
        KILLS = "Kills",
        DAMAGE = "Damage",
        HEADSHOTS = "Headshots",
        NADEKILLS = "Nade Kills"
    }
    enum WeaponMetric {
        KILLS = "K",
        HITS = "H",
        DAMAGE = "DG",
        HEADSHOTS = "HS"
    }
    let memoizedRoundScore = [];
    let memoizedKillCombos = {};
    let memoizedHeadshotCombos = {};
    let memoizedNadeCombos = {};
    let memoizedDamageCombos = {};
    let duelMetric: DuelMetric = DuelMetric.KILLS;
    let duelMetricType = 'number';
    let memoizeFirstRedIndex = undefined;
    let memoizedNadeKills = undefined;
    let memoizedHeadShots = undefined;
    let memoizedHits = undefined;
    let memoizedEntryHitsGiven = undefined;
    let memoizedEntryHitsTaken = undefined;
    let memoizedChartStats = undefined;
    let memoizedWeaponStats = undefined;

    function getOptions(data, perRound, metric, playerNos, smooth) {
        if (memoizedChartStats == undefined) {
            memoizedChartStats = calculateChartData(data);
        }

        if(!metric) {
            return null;
        }

        const teamCounter = {
            'blue': 0,
            'red': 0
        }

        function getColor(team) {
            var color = 'grey'
            if (team in teamCounter) {
                if (team == 'blue') {
                    color = blueColors[teamCounter[team]]
                } else {
                    color = redColors[teamCounter[team]]
                }
                teamCounter[team]++
            }
            return color;
        }

        let xAxisSeconds = [];
        for (let i = timeLimit; i >= 0; i--) {
            const minutes = Math.floor(i / 60);
            const seconds = i % 60;
            const timeFormatted = minutes > 0 ? `${minutes}min ${seconds}sec` : `${seconds}sec`;
            const label = `${timeFormatted} remaining`; // or a more succinct form like `${timeFormatted} left`
            xAxisSeconds.push(label);
        }

        return {
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                    label: {
                        backgroundColor: '#6a7985'
                    }
                },
                backgroundColor: '#111',
                textStyle: {
                    color: '#fff'
                },
                borderWidth: 0,
                order: 'valueDesc'
            },
            toolbox: {
                feature: {
                }
            },
            grid: {
                left: 0,
                containLabel: true,
                bottom: 60,
                top: 10,
                right: 0
            },
            legend: {
                data: memoizedChartStats.Legend,
                backgroundColor: '#222',
                textStyle: {
                    color: '#fff'
                },
                bottom: 0
            },
            xAxis: {
                type: 'category',
                data: aimOrSkeet ? xAxisSeconds : memoizedChartStats.Rounds,
                name: aimOrSkeet ? 'Seconds' : 'Round',
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: '#999',
                        width: 1,
                        type: 'dotted',
                        opacity: 0.1
                    },
                    interval: 9
                }
            },
            yAxis: {
                type: 'value',
                name: metric,
                axisLabel: {
                    show: true,
                    inside: true,
                    formatter: '{value}',
                    textStyle: {
                        color: '#FFF',
                        fontSize: 12
                    }
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        type: 'dotted',
                        opacity: 0.15
                    },
                    interval: 1000
                }
            },
            series: Object.entries(memoizedChartStats.Metric)
            .filter(([playerNo]) => playerNos.includes(parseInt(playerNo)))
            .filter(([_, player]) => sum(player[ChartMetric.DAMAGE_GIVEN]) > 0 || sum(player[ChartMetric.DAMAGE_TAKEN]) > 0)
            .sort((a, b) => {
                return sum(a[1][metric]) < sum(b[1][metric])
            })
            // .sort((a, b) => a[1]['team'] > b[1]['team'])
            .map(([_, player]) => {
                return {
                    data: accumulateListOfNumbers(player[metric], !perRound),
                    type: perRound ? 'scatter' : 'line',
                    name: player['name'],
                    emphasis: {
                        focus: 'series'
                    },
                    color: getColor(player['team']),
                    smooth: smooth,
                };
            })
        };
    }

    function sum(list) {
        var total = 0;
        list.forEach(v => total += v);
        return total;
    }

    function accumulateListOfNumbers(list: number[], apply: boolean) {
        if (list.length <= 1 || !apply) {
            return list;
        }
        let newList = list.slice()
        for (var i = 1; i < newList.length; i++) {
            newList[i] += newList[i-1]
        }
        return newList
    }

    function calculateChartData(data) {
        const chartData = {
            Rounds: [],
            Metric: {},
            Legend: []
        };

        if (isDef(data.stats.Rounds)) {
            data['stats']['Players'].forEach(player => {
                if (isDef(player.Rounds)) {
                    if ('DamageGiven' in player && player['DamageGiven'] > 0 || 'DamageTaken' in player && player['DamageTaken'] > 0) {
                        chartData.Legend.push(playerAuthOrName(player))
                        chartData.Metric[player['PlayerNo']] = {}
                        chartData.Metric[player['PlayerNo']].name = playerAuthOrName(player)
                        chartData.Metric[player['PlayerNo']].team = player['Team']
                        chartData.Metric[player['PlayerNo']][ChartMetric.KILLS] = player.Rounds['Kills']
                        chartData.Metric[player['PlayerNo']][ChartMetric.DAMAGE_GIVEN] = player.Rounds['Kills'].map(k => 0)
                        chartData.Metric[player['PlayerNo']][ChartMetric.DAMAGE_TAKEN] = player.Rounds['Kills'].map(k => 0)
                        chartData.Metric[player['PlayerNo']][ChartMetric.HEADSHOTS] = player.Rounds['Kills'].map(k => 0)
                    }
                }
            })

            data['stats']['Rounds'].forEach((round, i) => {
                chartData.Rounds.push(`${i + 1}${round['Winner']}`);
                if ('HitLog' in round && isDef(round['HitLog'])) {
                    round['HitLog'].forEach(hit => {
                        if ('Shooter' in hit && ![null, undefined].includes(hit['Shooter']) && 'Hit' in hit && ![null, undefined].includes(hit['Hit'])) {
                            if (hit['Shooter'] in chartData.Metric && hit['Hit'] in chartData.Metric) {
                                chartData.Metric[hit['Shooter']][ChartMetric.DAMAGE_GIVEN][i] += hit['Damage']
                                chartData.Metric[hit['Hit']][ChartMetric.DAMAGE_TAKEN][i] += hit['Damage']
                            }
                        }

                        if (isHitHeadshot(hit) && hit['Shooter'] in chartData.Metric) {
                            chartData.Metric[hit['Shooter']][ChartMetric.HEADSHOTS][i] += 1;
                        }
                    })
                }
            })
        }

        if (aimOrSkeet) {
            timeLimit = data['stats']['TimeLimit'] * 60; // minute to seconds
            console.log("TIMELIMIT: ", timeLimit)
            data['stats']['Players'].forEach(player => {
                if ('Damage' in player && player['Damage'] > 0) {
                    chartData.Legend.push(playerAuthOrName(player))
                    chartData.Metric[player['PlayerNo']] = {}
                    chartData.Metric[player['PlayerNo']].name = playerAuthOrName(player)
                    chartData.Metric[player['PlayerNo']].team = player['Team']
                    chartData.Metric[player['PlayerNo']][ChartMetric.DAMAGE_GIVEN] = new Array(timeLimit).fill(0);
                    chartData.Metric[player['PlayerNo']][ChartMetric.KILLS] = new Array(timeLimit).fill(0);
                    chartData.Metric[player['PlayerNo']][ChartMetric.HEADSHOTS] = new Array(timeLimit).fill(0);
                }
            })

            data['stats']['HitLog'].forEach(hit => {
                const idx = timeLimit - hit.Seconds;
                chartData.Metric[hit.Shooter][ChartMetric.DAMAGE_GIVEN][idx] += hit.Damage
                
                if (isHitHeadshot(hit) && hit['Shooter'] in chartData.Metric) {
                    chartData.Metric[hit['Shooter']][ChartMetric.HEADSHOTS][idx] += 1;
                }
            });

            data['stats']['KillLog'].forEach(kill => {
                const idx = timeLimit - kill.Seconds
                if (!['Telefrag', 'Unknown'].includes(kill.Weapon) && playerNumberToAuth[kill.Killer] != '---') {
                    chartData.Metric[kill.Killer][ChartMetric.KILLS][idx]++
                }
            })
        }

        return chartData;
    }

    function getMultiviewDemo(demos, matchRoundId) {
        for (const demo in demos) {
            if (demos[demo].urt_auth == null && demos[demo].match_round_id == matchRoundId) {
                return demos[demo]
            }
        }
        return undefined
    }

    function demosToDemoMap(demos, matchRoundId) {
        let demoMap = {}
        for (const demo in demos) {
            for (const i in demos[demo].auths) {
                if (demos[demo].match_round_id != matchRoundId) {
                    continue;
                }
                const auth = demos[demo].auths[i];
                demoMap[auth.toLowerCase()] = demos[demo]
            }
        }
        console.log('demoMap:', demoMap)
        return demoMap;
    }

    function avatarsToAvatarMap(avatars) {
        let avatarMap = {}
        for (const avatar in avatars) {
            for (const i in avatars[avatar].auths) {
                const auth = avatars[avatar].auths[i];
                avatarMap[auth.toLowerCase()] = avatars[avatar]
            }
        }
        return avatarMap;
    }

    function acUsageToMap(acUsage) {
        let acUsageMap = {}
        for (const ac in acUsage) {
            acUsageMap[acUsage[ac].user_id] = acUsage[ac]
        }
        console.log("AC_USAGE", acUsageMap)
        return acUsageMap;
    }

    async function getMatchRoundStats(roundId) {
        let stats;

        const response = await fetch(`http://localhost:8000/api/match-round/${roundId}/stats`)
        if (response.status === 200) {
            stats = await response.json();
        } else {
            return;
        }

        stats.stats.Players.sort((a, b) => (a.Team > b.Team) ? 1 : (a.Team === b.Team) ? ((a.Damage < b.Damage) ? 1 : -1) : -1)

        chartPlayerFilter = stats.stats.Players.map(p => p['PlayerNo'])

        playerNumberToAuth = {}
        stats.stats.Players.forEach(player => {
            playerNumberToAuth[player['PlayerNo']] = player['Auth']
        })

        if (isDef(stats.stats.Rounds)) {
            calculateRoundScores(stats.stats.Rounds)
            calculateKillAndDamageCombos(stats.stats.Rounds)
            calculateWeaponStats(stats.stats.Rounds)
        } else {
            if (isDef(stats.stats.CtfEvents)) {
                console.log(stats.stats.CtfEvents)
                calculateCtfScores(stats.stats.CtfEvents.filter(e => [0,1].includes(e.Event)).map(e => e.Event))
            }
            calculateKillAndDamageCombos([stats.stats])
            calculateWeaponStats([stats.stats])
        }

        return stats
    }

    function lastAlive(dict: object): number {
        if ('LastAlive' in dict && isDef(dict['LastAlive'])) {
            return dict['LastAlive'].length
        } else {
            return 0
        }
    }

    function clutchVsN(dict: object) {
        const clutch = {1:0, 2:0, 3:0, 4:0, 5:0}
        if (!('Clutch' in dict)) {
            return clutch;
        } else {
            try {
                if ('LastAlive' in dict && isDef(dict['LastAlive'])) {
                    dict['LastAlive'].forEach(lastAlive => {
                        if((dict['Clutch'].includes(lastAlive['Round']))) {
                            clutch[lastAlive['Opponents']]++;
                        }
                    });
                }
            } catch(e) {
                console.log(e)
            }
        }
        return clutch;
    }

    async function clutchedRound(player, roundIdx) {
        if ('LastAlive' in player.Rounds && player.Rounds['LastAlive']) {
            const lastAlive = player.Rounds.LastAlive.find(r => r.Round == roundIdx + 1)
            if (lastAlive && 'Clutch' in player.Rounds && player.Rounds.Clutch.includes(lastAlive.Round) && 'Opponents' in lastAlive) {
                return Promise.resolve(lastAlive.Opponents)
            }
        }
        return Promise.resolve(null)
    }

    function getPlayerCombo(metric ,playerNo1, playerNo2) {
        let dataset; 
        if (metric == DuelMetric.KILLS) {
            dataset = memoizedKillCombos;
        } else if (metric == DuelMetric.DAMAGE) {
            dataset = memoizedDamageCombos;
        } else if (metric == DuelMetric.HEADSHOTS) {
            dataset = memoizedHeadshotCombos;
        } else if (metric == DuelMetric.NADEKILLS) {
            dataset = memoizedNadeCombos;
        } else {
            return null;
        }
        let comboKey: any = [playerNo1, playerNo2]
        comboKey.sort()
        if (comboKey in dataset) {
            let killCombo = dataset[comboKey]
            if (playerNo1 > playerNo2) {
                return killCombo.reverse()
            } else {
                return killCombo
            }
        }
        return null
    }

    function calculateRoundScores(rounds) {
        let redScore = 0;
        let blueScore = 0;
        let draw = 0;
        for (let i = 0; i < rounds.length; i++) {
            if ('KillLog' in rounds[i] && isDef(rounds[i]['KillLog']) && rounds[i]['KillLog'].length > 0) {
                switch (rounds[i].Winner) {
                    case 'B':
                        blueScore++;
                        break;
                    case 'R':
                        redScore++;
                        break;
                    default:
                        draw++;
                }
                memoizedRoundScore[i] = [redScore, blueScore, draw]
            }
        }
    }

    function calculateCtfScores(caps) {
        let redScore = 0;
        let blueScore = 0;
        for (let i = 0; i < caps.length; i++) {
            if (caps[i] == 0) {
                redScore++;
            } else {
                blueScore++;
            }
            memoizedRoundScore[i] = [redScore, blueScore, null]
        }
        console.log(memoizedRoundScore)
    }

    function calculateKillAndDamageCombos(rounds) {
        memoizedKillCombos = {}
        memoizedNadeCombos = {}
        try {
            rounds.forEach((round) => {
                if ('KillLog' in round && isDef(round['KillLog']) && round['KillLog'].length > 0) {
                    round.KillLog.forEach((kill) => {
                        try {
                            const killer: number = kill.Killer
                            const killed: number = kill.Killed
                            let combo: any = [killer, killed]
                            combo.sort()

                            if (!(combo in memoizedKillCombos)) {
                                memoizedKillCombos[combo] = [0,0]
                                memoizedNadeCombos[combo] = [0,0]
                            }
                            if (killer > killed) {
                                memoizedKillCombos[combo][1]++
                                if (kill.Weapon == 'HE') memoizedNadeCombos[combo][1]++
                            } else {
                                memoizedKillCombos[combo][0]++
                                if (kill.Weapon == 'HE') memoizedNadeCombos[combo][0]++
                            }
                        } catch(e) {
                            console.error(e)
                        }
                    });
                }
                
            });
        } catch(e) {
            console.log(e)
        }

        memoizedDamageCombos = {}
        memoizedHeadshotCombos = {}
        try {
            rounds.forEach((round) => {
                if ('HitLog' in round && isDef(round['HitLog']) && round['HitLog'].length > 0) {
                    round.HitLog.forEach((hit) => {
                        try {
                            const shooter: number = hit.Shooter
                            const shot: number = hit.Hit
                            let combo: any = [shooter, shot]
                            combo.sort()

                            if (!(combo in memoizedDamageCombos)) {
                                memoizedDamageCombos[combo] = [0,0]
                                memoizedHeadshotCombos[combo] = [0,0]
                            }
                            if (shooter > shot) {
                                memoizedDamageCombos[combo][1] += hit.Damage
                                if (isHitHeadshot(hit)) memoizedHeadshotCombos[combo][1]++
                            } else {
                                memoizedDamageCombos[combo][0] += hit.Damage
                                if (isHitHeadshot(hit)) memoizedHeadshotCombos[combo][0]++
                            }
                        } catch(e) {
                            console.error(e)
                        }
                    });
                }  
            });
        } catch(e) {
            console.log(e)
        }        
    }

    function calculateWeaponStats(rounds) {
        if (isDef(memoizedWeaponStats)) {
            return memoizedWeaponStats
        }

        memoizedWeaponStats = {}

        rounds.forEach((round) => {
            if ('KillLog' in round && isDef(round['KillLog']) && round['KillLog'].length > 0) {
                round.KillLog.forEach(kill => {
                    if (aimOrSkeet && playerNumberToAuth[kill.Killer] == '---') {
                        return;
                    }

                    try {
                        const playerNo: number = kill.Killer
                        const weapon: string = kill.Weapon

                        if (weapon != '(null)') {
                            if (!(weapon in memoizedWeaponStats)) {
                                memoizedWeaponStats[weapon] = {}
                            }

                            if (playerNo in memoizedWeaponStats[weapon]) {
                                memoizedWeaponStats[weapon][playerNo][WeaponMetric.KILLS] += 1
                            } else {
                                memoizedWeaponStats[weapon][playerNo] = {}
                                memoizedWeaponStats[weapon][playerNo][WeaponMetric.KILLS] = 1
                                memoizedWeaponStats[weapon][playerNo][WeaponMetric.HITS] = 0
                                memoizedWeaponStats[weapon][playerNo][WeaponMetric.DAMAGE] = 0
                                memoizedWeaponStats[weapon][playerNo][WeaponMetric.HEADSHOTS] = 0
                            }

                            if ('ALL' in memoizedWeaponStats[weapon]) {
                                memoizedWeaponStats[weapon]['ALL'][WeaponMetric.KILLS] += 1
                            } else {
                                memoizedWeaponStats[weapon]['ALL'] = {}
                                memoizedWeaponStats[weapon]['ALL'][WeaponMetric.KILLS] = 1
                                memoizedWeaponStats[weapon]['ALL'][WeaponMetric.HITS] = 0
                                memoizedWeaponStats[weapon]['ALL'][WeaponMetric.DAMAGE] = 0
                                memoizedWeaponStats[weapon]['ALL'][WeaponMetric.HEADSHOTS] = 0
                            }
                        }
                    } catch(e) {
                        console.error(e)
                    }
                });
            }

            if ('HitLog' in round && isDef(round['HitLog']) && round['HitLog'].length > 0) {
                round.HitLog.forEach((hit) => {
                    try {
                        const playerNo: number = hit.Shooter
                        const weapon: string = hit.Weapon

                        if (weapon != '(null)') {
                            if (!(weapon in memoizedWeaponStats)) {
                                memoizedWeaponStats[weapon] = {}
                            }

                            if (playerNo in memoizedWeaponStats[weapon]) {
                                memoizedWeaponStats[weapon][playerNo][WeaponMetric.HITS] += 1
                                memoizedWeaponStats[weapon][playerNo][WeaponMetric.DAMAGE] += hit.Damage
                                if (['HEAD', 'HELMET'].includes(hit.Location.toUpperCase())) {
                                memoizedWeaponStats[weapon][playerNo][WeaponMetric.HEADSHOTS] += 1
                                }
                            } else {
                                memoizedWeaponStats[weapon][playerNo] = {}
                                memoizedWeaponStats[weapon][playerNo][WeaponMetric.KILLS] = 0
                                memoizedWeaponStats[weapon][playerNo][WeaponMetric.HITS] = 1
                                memoizedWeaponStats[weapon][playerNo][WeaponMetric.DAMAGE] = hit.Damage
                                memoizedWeaponStats[weapon][playerNo][WeaponMetric.HEADSHOTS] = 0
                            }

                            if ('ALL' in memoizedWeaponStats[weapon]) {
                                memoizedWeaponStats[weapon]['ALL'][WeaponMetric.HITS] += 1
                                memoizedWeaponStats[weapon]['ALL'][WeaponMetric.DAMAGE] += hit.Damage
                                if (['HEAD', 'HELMET'].includes(hit.Location.toUpperCase())) {
                                memoizedWeaponStats[weapon]['ALL'][WeaponMetric.HEADSHOTS] += 1
                                }
                            } else {
                                memoizedWeaponStats[weapon]['ALL'] = {}
                                memoizedWeaponStats[weapon]['ALL'][WeaponMetric.KILLS] = 0
                                memoizedWeaponStats[weapon]['ALL'][WeaponMetric.HITS] = 1
                                memoizedWeaponStats[weapon]['ALL'][WeaponMetric.DAMAGE] = hit.Damage
                                memoizedWeaponStats[weapon]['ALL'][WeaponMetric.HEADSHOTS] = 0
                            }
                        }
                    } catch(e) {
                        console.error(e)
                    }
                });
            }
        });
    }

    function getRoundScore(round) {
        let draw = "";
        if (memoizedRoundScore[round-1][2] != null) {
            draw = `:<strong><span>${memoizedRoundScore[round-1][2]}</span></strong>`
        }
        return `(<span style="color: red;"><strong>${memoizedRoundScore[round-1][0]}</strong></span>:<strong><span style="color: dodgerblue;">${memoizedRoundScore[round-1][1]}</span></strong>${draw})`
    }

    function playerNoToPlayer(players, number) {
        for (const player in players) {
            if (players[player]['PlayerNo'] == number) {
                return Promise.resolve(players[player])
            }
        }
        return Promise.resolve(null)
    }

    function playerAuthOrName(player) {
        if (player) {
            if ('Auth' in player && player['Auth'] && player['Auth'] != '---') {
                return player['Auth']
            } else if ('Name' in player && player['Name']) {
                return player['Name']
            }
        }
        return null
    }

    function calculateNadeKills(Rounds, player) {
        if (isDef(memoizedNadeKills)) {
            return (player.PlayerNo in memoizedNadeKills) ? memoizedNadeKills[player.PlayerNo] : 0;
        }

        memoizedNadeKills = {};
        Rounds.forEach(round => {
            if ('KillLog' in round && round['KillLog']) {
                round.KillLog.forEach(kill => {
                    if (kill.Weapon.toUpperCase() == 'HE') {
                        if (kill.Killer in memoizedNadeKills) {
                            memoizedNadeKills[kill.Killer]++;
                        } else {
                            memoizedNadeKills[kill.Killer] = 1;
                        }
                    }
                });
            }
        });
        return calculateNadeKills(Rounds, player);
    }

    function memoizeEntryDamage(Rounds) {
        memoizedEntryHitsTaken = {};
        memoizedEntryHitsGiven = {};
        Rounds.forEach(round => {
            if ('HitLog' in round && round.HitLog.length > 0) {
                // Shots
                if (round.HitLog[0].Shooter in memoizedEntryHitsGiven) {
                    memoizedEntryHitsGiven[round.HitLog[0].Shooter]++;
                } else {
                    memoizedEntryHitsGiven[round.HitLog[0].Shooter] = 1;
                }
                // Hits
                if (round.HitLog[0].Hit in memoizedEntryHitsTaken) {
                    memoizedEntryHitsTaken[round.HitLog[0].Hit]++;
                } else {
                    memoizedEntryHitsTaken[round.HitLog[0].Hit] = 1;
                }
            }
        });
    }

    function calculateEntryHitsTaken(Rounds, player) {
        if (isDef(memoizedEntryHitsTaken)) {
            return (player.PlayerNo in memoizedEntryHitsTaken) ? memoizedEntryHitsTaken[player.PlayerNo] : 0;
        }
        memoizeEntryDamage(Rounds);
        return calculateEntryHitsTaken(Rounds, player);
    }

    function calculateEntryHitsGiven(Rounds, player) {
        if (isDef(memoizedEntryHitsGiven)) {
            return (player.PlayerNo in memoizedEntryHitsGiven) ? memoizedEntryHitsGiven[player.PlayerNo] : 0;
        }
        memoizeEntryDamage(Rounds);
        return calculateEntryHitsGiven(Rounds, player);
    }

    function isHitHeadshot(hit) {
        return ['HEAD', 'HELMET'].includes(hit['Location']);
    }

    function calculateHeadShots(Rounds, player) {
        if (isDef(memoizedHeadShots)) {
            return (player.PlayerNo in memoizedHeadShots) ? memoizedHeadShots[player.PlayerNo] : 0;
        }

        memoizedHeadShots = {};
        Rounds.forEach(round => {
            if ('HitLog' in round && round['HitLog']) {
                round.HitLog.forEach(hit => {
                    if (isHitHeadshot(hit)) {
                        if (hit.Shooter in memoizedHeadShots) {
                            memoizedHeadShots[hit.Shooter]++;
                        } else {
                            memoizedHeadShots[hit.Shooter] = 1;
                        }
                    }
                });
            }
        });
        return calculateHeadShots(Rounds, player);
    }

    function calculateHits(Rounds, player) {
        if (isDef(memoizedHits)) {
            return (player.PlayerNo in memoizedHits) ? memoizedHits[player.PlayerNo] : 0;
        }

        memoizedHits = {};
        Rounds.forEach(round => {
            if ('HitLog' in round && round['HitLog']) {
                round.HitLog.forEach(hit => {
                    if (hit.Shooter in memoizedHits) {
                        memoizedHits[hit.Shooter]++;
                    } else {
                        memoizedHits[hit.Shooter] = 1;
                    }
                });
            }
        });
        return calculateHits(Rounds, player);
    }

    function firstRedIndex(players) {
        if (memoizeFirstRedIndex) {
            return memoizeFirstRedIndex
        }
        let i = 0;
        for (i = 0; i < players.length; i++) {
            if (players[i].Team == 'red') {
                memoizeFirstRedIndex = i
                return i
            }
        }
        return null;
    }

    function calculateWeaponRows(data, weapon) {
        const hideColumns = [];
        Object.values(memoizedWeaponStats[weapon]['ALL']).forEach((stat, index) => {
            if (stat == 0) {
                hideColumns.push(index);
            }
        });

        let rows = [];
        for (const playerNo in memoizedWeaponStats[weapon]) {
            if (playerNo != 'ALL') {
                const playerFilter = data['stats']['Players'].filter(p => p.PlayerNo == playerNo);
                if (!playerFilter && playerFilter.length != 1) {
                    continue;
                }

                const player = playerFilter[0];

                let playerName;
                if (demoMap && playerAuthOrName(player).toLowerCase() in demoMap && isDef(demoMap[playerAuthOrName(player).toLowerCase()].username) && isDef(avatarMap[playerAuthOrName(player).toLowerCase()])) {
                    const urtAuth = playerAuthOrName(player).toLowerCase();
                    playerName = {
                        "col": `<span class="${player['Team'][0].toUpperCase()}">&nbsp;</span>`,
                        "type": "UserLink",
                        "username": demoMap[urtAuth].username,
                        "avatar": avatarMap[urtAuth].avatar,
                        "online": avatarMap[urtAuth].online,
                        "userId": demoMap[urtAuth].user_id
                    }
                } else {
                    playerName = {
                        "col": `<span class="${player['Team'][0].toUpperCase()}">&nbsp;</span>`,
                        "type": "UserLink",
                        "anon": player['Auth']
                    }
                }
                
                const concatData = []
                Object.values(memoizedWeaponStats[weapon][playerNo]).forEach((stat, index) => {
                    if (!hideColumns.includes(index)) {
                        concatData.push(stat)
                    }
                });
                
                rows.push([playerName].concat(concatData))
            }
        }
        return rows
    }

    function calculateStatsTable(data) {
        const rows = []
        data['stats']['Players'].forEach(player => {
            if (player['Kills'] > 0 && player['Deaths'] > 0) {
                const row = []
                if (player['Auth'] == undefined || player['Auth'] == '---') {
                    row.push(`<span class="${player['Team'][0].toUpperCase()}">&nbsp;</span> ${player['Name']}`)
                } else {
                    if (demoMap && playerAuthOrName(player).toLowerCase() in demoMap && isDef(demoMap[playerAuthOrName(player).toLowerCase()].username && isDef(avatarMap[playerAuthOrName(player).toLowerCase()]))) {
                        const urtAuth = playerAuthOrName(player).toLowerCase();
                        const avatar = {
                            "col": `<span class="${player['Team'][0].toUpperCase()}">&nbsp;</span>`,
                            "type": "UserLink"
                        }
                        if (isDef(demoMap[urtAuth])) {
                            avatar['username'] = demoMap[urtAuth].username
                            avatar['userId'] = demoMap[urtAuth].user_id
                        }
                        if (isDef(avatarMap[urtAuth])) {
                            avatar['avatar'] = avatarMap[urtAuth].avatar
                            avatar['online'] = avatarMap[urtAuth].online
                        }
                        row.push(avatar)
                    } else {
                        row.push({
                            "col": `<span class="${player['Team'][0].toUpperCase()}">&nbsp;</span>`,
                            "type": "UserLink",
                            "anon": player['Auth']
                        })
                    }
                }

                // demo button
                if (demoMap && playerAuthOrName(player).toLowerCase() in demoMap) {
                    row.push({
                        "demo": demoMap[playerAuthOrName(player).toLowerCase()],
                        "type": "DemoButton",
                        "gameId": gameId,
                        "gameName": gameName
                    })
                } else {
                    row.push('')
                }

                // Anticheat
                let ac_row = {col: ''}
                if (demoMap && playerAuthOrName(player).toLowerCase() in demoMap) {
                    if (matchRound.ac_usage.map(u => u['user_id']).includes(demoMap[playerAuthOrName(player).toLowerCase()].user_id)) {
                        ac_row = {
                            tooltip: 'Anticheat Used',
                            col: `<img src="${$RELATIVE_PATH}/icons/ac_shield.svg" alt="Anticheat Used" width="16" height="16"/>`
                        }
                    }
                }
                row.push(ac_row)

                row.push(player['Kills'])
                row.push(player['Deaths'])
                row.push(player['Assists'])
                row.push((player['Kills'] / player['Deaths']))
                row.push(((player['Kills'] + player['Assists']) / player['Deaths']))
                row.push(player['Damage'])
                row.push(player['DamageTaken'])
                row.push(player['Damage'] / player['DamageTaken'])
                // row.push(parseInt(player['Damage'] / player['Deaths']))
                row.push(player['TeamDamage'])
                row.push(player['TeamDamageTaken'])
                row.push(player['TeamKills'])
                row.push(player['TeamKilled'])
                row.push(player['Suicides'])

                var hits = 0;
                if (data['stats']['GameType'] == 4) {
                    row.push(player.Rounds.Kills.filter(k => k == 2).length)
                    row.push(player.Rounds.Kills.filter(k => k == 3).length)
                    row.push(player.Rounds.Kills.filter(k => k == 4).length)
                    row.push(player.Rounds.Kills.filter(k => k == 5).length)
                    row.push(player.Rounds.Kills.filter(k => k == 2 || k == 3 || k == 4 || k == 5).length)
                    const clutch = clutchVsN(player.Rounds);
                    row.push(clutch[1])
                    row.push(clutch[2])
                    row.push(clutch[3])
                    row.push(clutch[4])
                    row.push(clutch[5])
                    row.push(lastAlive(player.Rounds))
                    row.push(Math.floor((clutch[1] + clutch[2] + clutch[3] + clutch[4] + clutch[5]) / lastAlive(player.Rounds) * 100))
                    row.push(player['EntryFrags'])
                    row.push(player['EntryFragged'])
                    row.push(player['EntryFrags'] - player['EntryFragged'])
                    row.push(player['SecondsAlive'])
                    row.push(calculateNadeKills(data['stats'].Rounds, player))
                    const headshots = calculateHeadShots(data['stats'].Rounds, player)
                    row.push(headshots)
                    hits = calculateHits(data['stats'].Rounds, player)
                    row.push(Math.floor(headshots / hits * 100))
                } else if (data['stats']['GameType'] == 7) {
                    const headshots = calculateHeadShots([data['stats']], player)
                    hits = calculateHits([data['stats']], player)
                    row.push(
                        player.CTF.FlagsTaken,
                        player.CTF.FlagsDropped,
                        player.CTF.FlagsCaptured,
                        player.CTF.FlagsReturned,
                        player.CTF.FlagCarriersKilled,
                        calculateNadeKills([data['stats']], player),
                        headshots,
                        Math.floor(headshots / hits * 100)
                    )
                }

                try {
                    const ps = data.player_stats[player.PlayerNo]
                    if (isDef(ps) && isDef(ps.shots_fired)) {
                        const shotsFired = ps.shots_fired.map(r => r).map(w => Object.values(w).reduce((partialSum, a) => partialSum + a, 0)).reduce((partialSum, a) => partialSum + a, 0)
                        row.push(hits)
                        row.push(shotsFired)
                        row.push(parseInt(hits / shotsFired * 100))
                    } else {
                        row.push(hits)
                        row.push(0)
                        row.push(0)
                    }
                } catch (e) {
                    console.log(e)
                }
                
                rows.push(row)
            }
        })
        return rows
    }

    function calculateStatsTableHeadings(data) {
        const stats = ['Player',
            {col:'R', tooltip: 'Recording'},
            {col:'AC', tooltip: 'Anticheat'},
            {col:'K', tooltip: 'Kills'},
            {col:'D', tooltip: 'Deaths'},
            {col:'A', tooltip: 'Assists'},
            {col:'KD', tooltip: 'Kills Death Ratio'},
            {col:'KDA', tooltip: 'Kill Assist Ratio'},
            {col:'DG', tooltip: 'Damage Given'},
            {col:'DT', tooltip: 'Damage Taken'},
            {col:'DR', tooltip: 'Damage Ratio'},
            {col:'FDG', tooltip: 'Friendly Damage Given'},
            {col:'FDT', tooltip: 'Friendly Damage Taken'},
            {col:'FK', tooltip: 'Friendly Kills'},
            {col:'FD', tooltip: 'Friendly Deaths'},
            {col:'S', tooltip: 'Suicides'},
        ]

        if (data['stats']['GameType'] == 4) {
            stats.push(
                '2K', '3K', '4K', '5K',
                {col:'MK', tooltip: 'Multikills'},
                '1v1', '1v2', '1v3', '1v4', '1v5',
                {col:'LA', tooltip: 'Last Alive'},
                {col:'CP', tooltip: 'Clutch Percent'},
                {col:'EK', tooltip: 'Entry Kills'},
                {col:'ED', tooltip: 'Entry Deaths'},
                {col:'EKD', tooltip: 'Entry Kills - Deaths'},
                {col:'SA', tooltip: 'Seconds Alive'},
            )
        } else if (data['stats']['GameType'] == 7) {
            stats.push(
                {col: 'FT', tooltip: 'Flags Taken'},
                {col: 'FD', tooltip: 'Flags Dropped'},
                {col: 'C', tooltip: 'Flags Captured'},
                {col: 'R', tooltip: 'Flags Returns'},
                {col: 'FCK', tooltip: 'Flag Carriers Killed'},
            )
        }

        stats.push(
            {col:'NK', tooltip: 'Nade Kills'},
            {col:'HS', tooltip: 'Headshots'},
            {col:'HS%', tooltip: 'Headshot Percent'},
            {col:'H', tooltip: 'Shots Hit'},
            {col:'F', tooltip: 'Shots Fired'},
            {col:'S%', tooltip: 'Shooting Percent'},
        )

        return stats
    }

    function calculateStatsTableCategories(data) {
        const categories = {
            'Basic': 8,
            'Damage': 3,
            'Friendly Fire': 5
        }

        if (data['stats']['GameType'] == 4) {
            categories['Multikills'] = 5
            categories['Clutches'] = 7
            categories['Entry Kills'] = 3
            categories['Extra'] = 2
            categories['Shooting'] = 5
        } else if (data['stats']['GameType'] == 7) {
            categories['CTF'] = 5
            categories['Shooting'] = 6
        }

        return categories
    }

    function duelVal(value, type, player) {
        if (type == 'number') {
            return value;
        } else {
            if (duelMetric == DuelMetric.DAMAGE) {
                return `${((value / player['Damage']) * 100).toFixed(0)}%`;
            } else if (duelMetric == DuelMetric.KILLS) {
                return `${((value / player['Kills']) * 100).toFixed(0)}%`;
            } else if (duelMetric == DuelMetric.HEADSHOTS) {
                return `${((value / memoizedHeadShots[player['PlayerNo']]) * 100).toFixed(0)}%`;
            } else if (duelMetric == DuelMetric.NADEKILLS) {
                return `${((value / memoizedNadeKills[player['PlayerNo']]) * 100).toFixed(0)}%`;
            }
        }
    }
</script>

<style>
    .table-scores {
        /* table-layout: fixed; */
        /* width: 180px; */
        text-overflow: ellipsis;
        display: inline-block;
        vertical-align: top;
    }
    .round-bar {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .round-bar .D {
        background-color: grey;
    }
    .round-bar {
        list-style: none;
    }
    .round-bar span {
        padding: 1em 0;
        margin: 0;
        display: inline;
        cursor: pointer;
        display: flex;
        width: 100%;
    }
    .round-bar li {
        display: flex;
        /* flex: 0 1 auto; */
        list-style-type: none;
        width: 100%;
        padding:  0.15em 0.1em;
        margin: 0.1em;
        border-radius: 8px;
    }
    .round-log {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    .headcol {
        position: sticky;
        left: 0;
        display: block;
    }


    .demo-button {
        /* text-align: right; */
        cursor: pointer;
        float: right;
        /* display: inline-block; */
        /* width: 12px; */
    }

    .round-preview {
        width: 98%;
        /* background-color: var(--table-alternate-background); */
        padding: 0.45em;
        margin: 0.25em;
        border-radius: 5px;
    }

    .kill-breakdown {
        border-radius: 100%;
        /* background-color: green; */
        width: 2.5em;
        height: 2.5em;
        display: flex;
        align-items: center;
        vertical-align: middle;
        justify-content: center;
        font-weight: 400 !important;
        text-align: center;
        position: absolute;
        margin: auto;
    }

    label {
        display: inline-block;
        padding: 0.25em 0.5em 0.25em 0.5em;
        border: 0;
    }

    label > input {
        margin-bottom: 0;
    }

    summary {
        padding-left: 0.25em;
        margin-left: 0.25em;
        border-radius: 5px 0 0 5px;
        background-color: var(--background-mid);
    }

    summary:hover {
        background-color: var(--background-dark);
    }

    details[open] > summary {
        background-color: var(--background-dark);
        margin-bottom: 0.25em;
    }

    details[open] > summary:hover {
        background-color: var(--background-mid);
    }

    details {
        margin-top: 0.5em;
        margin-bottom: 0.5em;
    }

    summary h3 {
        margin-top: 0.65em;
        margin-bottom: 0.65em;
        display: inline-block;
    }

    .right {
        margin-left: auto;
    }
</style>

{#if matchRound.id !== undefined}
    {#await getMatchRoundStats(matchRound.id)}
        <Loading/>
        <div>
            <span class="skeleton" style="height: 1em; width: 40px; display: inline-block;"></span>
            <span class="skeleton" style="height: 1em; width: 40px; display: inline-block;"></span>
            <span class="skeleton" style="height: 1em; width: 40px; display: inline-block;"></span>
        </div>

        <div class="skeleton" style="height: 4em;"></div>
        <div class="skeleton" style="height: 400px;"></div>
    
        <div class="skeleton" style="height: 4em;"></div>
        <div class="skeleton" style="height: 4em;"></div>
        <div class="skeleton" style="height: 4em;"></div>
        <div class="skeleton" style="height: 4em;"></div>
    {:then data}
        <div style="margin-left: 0.25em; margin-bottom: 0.25em; display: flex; vertical-align: middle; align-items: center;">
            <span class="left">
                {#if multiviewDemo}
                    <span class="demo-button" style="float: left;"><DemoButton tag={true} fileHash={multiviewDemo['file_hash']} {gameId} {gameName}/></span>&nbsp;
                {/if}
                <span class="tag">🗺️{data['stats']['Map']}</span>

                {#if !aimOrSkeet}
                    <span class="tag">
                        <span style="color:red;">Red</span>: {data['stats']['ScoreRed']}
                    </span>
                    <span class="tag">
                        <span style="color:dodgerblue;">Blue</span>: {data['stats']['ScoreBlue']}
                    </span>
                {/if}
            </span>

            <span class:right={!($isMobile)}>
                {#if 'server' in matchRound && matchRound.server !== undefined}
                    <img height="28" style="display: inline-block; vertical-align: middle; margin: 0 0 0.2em; 0"
                        src="{$RELATIVE_PATH}/countries/{matchRound.server.country}.svg" 
                        alt={`${matchRound.server.city}, ${matchRound.server.country} Server`} 
                        use:tooltip={{text: `${matchRound.server.city}, ${matchRound.server.country} Server`, pos: TooltipPosition.Bottom}}
                    />
                    &nbsp;
                {/if}
                <span class="tag">
                    <TimeSince dateString={matchRound.submitted}/>
                </span>
            </span>
        </div>

        {#if !aimOrSkeet}
            <details open>
                <summary style="margin-left: 0.25em">
                    <h3>Table</h3>
                </summary>
                <div class="table-wrapper overflow-scroll">
                    <SortableTable
                        categories={calculateStatsTableCategories(data)}
                        headings={calculateStatsTableHeadings(data)}
                        rows={calculateStatsTable(data)}/>

                    <!-- Display AC usage for players who did not play in the match (subs/specs) -->
                    <div style="margin-top: 0.5em; margin-bottom: 0.5em;">
                        {#each data['stats']['Players'] as player}
                            {#if player['Kills'] == 0 && player['Deaths'] == 0}
                                {#if playerAuthOrName(player).toLowerCase() in demoMap}
                                    {@const username = avatarMap[playerAuthOrName(player).toLowerCase()].username}
                                    {@const avatar = avatarMap[playerAuthOrName(player).toLowerCase()]}
                                    <div class="tag" style="margin-left: 0.75em;">
                                        <!-- Username display -->
                                        <span class="{player['Team'][0].toUpperCase()}">&nbsp;</span>
                                        {#if isDef(username) && isDef(avatar)}
                                            <UserLink username={username} avatar={avatar.avatar} online={avatar.online} userId={avatar.user_id}/>
                                        {:else}
                                            <UserLink anon={playerAuthOrName(player)}/>
                                        {/if}

                                        <!-- AC Icon -->
                                        {#if isDef(avatar) && avatar.user_id in acUsageMap}
                                            <img src="{$RELATIVE_PATH}/icons/ac_shield.svg" alt="Anticheat Used" width="16" height="16"/>
                                        {/if}
                                    </div>
                                {/if}
                            {/if}
                        {/each}
                    </div>
                </div>
            </details>
        {/if}

        {#if data.stats.GameType == 7 && isDef(data.stats.CtfEvents)}
            {@const caps = data.stats.CtfEvents.filter(e => [0,1].includes(e.Event)).map(e => e.Event)}
            <details>
                <summary>
                    <h3>Captures</h3>
                    <ul class="round-bar">
                        {#each caps as cap, round}
                            <span use:tooltip={{text:`Cap ${round+1}<br/>${getRoundScore(round+1)}`, pos: TooltipPosition.Top}}>
                                <li class="{cap == 0 ? 'R' : 'B'}"></li>
                            </span>
                        {/each}
                    </ul>
                </summary>
                <!-- <p>CAPTS</p> -->
            </details>
        {/if}

        {#if isDef(data.stats.Rounds) || aimOrSkeet}
            <details open={aimOrSkeet}>
                <summary style="margin-left: 0.25em">
                    <h3>Charts</h3>
                </summary>
                <div>
                    &nbsp;
                    <select bind:value={chartMetric} class="tag" style="color: white">
                        {#each Object.values(ChartMetric) as metric}
                            {#if !aimOrSkeet || metric != "Damage Taken"}
                                <option value={metric}>
                                    {metric}
                                </option>
                            {/if}
                        {/each}
                    </select>

                    <label class="tag">
                        Per Round
                        <input type="checkbox" bind:checked={chartPerRound}/>
                    </label>

                    {#if !chartPerRound}
                        <label class="tag">
                            Smooth
                            <input type="checkbox" bind:checked={chartSmooth}/>
                        </label>
                    {/if}
                </div>

                <div style="margin: 5px;">
                    <Chart options={getOptions(data, chartPerRound, chartMetric, chartPlayerFilter, chartSmooth)} height={600}/>
                </div>

                <!-- <div style="text-align: center;">
                    {#each data['stats']['Players'] as player}
                        {#if 'Round' in player}
                            <label class="tag">
                                {playerAuthOrName(player)}
                                <input type="checkbox" value={player['PlayerNo']} checked={true} bind:group={chartPlayerFilter}/>
                            </label>
                        {/if}
                    {/each}
                </div> -->
            </details>
        {/if}

        {#if isDef(data.stats.Rounds)}
            <FeatureFlag flag="stat-teams">
                <details>
                    <summary style="margin-left: 0.25em">
                        <h3>Teams <span class="new">New</span></h3>
                    </summary>
                    <div class="grid center" style="margin-left: auto; margin-right:auto; float: center;">
                        <ul>
                            <li>Round win players alive</li>
                            <li>flawless rounds for</li>
                            <li>flawless rounds against</li>
                            <li>win streak</li>
                            <li>loss streak</li>
                            <li>nade kill %</li>
                            <li>lead taken</li>
                            <li>lead lost</li>
                            <li>largest rounds lead</li>
                            <li>largest rounds trailing</li>
                        </ul>
                    </div>
                </details>
            </FeatureFlag>

            <FeatureFlag flag="stat-players">
                <details>
                    <summary style="margin-left: 0.25em">
                        <h3>Players <span class="new">New</span></h3>
                    </summary>
                    <div class="grid center" style="margin-left: auto; margin-right:auto; float: center;">
                        <select class="tag" style="color: white">
                            {#each data['stats']['Players'] as player}
                                <option value="{playerAuthOrName(player)}">
                                    {playerAuthOrName(player)}
                                </option>
                            {/each}
                        </select>

                        <ul>
                            <li>Longest Kill Streak</li>
                            <li>Longest Death Streak</li>
                            <li>Rounds without a kill</li>
                            <li>Rounds without damage</li>
                            <li>Round winning kill</li>
                            <li>weapon breakdown</li>
                        </ul>
                    </div>
                </details>
            </FeatureFlag>

            <details>
                <summary style="margin-left: 0.25em">
                    <h3 style="margin-bottom: 0.25em;">Round Breakdown</h3>
                    <ul class="round-bar">
                        {#each data['stats']['Rounds'] as round, i}
                            {#if isDef(round['KillLog']) && round['KillLog'].length > 0}
                                <span use:tooltip={{text:`Round ${i+1}<br/>${getRoundScore(i+1)}`, pos: TooltipPosition.Top}}>
                                    <li class="{round['Winner']}"></li>
                                </span>
                            {/if}
                        {/each}
                    </ul>
                </summary>
                <div>
                    <ul class="round-log">
                        {#each data['stats']['Rounds'] as round, i}
                            {#if isDef(round['KillLog']) && round['KillLog'].length > 0}
                                <li class="round-preview">
                                    <details>
                                        <summary>
                                            <span class="tag">
                                                <span class="{round['Winner']}" style="padding: 0 0.1em; border-radius: 3px;" use:tooltip={`${round['Winner'] == 'B' ? "Blue" : "Red"} Team Wins`}>&nbsp</span>
                                                R<strong>{round['Round']}</strong>
                                                <span>{@html getRoundScore(i+1)}</span>
                                            </span>
                                            {#each data['stats']['Players'] as player}
                                                {#await clutchedRound(player, i)}
                                                    &nbsp;
                                                {:then clutched}
                                                    {#if clutched}
                                                        <span class="tag"><span class="{player['Team'][0].toUpperCase()}">&nbsp;</span> {playerAuthOrName(player)} <strong>1v{clutched}</strong></span>
                                                    {/if}
                                                {/await}
                                            {/each}
                                            <span style="float:right; margin-right: 2em;">
                                                {#each data['stats']['Players'] as player}
                                                    {#if player != null && 'Team' in player && player.Rounds['Kills'][i] > 1}
                                                        <span class="tag">
                                                            <span class="{player['Team'][0].toUpperCase()}">&nbsp;</span> 
                                                            {playerAuthOrName(player)} 
                                                            <strong>{player.Rounds['Kills'][i]}K</strong>
                                                        </span>
                                                    {/if}
                                                {/each}
                                            </span>
                                        </summary>
                                        {#if 'KillLog' in round && round['KillLog']}
                                            <br/>
                                            {#each round['KillLog'] as kill}
                                                <div style="vertical-align: middle; justify-content: left; align-items: center; display: flex; padding: 0.5em;">
                                                    <span class="tag">🕑{parseInt(kill.Seconds / 60)}:{zeroPad(kill.Seconds % 60, 2)}</span>

                                                    {#await playerNoToPlayer(data['stats']['Players'], kill['Killer'])}
                                                        &nbsp;
                                                    {:then player}
                                                        {#if isDef(player)}
                                                            <span class="tag"><span class={player.Team[0].toUpperCase()}>&nbsp;</span>
                                                                {#if playerAuthOrName(player).toLowerCase() in avatarMap && isDef(avatarMap[playerAuthOrName(player).toLowerCase()].username)}
                                                                    {@const avatar = avatarMap[playerAuthOrName(player).toLowerCase()]}
                                                                    <UserLink username={avatar.username} avatar={avatar.avatar} online={avatar.online} userId={avatar.user_id}/>
                                                                {:else}
                                                                    <UserLink anon={playerAuthOrName(player)}/>
                                                                {/if}
                                                            </span>
                                                        {/if}
                                                    {/await}

                                                    <img src="{$RELATIVE_PATH}/weapon_sprites/urbanterror/{kill['Weapon']}.png" height="32" alt={kill['Weapon']} use:tooltip={kill['Weapon']}/>

                                                    {#await playerNoToPlayer(data['stats']['Players'], kill['Killed'])}
                                                        &nbsp;
                                                    {:then player}
                                                        {#if isDef(player)}
                                                            <span class="tag"><span class={player.Team[0].toUpperCase()}>&nbsp;</span>
                                                                {#if playerAuthOrName(player).toLowerCase() in avatarMap && isDef(avatarMap[playerAuthOrName(player).toLowerCase()].username)}
                                                                    {@const avatar = avatarMap[playerAuthOrName(player).toLowerCase()]}
                                                                    <UserLink username={avatar.username} avatar={avatar.avatar} online={avatar.online} userId={avatar.user_id}/>
                                                                {:else}
                                                                    <UserLink anon={playerAuthOrName(player)}/>
                                                                {/if}
                                                            </span>
                                                        {/if}
                                                    {/await}
                                                </div>
                                            {/each}
                                        {/if}
                                        <!-- {#if 'HitLog' in round}
                                            {#each round.HitLog as hit}
                                                {hit}
                                            {/each}
                                        {/if} -->
                                    </details>
                                </li>
                            {/if}
                        {/each}
                    </ul>
                </div>
            </details>
        {/if}

        {#if !aimOrSkeet && Object.keys(memoizedKillCombos).length && Object.keys(memoizedDamageCombos).length}
            <details>
                <summary style="margin-left: 0.25em">
                    <h3>Duels</h3>
                </summary>
                <div class="table-wrapper overflow-scroll">
                    &nbsp;
                    <span class="button-group">
                        {#each Object.values(DuelMetric) as metric}                    
                            <input id={`duel-metric-${metric}`} type="radio" bind:group={duelMetric} value={metric} hidden/><label for={`duel-metric-${metric}`}>{titlecase(metric)}</label>
                        {/each}
                    </span>

                    <span class="button-group">
                            <input id={`duel-metric-type-number`} type="radio" bind:group={duelMetricType} value="number" hidden/><label for={`duel-metric-type-number`}>#</label><input id={`duel-metric-type-percent`} type="radio" bind:group={duelMetricType} value="percent" hidden/><label for={`duel-metric-type-percent`}>%</label>
                    </span>
                    <table style="border-collapse: separate; table-layout: fixed; width: 100%; min-width: {(firstRedIndex(data['stats']['Players']) + 2) * 100}px;">
                        <tbody>
                            {#each data['stats']['Players'] as player, x}
                                {#if player['Kills'] > 0 && player['Deaths'] > 0}
                                    {#if x == 0}
                                        <tr style="text-align: center;">
                                            {#each data['stats']['Players'] as player, i}
                                                {#if i == 0}
                                                    <th>&nbsp;</th>
                                                {/if}
                                                {#if player['Team'] == 'red' && (player['Kills'] > 0 || player['Deaths'] > 0)}
                                                    <th class="nowrap">
                                                        <span class="{player['Team'][0].toUpperCase()}">&nbsp;</span>
                                                        {#if playerAuthOrName(player).toLowerCase() in avatarMap && isDef(avatarMap[playerAuthOrName(player).toLowerCase()].username)}
                                                            {@const avatar = avatarMap[playerAuthOrName(player).toLowerCase()]}
                                                            <UserLink username={avatar.username} avatar={avatar.avatar} online={avatar.online} userId={avatar.user_id}/>                    
                                                        {:else}
                                                            <UserLink anon={playerAuthOrName(player)}/>
                                                        {/if}
                                                    </th>
                                                {/if}
                                            {/each}
                                        </tr>
                                    {/if}
                                    {#if player['Team'] == 'blue'}
                                        <tr>
                                            {#each data['stats']['Players'] as player2, y}
                                                {#if player2['Team'] == 'red' && player2['Kills'] > 0}
                                                    {@const combo = getPlayerCombo(duelMetric, player.PlayerNo, player2.PlayerNo)}
                                                    {#if combo != null}
                                                        {#if y == firstRedIndex(data['stats']['Players'])}
                                                            <td class="nowrap" style="text-align: left;">
                                                                <span class="{player['Team'][0].toUpperCase()}">&nbsp;</span>
                                                                <strong>
                                                                    {#if playerAuthOrName(player).toLowerCase() in avatarMap && isDef(avatarMap[playerAuthOrName(player).toLowerCase()].username)}
                                                                        {@const avatar = avatarMap[playerAuthOrName(player).toLowerCase()]}
                                                                        <UserLink username={avatar.username} avatar={avatar.avatar} online={avatar.online} userId={avatar.user_id}/>                                
                                                                    {:else}
                                                                        <UserLink anon={playerAuthOrName(player).toLowerCase()}/>
                                                                    {/if}
                                                                </strong>
                                                            </td>
                                                        {/if}
                                                        <td style="min-width: 120px;">
                                                            <div style="display: block; position: relative; min-height: calc(1.5em + 12px); min-width: 120px;">
                                                                <span style="left: calc(49%); bottom: 7px;" class="monospace kill-breakdown {player2.Team[0].toUpperCase()}" use:tooltip={playerAuthOrName(player2).toLowerCase()}>
                                                                    {duelVal(combo[1], duelMetricType, player2)}
                                                                </span>
                                                                <span style="right: calc(50% - 0.25em); top: 7px;" class="monospace kill-breakdown {player.Team[0].toUpperCase()}" use:tooltip={playerAuthOrName(player).toLowerCase()}>
                                                                    {duelVal(combo[0], duelMetricType, player)}
                                                                </span> 
                                                            </div>
                                                        </td>
                                                    {:else}
                                                        <td style="min-width: 120px;">
                                                            <div style="display: block; position: relative; min-height: calc(1.5em + 12px); min-width: 120px;">
                                                                <span style="left: calc(49%); bottom: 7px;" class="monospace kill-breakdown {player2.Team[0].toUpperCase()}" use:tooltip={playerAuthOrName(player2).toLowerCase()}>
                                                                    0
                                                                </span>
                                                                <span style="right: calc(50% - 0.25em); top: 7px;" class="monospace kill-breakdown {player.Team[0].toUpperCase()}" use:tooltip={playerAuthOrName(player).toLowerCase()}>
                                                                    0
                                                                </span>
                                                            </div>
                                                        </td>
                                                    {/if}
                                                {/if}
                                            {/each}
                                        </tr>
                                    {/if}
                                {/if}
                            {/each}
                        </tbody>
                    </table>
                </div>
            </details>
        {/if}

        {#if memoizedWeaponStats}
        <details open={aimOrSkeet}>
                <summary style="margin-left: 0.25em">
                    <h3>Weapons</h3>
                </summary>
                <div class="grid center" style="margin-left: auto; margin-right:auto; float: center;">
                    {#each Object.keys(memoizedWeaponStats).sort((a, b) => Object.keys(memoizedWeaponStats[b]).length - Object.keys(memoizedWeaponStats[a]).length) as weapon}
                        <div class="col-6 card inline-card center" style=" margin: 5px; padding: 0.5em; text-align: left;">
                            <h4 style="margin-top: 0; display: flex; align-items: center; vertical-align: middle; width: 100%;">
                                <img src="{$RELATIVE_PATH}/weapon_sprites/urbanterror/{weapon}.png" height="48" alt={weapon} use:tooltip={weapon} class="left"/>
                                <!-- <div class="right" style="text-align:right; width: 100%;">
                                    ({#each Object.entries(memoizedWeaponStats[weapon]['ALL']).filter(([_, v]) => v > 0) as [metric, _], i}
                                        {memoizedWeaponStats[weapon]['ALL'][metric]} {metric}
                                        {#if i != Object.entries(memoizedWeaponStats[weapon]['ALL']).filter(([_, v]) => v > 0).length - 1} / {/if}
                                    {/each})
                                </div> -->
                            </h4>

                            <SortableTable
                                headings={['Player'].concat(Object.entries(memoizedWeaponStats[weapon]['ALL']).filter(([_, v]) => v > 0).map(([metric, _]) => metric))}
                                footings={['Total'].concat(Object.entries(memoizedWeaponStats[weapon]['ALL']).filter(([_, v]) => v > 0).map(([_, value]) => value))}
                                rows={calculateWeaponRows(data, weapon)} />
                        </div>
                    {/each}
                </div>
            </details>
        {/if}
    {/await}
{/if}