<script>
    import { accessTokenBody, IS_ELECTRON, validAccessToken, userData, isMobile } from '../stores.js';
    import MatchScroller from '../components/MatchScroller.svelte'
    import { onMount } from 'svelte';
    import { Link, navigate } from 'svelte-routing';
    import Avatar from '../components/Avatar.svelte';
    import PlayedGames from '../components/PlayedGames.svelte';
    import Page from '../components/Page.svelte';

  onMount(() => {
    if (!$validAccessToken && !$IS_ELECTRON) {
      navigate('/recent/urban-terror', { replace: true });
    }
  });
</script>

<style>
  h1:first-of-type {
    display: flex;
    /* justify-content: center; */
    vertical-align: center;
    align-items: center;
  }
  h1, h2 {
    margin-top: 1em;
  }
</style>

<Page title="Home">
  {#if $accessTokenBody && 'uid' in $accessTokenBody}
    {#if $userData && $userData.username}
      {#if $isMobile}
        <h2>
          <span>👋 Welcome back,&nbsp;</span>
            {#if $userData.avatar}
              <Avatar avatar={$userData.avatar} userId={$userData.id} username={$userData.username} noPresence/>&nbsp;
            {/if}
            <span>{$userData.username}!</span>
        </h2>
      {:else}
        <h1>
          <span>👋 Welcome back,&nbsp;</span>
            {#if $userData.avatar}
              <Avatar avatar={$userData.avatar} userId={$userData.id} username={$userData.username} noPresence/>&nbsp;
            {/if}
            <span>{$userData.username}!</span>
        </h1>
      {/if}
      <div style="margin-left: 0.5em;"><Link to="/user/{encodeURIComponent($userData.username)}">Go to Profile</Link></div>
    {/if}
    <PlayedGames user_id={$accessTokenBody['uid']}/>

    <h1>My Matches</h1>
    <h2>Upcoming Matches</h2>
    <MatchScroller userId={$accessTokenBody['uid']} matchType="upcoming"/>
    <h2>Recent Matches</h2>
    <MatchScroller userId={$accessTokenBody['uid']} matchType="recent"/>
  {/if}
</Page>
