<script lang="ts">
    import Page404 from './Page404.svelte';
    import {gameTypes, leagueUsers} from '../stores.js'

    import Page from '../components/Page.svelte'
    import Loading from '../components/Loading.svelte'
    import OnlineIndicator from '../components/OnlineIndicator.svelte'
    import {OnlineStatus} from '../models/Enums.ts'
    import LikeButton from '../components/LikeButton.svelte'
    import GameLog from '../components/stats/GameLog.svelte'
    import UserSeasons from '../components/stats/UserSeasons.svelte';
    import DevelopersOnly from '../components/DevelopersOnly.svelte';

    import { Link } from 'svelte-routing';

    import { slugify, isDef } from '../utils.js';
    import MatchScroller from '../components/MatchScroller.svelte';
    import PlayedGames from '../components/PlayedGames.svelte';

    import FeatureFlag from '../components/FeatureFlag.svelte';
    import UserGauges from '../components/stats/UserGauges.svelte';
    export let username = null;
    let user = undefined;
    let loading = true;

    $: getUser(username);

    async function getUser(username) {
        const response = await fetch(`http://localhost:8000/api/user/${username}`);
        loading = false;
        if (response.ok) {
            user = await response.json();
            console.log(user);
        } else {
            user = null;
        }
    }
</script>

<style>
    .profile-img {
        max-height: 200px;
        max-width: 200px;
        height: auto;
        width: auto;
    }

    .center {
        text-align: center;
    }

    .pad {
        padding: 0 1em 0.75em 1em;
    }
</style>

<Page title={username}>
    {#if loading}
        <Loading/>
        <!-- Profile Info -->
        <div style="margin-left:0.25em" class="grid">
            <div class="row">
                <div class="col-3 skeleton" style="height: 280px"></div>
                <div class="col-3 skeleton" style="height: 280px"></div>
                <div class="col-3 skeleton" style="height: 280px"></div>
            </div>
        </div>

        <!-- Match Scroller -->
        <div class="skeleton" style="height: 112px; margin-top: 1em;"></div>
        <div class="skeleton" style="height: 111px"></div>
        
        <!-- Stats Table -->
        <div style="margin-top: 2em;">
            <span class="skeleton" style="height: 1em; width: 40px; display: inline-block;"></span>
            <span class="skeleton" style="height: 1em; width: 40px; display: inline-block;"></span>
            <span class="skeleton" style="height: 1em; width: 40px; display: inline-block;"></span>
        </div>

        <div class="skeleton" style="height: 400px;"></div>

        <div class="skeleton" style="height: 4em;"></div>
        <div class="skeleton" style="height: 4em;"></div>
        <div class="skeleton" style="height: 4em;"></div>
    {:else if isDef(user)}
        <div style="margin-left:0.25em" class="grid">
            <div class="row" style="vertical-align: middle; align-items: center; justify-content: center;">
                <div class="col-3 center" style="margin-top: 0;">
                    <h1 class="nomargin">
                        <OnlineIndicator status={user.online ? OnlineStatus.Online : OnlineStatus.Offline}/> {decodeURIComponent(username)} <DevelopersOnly value={user.id} tooltipText="User ID"/>
                        <LikeButton likeType="user" likeId={user.id}/>
                    </h1>
                    {#if user.avatar}
                        <img class="profile-img" src="FTW_STATIC_HOST/avatars/{user.id}_128.webp" alt="{username} avatar" style="border-radius: 100%;"/>
                    {/if}
                </div>
                {#if user.teams.length > 0}
                    <div class="col-5"  style="background-color: var(--background-highlight); border-radius: 5px">
                        <div class="pad">
                            <h2 class="nomargin">Teams</h2>
                            {#each user.teams as team}
                                <div style="margin-left:0.25em;"><Link to="/team/{slugify(team.team_name)}">{team.team_name}</Link> {team.role}</div>
                            {/each}
                        </div>
                    </div>
                    <div class="col-4" style="background-color: var(--background-highlight);  border-radius: 5px">
                        <div class="pad">
                            <h2 class="nomargin">Upcoming Matches</h2>
                            <MatchScroller userId={user.id} matchType="upcoming"/>
                        </div>
                    </div>
                {/if}
            </div>
        </div>
        {@const isLeagueUser = user.id in $leagueUsers}
        <PlayedGames user_id={user.id}/>
        <MatchScroller userId={user.id} matchType="recent"/>

        {#if !isLeagueUser}
            {#key user.id}
                <UserSeasons userIds={[user.id]}/>
                <GameLog userIds={[user.id]} baseUrl={`/user/${user.username}`} hidePlayerCol />
                <UserGauges userIds={[user.id]} username={user.username}/>
            {/key}
        {:else}
            <GameLog leagueUserId={[user.id]} baseUrl={`/user/${user.username}`} />
        {/if}
    {:else}
        <Page404 item="user"/>
    {/if}
</Page>
