<script>
    import {createEventDispatcher} from 'svelte';

    export let height = 150;
    export let width = 300;
    export let hidePane = false;

    let showPane = false;
    let x, y;
    let delay;

    let dispatch = createEventDispatcher();

    function handleMouseEnter(e) {
        if (hidePane) {
            return;
        }
        dispatch('hover');

        x = e.x - 15;
        if (x >= window.innerWidth - width) {
            x = window.innerWidth - width;
        }

        y = e.y - height / 2;
        if (y >= window.innerHeight - height) {
            y = window.innerHeight - height;
        }
        delay = setTimeout(() => {
            showPane = true;
        }, 500);
    }

    function handleMouseLeave(e) {
        if (hidePane) {
            return;
        }
        showPane = false;
        clearTimeout(delay);
    }
</script>

<style>
    .pane {
        display: none;
        position: fixed;
        background-color: var(--background-dark);
        border-radius: 5px;
        z-index: 9999999999;
    }
    .visible {
        display: inline-block !important;
    }
</style>

<span on:mouseenter={handleMouseEnter} on:mouseleave={handleMouseLeave}>
    <slot/>
    <div class="pane" class:visible={showPane} style="left: {x}px; top: {y}px; height: {height}px; width: {width}px;">
        <slot name="pane"/>
    </div>
</span>
