<script>
    import { IS_ELECTRON, socket, ipc } from "../stores";
    import {tooltip} from './Tooltip'
    import {onMount} from 'svelte';
    import {makeGamePathKey} from '../utils.js';
    import {Link} from 'svelte-routing';

    export let fileHash = null;
    export let gameId = null;
    export let gameName = null;
    export let tag = false;

    let path = null;

    $: key = makeGamePathKey(gameId);
    onMount(async () => {
        if ($IS_ELECTRON) {
            if (gameId) {
                path = await $ipc.send('getSetting', key);
                console.log(path)
            }
            $ipc.on(key, async (_, value) => {
                path = value;
            });
        }
    });

    function playDemo() {
        $socket.emit('demo', gameId, fileHash)
    }
</script>

<style>
    span {
        cursor: pointer;
    }

    a, span {
        text-align: center;
        vertical-align: center;
    }
</style>

{#if fileHash}
    {#if $IS_ELECTRON}
        {#if !path}
            <Link to="/settings"><span class:tag use:tooltip={`Set ${gameName} Directory to Play Demo`}>⚙️</span></Link>
        {:else}
            <span class:tag use:tooltip={"Play Demo"} on:click={playDemo}>▶️</span>
        {/if}
    {:else}
        <a href="http://localhost:8000/api/upload/{fileHash}" use:tooltip={"Download Demo"} class:tag>💾</a>
    {/if}
{/if}
