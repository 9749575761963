<script>
    import { accessToken } from '../stores.js';
    import { navigate } from 'svelte-routing';

    import AdminOnly from '../components/AdminOnly.svelte';
    import DrawerLayout from '../components/DrawerLayout.svelte';
    import NavLink from '../components/NavLink.svelte';
    import AdminDataTable from '../components/AdminDataTable.svelte';
    import Page from '../components/Page.svelte';
    
    import Page404 from './Page404.svelte';

    export let table = undefined;
    export let id = undefined;

    $: if (table === undefined) {
        navigate('/admin/dashboard/all');
    } else if (id == undefined) {
        navigate(`/admin/${table}/all`);
    }
    
    // $: if (admin) {
    //     getTables();
    // }


    // $: if (admin) {
    //     if (table === 'dashboard') {
    //         // get dashboard
    //     } else if(table) {
    //         if (id == 'all') {
    //             getTable(table);
    //         } else {
    //             getTableItem(table, id);
    //         }
    //         getTableMeta(table);
    //     }
    // }
    async function getTables() {
        const response = await fetch('http://localhost:8000/api/admin/tables', {
            headers: {
                'Authorization': 'Bearer ' + $accessToken
            }
        });

        if (response.ok) {
            return await response.json();
        }
    }
</script>

<Page title="Admin Panel">
    <AdminOnly>
        <DrawerLayout>
            <div slot="drawer" class="content-padding" style="padding:4px;">
                <h2>Menu</h2>
                {#await getTables()}
                    Loading...
                {:then menu}
                    <NavLink to="/admin/dashboard/all"><div>Dashboard</div></NavLink>
                    {#each menu as item}
                        <NavLink to="/admin/{item}/all"><div>{item}</div></NavLink>
                    {/each}
                {/await}
            </div>
            <div slot="content" class="content content-padding">
                <h1 style="padding-top: 0; margin-top:0;">Admin Panel</h1>
                <h2>{table}</h2>
                <AdminDataTable tablename={table} pk={id}/>
            </div>
        </DrawerLayout>
        <svelte:fragment slot="else">
            <Page404/>
        </svelte:fragment>
    </AdminOnly>
</Page>
