<script>
    import {tooltip} from './Tooltip';

    export let league_type = undefined;
    export let inline = false;
    export let float = false;
</script>

<style>
    .float {
        float: right;
        position: absolute;
        top: 0px;
        right: 1px;
        display: flex;
        justify-content: center;
        align-items: center;
        vertical-align: top;
        background-color: rgba(1, 1, 1, 0.25);
    }
    .inline {
        display: inline-block
    }
    span {
        border-radius: 2px;
        font-weight: 800;
        /* width: 0.8em; */
        height: 0.8em;
        font-size: 0.8em;
        text-transform: uppercase;
        text-align: center;
    }

    .scrim {
        animation: scrim-change 10s ease-in-out infinite;
        right:0px;
    }

    @keyframes scrim-change {
        0% { color: palegoldenrod; }
        50% { color: lightyellow; }
        100% { color: palegoldenrod; }
    }

    .pickup {
        animation: pickup-change 10s ease-in-out infinite;
        right: 0px;
    }

    @keyframes pickup-change {
        0% { color: palevioletred; }
        50% { color: pink; }
        0% { color: palevioletred; }
    }

    .match {
        animation: match-change 10s ease-in-out infinite;
    }

    @keyframes match-change {
        0% { color: palegreen; }
        50% { color: greenyellow; }
        100% { color: palegreen; }
    }
</style>

<span>
    {#if league_type == 'rent'}
        <span class:inline class:float use:tooltip={"Scrim"} class="scrim">s</span>
    {:else if league_type == 'pickup'}
        <span class:inline class:float use:tooltip={"Pickup"} class="pickup">p</span>
    {:else if ['group', 'swiss', 'swiss'].includes(league_type)}
        <span class:inline class:float use:tooltip={"Match"} class="match">m</span>
    {/if}
</span>
