<script>
    import {RELATIVE_PATH, userKV} from '../stores.js'
    import {Link} from 'svelte-routing';
    import {tooltip} from './Tooltip';
</script>

<Link to="/bot" class="border">
    <button use:tooltip={"Add bot to Discord"}>
        <span class="nav-button">
            <img src="{$RELATIVE_PATH}/icons/bot.svg" alt="Add Bot to Discord" width="18" height="22"/>{#if !$userKV.hideButtonLabels}Discord Bot{/if}
        </span>
    </button>
</Link>
