<script>
    import { onDestroy, onMount } from 'svelte';
    import {RELATIVE_PATH, globalLoadingAnimation} from '../stores.js';

    export let active=true;
    export let inline=false;
    export let inlineSize="1em";

    onMount(() => {
        if (!inline) {
            $globalLoadingAnimation++;
        }
    });

    onDestroy(() => {
        if (!inline) {
            $globalLoadingAnimation--;
        }
    });
</script>

<style>
    .inline {
        display: flex;
        align-items: center;
        justify-content: center;
    }
</style>
{#if active}
    <!-- <div out:fade|global class="lds-ellipsis"><div></div><div></div><div></div><div></div></div> -->
    {#if inline}
        <div class="inline-loading">
            <div style="font-size: {inlineSize}"><img src="{$RELATIVE_PATH}/loading.svg" alt="Loading" style="width:{inlineSize}; height:100%;"/> <slot></slot></div>
        </div>
    {/if}
{/if}
