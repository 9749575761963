<script>
    import { onMount } from 'svelte';

    import {tooltip} from '../components/Tooltip.ts';
    import {games, RELATIVE_PATH, selectedGameId} from '../stores.js'
    import {slugify} from '../utils.js'

    let selectedGameForm;

    onMount(async () => {
        selectedGameForm = $selectedGameId;
    });

    $: selected(selectedGameForm);

    function selected(gameId) {
        if (gameId) {
            window.localStorage.setItem('selectedGameId', gameId);
            selectedGameId.set(gameId)
        }
    }
</script>

<style>
    label {
        width: 100px;
        height: 100px;
        background-color: var(--background-mid);
        border-radius: 5px;
        overflow: hidden;
        vertical-align: middle;
        text-align: center;
        padding: 0.25em;
        margin: 0.25em;
        display: inline-block;
    }

    label:hover {
        background-color: var(--background-dark)
    }

    input:checked + label {
        background-color: palegoldenrod;
    }

    img {
        object-fit: contain;
        width: 100%;
    }
</style>

<h1>Games</h1>
{#each $games as game}
    <input type="radio" id={game.id} bind:group={selectedGameForm} name="games" value={game.id} hidden>
    <label for={game.id} use:tooltip={game.game_name}>
        <img src={`${$RELATIVE_PATH}/logos/${slugify(game.game_name)}.png`} alt={`${game.game_name} icon`}/>
    </label>
{/each}
