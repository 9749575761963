<script lang="ts" context="module">
    export type EChartsOptions = echarts.EChartsOption
    export type EChartsTheme = string | object
    export type EChartsRenderer = 'canvas' | 'svg'
  
    export type ChartOptions = {
      theme?: EChartsTheme
      renderer?: EChartsRenderer
      options: EChartsOptions
    }
  
    const DEFAULT_OPTIONS: Partial<ChartOptions> = {
      theme: undefined,
      renderer: 'canvas',
    }

    let debounce;
  
    export function chartable(element: HTMLElement, echartOptions: ChartOptions) {
      const { theme, renderer, options } = {
        ...DEFAULT_OPTIONS,
        ...echartOptions,
      }
      const echartsInstance = echarts.init(element, theme, { renderer })
      echartsInstance.setOption(options)
  
      function handleResize() {
        echartsInstance.resize()
      }
  
      window.addEventListener('resize', handleResize)

      echartsInstance.on('finished', () => {
          clearTimeout(debounce)
          debounce = setTimeout(() => {
            echartsInstance.resize()
          }, 50);
      })
  
      return {
        destroy() {
          echartsInstance.dispose()
          window.removeEventListener('resize', handleResize)
        },
        update(newOptions: ChartOptions) {
          echartsInstance.setOption({
            ...echartOptions.options,
            ...newOptions.options,
          })
        },
      }
    }
</script>

<script lang="ts">
export let options: echarts.EChartsOption
export let { theme, renderer } = DEFAULT_OPTIONS
export let height = 300;

</script>

<style>
.chart {
    width: 100%;
}
</style>

<div class="chart" style="height: {height}px;" use:chartable={{ renderer, theme, options }} />
