<script lang="ts">
    // import {tooltip} from '../../components/Tooltip.ts';
    import { socket } from '../../stores';

    export let matchId: number = null;
    export let numberTeamMembers: number = null;
    export let minimumRosterSize: number = null;
    export let disabled: boolean = false;
    export let ready: boolean = false;
    export let dcid: string = null;

    // $: tooltipText = (numberTeamMembers < minimumRosterSize) ? `Need ${minimumRosterSize - numberTeamMembers} more players` : "Click to ready"

    function clickHandler(e) {
        if (matchId !== null) {
            ready = !ready;
            $socket.emit('lobbyp', matchId, {p: 'ready', d: ready});
        }
    }
</script>

<style>
    .standby {
        background-color: darkgoldenrod;
        /* margin-bottom: 0.5em; */
    }

    .ready {
        background-color: green;
        color: white;
        font-weight: 600;
    }
</style>

{#if numberTeamMembers !== null && minimumRosterSize !== null}
    <!-- <span use:tooltip={tooltipText}> -->
        <button 
        class:ready 
        class:standby={!ready && numberTeamMembers >= minimumRosterSize && (dcid !== null && dcid !== undefined)} 
        class="button" 
        on:click={clickHandler} disabled={numberTeamMembers < minimumRosterSize || disabled || (dcid === null || dcid === undefined)}>
            {#if numberTeamMembers < minimumRosterSize}
                &#8987; Waiting for <strong>{minimumRosterSize - numberTeamMembers}</strong> more players
            {:else if (dcid === null || dcid === undefined)}
                &#127758; Choose Server
            {:else if !ready}
                &#9888; Not Ready
            {:else}
                &#10004; Ready
            {/if}
        </button>
    <!-- </span> -->
{/if}