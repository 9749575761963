<script context="module">
    export const styleDefaults = {
        //layout
        "--sidebar-width": "72px",
        "--sidebar-button": "50px",
        "--sidebar-transition": "all .3s cubic-bezier(0.76, 0, 0.24, 1)",
        "--nav-height": "52px",
        "--electron-titlebar-height": "25px",
        "--content-padding": "1em",

        //base colors
        "--dark-color": "#111",
        "--background-color": "#333",
        "--background-mid": "#2B2B2B",
        "--background-dark": "#222",
        "--background-highlight": '#444',
        "--ontop-hover": "#555",
        "--foreground-color": '#eee',
        '--link-color': '#FF5B28',

        //input
        '--input-disabled-color': '#ccc',
        '--input-border-width': '0',
        '--input-border-color': '#ccc',

        //button
        '--button-background-color': '#111',
        '--button-color': '#000',
        '--button-hover-background-color': '#222',
        '--button-hover-color': '#000',
        
        //dropdown
        '--dropdown-background-color': '#111',
        '--dropdown-color': '#eee',
        '--dropdown-hover-background-color': '#000',

        //table
        '--table-heading-background': '#111',
        '--table-alternate-background': '#444',

        //mention
        '--mention-background': '#FF6B3D',
        '--mention-hover': '#FF7B53',
    }
</script>

<script>
    import {isDef} from '../utils.js'
    import {onlyPage, styles} from '../stores.js';

    for (const key in styleDefaults) {
        console.log("SET", key)
        $styles[key] = styleDefaults[key]
    }

    $: if ($onlyPage) {
        $styles['--electron-titlebar-height'] = '0px';
        updateStyles();
    }

    $: isDef($styles) && updateStyles()

    function updateStyles() {
        for (const [key, value] of Object.entries($styles)) {
            document.documentElement.style.setProperty(key, value);
        }
    }
</script>
