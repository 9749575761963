<script>
    import {accessToken, RELATIVE_PATH, globalLoadingAnimation} from '../../stores';
    import {getServer, getServerLocations, destroyServer, reinstallServer, serverIdMap} from './data.js';
    import {isDef, timeAgo, slugify} from '../../utils.js';
    import {OnlineStatus} from '../../models/Enums.ts';

    import AdminOnly from '../../components/AdminOnly.svelte';
    import NavLink from '../../components/NavLink.svelte';
    import Loading from '../../components/Loading.svelte';
    import ClickToCopy from '../../components/ClickToCopy.svelte';
    import {tooltip} from '../../components/Tooltip.ts';
    import OnlineIndicator from '../../components/OnlineIndicator.svelte';
    import TimeSince from '../../components/TimeSince.svelte';

    import {Link, navigate} from 'svelte-routing';

    export let id = undefined;

    let hidePassword = true;
    let hideRcon = true;

    let commandHistory = {
        rcon: [],
        ssh: []
    }
    let commandHistoryIndex = {
        rcon: 0,
        ssh: 0
    }
    let inputCommand;
    let commandType = "rcon";
    let rconPort = "27960";
    let terminalOutput = {
        rcon: "======== FTWGL RCON CONSOLE ========",
        ssh:  "======== FTWGL SSH CONSOLE ========"
    }
    let terminalDiv;
    let rconInputElement;

    async function requestDestroyServer() {
        $globalLoadingAnimation++;
        if (confirm("Delete server") && await destroyServer($accessToken, id)) {
            navigate("/gameservers");
        }
        $globalLoadingAnimation--;
    }

    async function requestReinstallServer() {
        $globalLoadingAnimation++;
        if (confirm("Reinstall server") && await reinstallServer($accessToken, id)) {
            navigate("/gameservers");
        }
        $globalLoadingAnimation--;
    }

    function getServerStatus(server) {
        console.log(server)
        if (server.active) {
            if ('ip' in server.config) {
                return OnlineStatus.Online;
            } else {
                return OnlineStatus.Spawning;
            }
        } else {
            return OnlineStatus.Offline;
        }
    }

    function quakeColorCodesToHtml(text) {
        const colorMap = {
            "0": "#000000",
            "1": "#FF0000",
            "2": "#00FF00",
            "3": "#FFFF00",
            "4": "#0000FF",
            "5": "#FF00FF",
            "6": "#00FFFF",
            "7": "#FFFFFF",
            "8": "#555555",
            "9": "#FF5555",
            "a": "#55FF55",
            "b": "#FFFF55",
            "c": "#5555FF",
            "d": "#FF55FF",
            "e": "#55FFFF",
            "f": "#AAAAAA"
        };

        let result = "";
        let color = "";

        for (let i = 0; i < text.length; i++) {
            if (text[i] === "^") {
            if (text[i + 1] && text[i + 1].match(/[0-9a-f]/i)) {
                color = colorMap[text[i + 1].toLowerCase()];
                i++;
                continue;
            }
            }
            result += color ? `<span style="color: ${color};">${text[i]}</span>` : text[i];
        }

        return result;
    }


    async function sendCommand(e) {
        console.log(e.keyCode)
        if (e.keyCode == 13) {
            terminalOutput[commandType] += `<br/><span style="color:white;">&gt; ${inputCommand}</span>`
            
            const request = command(inputCommand, commandType);
            commandHistory[commandType].push(inputCommand);
            inputCommand = "";
            commandHistoryIndex[commandType] = commandHistory[commandType].length;
            
            setTimeout(() => {
                terminalDiv.scroll({ top: terminalDiv.scrollHeight, behavior: 'smooth' });;
            }, 100);
            
            const response = await request;
            if (response.length > 0) {
                terminalOutput[commandType] += `<br/>${response.replace(/\n/g, "<br/>")}`
                setTimeout(() => {
                    terminalDiv.scroll({ top: terminalDiv.scrollHeight, behavior: 'smooth' });;
                }, 100);
            }
        } else if (e.keyCode == 38) {
            if (commandHistory[commandType].length > 0) {
                commandHistoryIndex[commandType]--
                if (commandHistoryIndex[commandType] < 0) commandHistoryIndex[commandType] = 0;
                if (commandHistoryIndex[commandType] > commandHistory[commandType].length) commandHistoryIndex[commandType] = commandHistory[commandType].length;

                console.log(commandHistoryIndex[commandType])

                inputCommand = commandHistory[commandType][commandHistoryIndex[commandType]];
            }
        } else if (e.keyCode == 40) {
            if (commandHistory[commandType].length > 0) {
                console.log("40")
                commandHistoryIndex[commandType]++
                if (commandHistoryIndex[commandType] < 0) commandHistoryIndex[commandType] = 0;
                if (commandHistoryIndex[commandType] > commandHistory[commandType].length) commandHistoryIndex[commandType] = commandHistory[commandType].length;

                console.log(commandHistoryIndex[commandType])

                inputCommand = commandHistory[commandType][commandHistoryIndex[commandType]];
            }
        }
    }

    async function command(command, type) {
        if (command.length <= 0) {
            return;
        }
        let url = `http://localhost:8000/api/gameserver/${id}/${type}`;
        if (type == 'rcon') {
            url += `/${rconPort}`;
        }
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${$accessToken}`
            },
            body: JSON.stringify({
                cmd: command
            })
        })
        if (response.ok) {
            const body = await response.json();
            if (isDef(body)) {
                return type == 'rcon' ? quakeColorCodesToHtml(body.response) : body.response;
            } else {
                return "No response";
            }
        }
    }

    async function getMatches() {
        const response = await fetch(`http://localhost:8000/api/gameserver/${id}/matches`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${$accessToken}`
            }
        })
        if (response.ok) {
            const body = await response.json();
            if (isDef(body)) {
                return body;
            } else {
                return [];
            }
        }
    }
</script>

<style>
    .tag {
        background-color: var(--dark-color);
        padding: 0.25em;
        margin: 0.25em;
    }
    h1 {
        margin: 0.25em;
    }
    .title {
        vertical-align: middle;
        align-items: center;
        display: flex;
    }

    .rcon {
        background-color: var(--dark-color);
        height: 300px;
        margin: 0.25em;
        font-family: monospace;
        color: #d17c78;
        padding: 20px;
        border-radius: 5px
    }

    .rcon input[type="text"] {
        background-color: var(--dark-color);
        color: white;
        border: none;
        outline: none;
        padding: 10px;
        font-size: 16px;
        font-family: monospace;
        bottom: 0;
        left: 0;
        width: calc(100%-1em)
    }

    .rcon input[type="text"]:before {
        content: '$>'
    }

    .rcon input[type="text"]:focus {
        background-color: var(--dark-color);
        color: white;
        animation: blinker 1s cubic-bezier(.5, 0, 1, 1);
    }

    .rcon input[type="text"]::placeholder {
        color: gray;
    }

    .tag {
        font-weight: 600;
    }
</style>

<NavLink to="/gameservers"><span>⬅️ Back to gameservers</span></NavLink>

{#await Promise.all([getServer($accessToken, id), getServerLocations($accessToken)])}
    <Loading/>
{:then [server, locations]}
    <h1>
        <div class="title">
            <OnlineIndicator status={getServerStatus(server)}/>
            {#if 'ip' in server['config']}
                {server['config']['ip']} <ClickToCopy text={server.config.ip} buttonOnly={true}/>
            {:else}
                Setting Up Server
            {/if}
        </div>
    </h1>

    <!-- General Information -->
    <div class="split">
        <span>
            <span class="tag">&#128205;{locations[server.config.dcid].city}, {locations[server.config.dcid].country}</span>
            <span class="tag"><TimeSince dateString={server.date_created}/></span>
            {#if server.config.public}
                <span class="tag"><img src="{$RELATIVE_PATH}/icons/unlock.svg" width="18" height="18" alt="Unlocked Icon">&nbsp;No Password</span>
            {:else}
                <span class="tag">
                    <!-- svelte-ignore a11y-click-events-have-key-events -->
                    <span use:tooltip={hidePassword ? "Show password" : "Hide password"} on:click={() => hidePassword = !hidePassword}>
                        <img src="{$RELATIVE_PATH}/icons/lock.svg" width="18" height="18" alt="Locked Icon">
                        {#if hidePassword}*********{:else}{server.config.password}{/if}
                    </span>
                    <ClickToCopy text={server.config.password} buttonOnly={true}/>
                </span>
            {/if}
            <span class="tag">
                <!-- svelte-ignore a11y-click-events-have-key-events -->
                <span use:tooltip={hideRcon ? "Show rcon" : "Hide rcon"} on:click={() => hideRcon = !hideRcon}>
                    <img src="{$RELATIVE_PATH}/icons/key.svg" width="18" height="18" alt="Locked Icon" style="vertical-align: sub;">
                    {#if hideRcon}*********{:else}{server.config.rcon}{/if}
                </span>
                <ClickToCopy text={server.config.rcon} buttonOnly={true}/>
            </span>
        </span>

        {#if server.active}
            <span class="right">
                <button class="tag nowrap" on:click={requestDestroyServer}>
                    <img class="inline" src="{$RELATIVE_PATH}/icons/power.svg" width="18" height="18" alt="Unlocked Icon"> Destroy
                </button>
                <button class="tag nowrap" on:click={requestReinstallServer}>
                    <img class="inline" src="{$RELATIVE_PATH}/icons/refresh.svg" width="18" height="18" alt="Unlocked Icon">
                    Reinstall
                </button>
            </span>
        {/if}
    </div>

    <!-- RCON -->
    {#if 'ip' in server['config']}
        <AdminOnly>
            <div style="display: flex; flex-direction: row; justify-content: space-between;" class="split">
                <span class="button-group">
                    <input id="rcon-radio" type="radio" value="rcon" bind:group={commandType}/><label class="tag" for="rcon-radio">RCON</label>
                    <input id="ssh-radio" type="radio" value="ssh" bind:group={commandType}/><label class="tag" for="ssh-radio">SSH</label>
                </span>
                {#if server['pool'] && commandType == 'rcon'}
                    <span class="button-group">
                        <input id="default-rcon-port" type="radio" value="27960" bind:group={rconPort}/><label class="tag" for="default-rcon-port">:27960</label>
                        <input id="second-rcon-port" type="radio" value="2" bind:group={rconPort}/><label class="tag" for="second-rcon-port">:2</label>
                        <input id="third-rcon-port" type="radio" value="3" bind:group={rconPort}/><label class="tag" for="third-rcon-port">:3</label>
                    </span>
                {/if}
            </div>
        </AdminOnly>

        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <div class="rcon" on:click={() => rconInputElement.focus()}>
            <div style="max-height: calc(300px - 60px)" class="overflow-scroll" bind:this={terminalDiv}>
                {@html terminalOutput[commandType]}
            </div>
            &gt;<input bind:this={rconInputElement} type="text" style="color: white;" bind:value={inputCommand} on:keydown={sendCommand}/> 
        </div>
    {/if}

    {#await getMatches()}
        <Loading/>
    {:then matches}
        <div>
            <h2>Matches</h2>
        </div>
        {#if matches.length}
            {#each matches as match}
                <Link to="/match/{match.id}">
                    <div class="card split" style="background-color: var(--background-dark);">
                        <span>
                            <span class="tag">
                                {match.league}
                            </span>

                            <span class="tag" use:tooltip={match.game_type}>
                                {match.gt_abbreviation}
                            </span>

                            <span class="tag">
                                {match.season}
                            </span>
                        </span>
                        <span>
                            <span class="tag">
                                <TimeSince dateString={match.date_created}/>
                            </span>

                            <span class="tag" use:tooltip={match.game_name}>
                                <img src="/logos/{slugify(match.game_name)}.png" alt={match.game_name} height="16"/>{match.game_abbreviation}
                            </span>
                        </span>
                    </div>
                </Link>
            {/each}
        {:else}
            <div class="card" style="background-color: var(--background-dark);">
                No matches played
            </div>
        {/if}
    {/await}
{/await}
