<script>
    import {currentLocation, RELATIVE_PATH, isMobile, userKV} from '../stores.js'
    import {tooltip} from './Tooltip'
    import {onMount} from 'svelte'

    let copied = false
    $: tooltipText = copied ? "Copied to Clipboard" : "Share Current Page";

    async function sharePage() {
        await navigator.clipboard.writeText(`http://localhost:8000${$currentLocation.pathname}`)
        copied = true
        setTimeout(() => copied = false, 2500)
    }

    onMount(async () => {
        console.log("MOUNTED")
    });
</script>

{#if copied}
    <button class="border" use:tooltip={tooltipText} on:click={sharePage}>
        <span class="nav-button">
            {#if $isMobile}
                📋
            {:else}
                📋Copied
            {/if}
        </span>
    </button>
{:else}
    <button class="border" use:tooltip={tooltipText} on:click={sharePage}>
        <span class="nav-button">
                <img src="{$RELATIVE_PATH}/icons/share.svg" alt="Share Current Page" width="16" height="22"/>
                {#if !$userKV.hideButtonLabels}Share{/if}
        </span>
    </button>
{/if}
