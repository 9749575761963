<script lang="ts">
    import {accessToken, userData, mention} from '../../stores.js';
    import {tooltip} from '../Tooltip.ts';
    import { createEventDispatcher } from 'svelte';
    import {mentionListener} from './PostMention.svelte'
    import {formatDateToUTC} from '../../utils.js'

    export let sectionId: number = null;
    export let threadId: number = null;
    export let postId: number = null;
    export let thread: boolean = false;
    export let edit: boolean = false;

    export let text = "";

    let dispatch = createEventDispatcher();

    $: console.log(text);

    let threadTitle: string = null;

    let trix, inputElement;

    $: console.log(inputElement);

    function handleEnter(e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            trix.focus();
        }
    }

    async function sendData(e) {
        e.preventDefault();

        if (inputElement && inputElement.value && inputElement.value.length > 0) {
            if (sectionId !== null && thread && threadTitle && threadTitle.length > 0) { // new thread
                await createThread();
            } else if (edit) { // new post or edit post
                await updatePost();
            } else {
                await newPost();
            }
        }
        
    }

    async function createThread() {
        const response = await fetch('http://localhost:8000/api/forum/thread', {
            method: 'POST',
            headers: {
                authorization: 'Bearer ' + $accessToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                section_id: sectionId,
                title: threadTitle,
                post: inputElement.value
            })
        })

        if (response.ok) {
            text = inputElement.value;
            const body = await response.json()
            dispatch('threadCreated', body['slug']);
        }

        return false
    }

    async function updatePost() {
        const response = await fetch('http://localhost:8000/api/forum/post', {
            method: 'PUT',
            headers: {
                authorization: 'Bearer ' + $accessToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                post_id: postId,
                post: inputElement.value
            })
        })

        if (response.ok) {
            text = inputElement.value;
            dispatch('postUpdated', text);
        }
    }

    async function newPost() {
        const response = await fetch('http://localhost:8000/api/forum/post', {
            method: 'POST',
            headers: {
                authorization: 'Bearer ' + $accessToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                thread_id: threadId,
                post: inputElement.value
            })
        })

        if (response.ok) {
            const body = await response.json()
            text = inputElement.value;
            dispatch('postCreated', {
                id: body.id,
                username: $userData.username,
                avatar: $userData.avatar,
                user_id: $userData.id,
                post: text,
                date_created: formatDateToUTC(new Date())
            });
        }
    }

    function handleKeydown(event) {
        if (event.key === 'Enter' && event.ctrlKey) {
            sendData(event)
        }
    }
</script>

<style>
    :global(trix-editor), :global(.trix-button), .thread {
        background-color: var(--background-dark) !important;
        color: white !important;
        border: none !important;
    }

    :global(.trix-button:hover) {
        background-color: var(--background-highlight) !important;
    }

    :global(.trix-active) {
        background-color: #a5dc86 !important;
    }

    :global(.trix-button-group) {
        border: none !important;
    }
    :global(custom-toolbar) {
        /* display: none !important; */
    }

    .thread {
        display: block;
        width: 100%;
    }
    .thread:focus{
        border: none;
        outline: none !important;
    }

    trix-editor {
        border: 1px solid #bbb;
        border-radius: 3px;
        margin: 0;
        padding: 0.4em 0.6em;
        min-height: 5em;
        outline: none; 
    }
    custom-toolbar * {
        box-sizing: border-box; 
    }
    custom-toolbar .trix-button-row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;
        overflow-x: auto; 
    }
    custom-toolbar .trix-button-group {
        display: flex;
        margin-bottom: 10px;
        border: 1px solid #bbb;
        border-top-color: #ccc;
        border-bottom-color: #888;
        border-radius: 3px; 
    }
    custom-toolbar .trix-button-group:not(:first-child) {
        margin-left: 1.5vw; 
    }

    @media (max-device-width: 768px) {
        custom-toolbar .trix-button-group:not(:first-child) {
            margin-left: 0; 
        } 
    }
    custom-toolbar .trix-button-group-spacer {
        flex-grow: 1; 
    }
    @media (max-device-width: 768px) {
        custom-toolbar .trix-button-group-spacer {
            display: none; 
        } 
    }
    custom-toolbar .trix-button {
        position: relative;
        float: left;
        color: rgba(0, 0, 0, 0.6);
        font-size: 0.75em;
        font-weight: 600;
        white-space: nowrap;
        padding: 0 0.5em;
        margin: 0;
        outline: none;
        border: none;
        border-bottom: 1px solid #ddd;
        border-radius: 0;
        background: transparent; 
    }
    custom-toolbar .trix-button:not(:first-child) {
        border-left: 1px solid #ccc; 
    }
    custom-toolbar .trix-button.trix-active {
        background: #cbeefa;
        color: black; 
    }
    custom-toolbar .trix-button:not(:disabled) {
        cursor: pointer; 
    }
    custom-toolbar .trix-button:disabled {
        color: rgba(0, 0, 0, 0.125); 
    }
    @media (max-device-width: 768px) {
        custom-toolbar .trix-button {
            letter-spacing: -0.01em;
            padding: 0 0.3em; 
        } 
    }
    custom-toolbar .trix-button--icon {
        font-size: inherit;
        width: 2.6em;
        height: 1.6em;
        max-width: calc(0.8em + 4vw);
        text-indent: -9999px; 
    }
    @media (max-device-width: 768px) {
        custom-toolbar .trix-button--icon {
            height: 2em;
            max-width: calc(0.8em + 3.5vw);
        }
    }
    custom-toolbar .trix-button--icon::before {
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.6;
        content: "";
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain; 
    }
    @media (max-device-width: 768px) {
        custom-toolbar .trix-button--icon::before {
            right: 6%;
            left: 6%; 
        } 
    }
    custom-toolbar .trix-button--icon.trix-active::before {
        opacity: 1; 
    }
    custom-toolbar .trix-button--icon:disabled::before {
        opacity: 0.125; 
    }
    custom-toolbar .trix-button--icon-attach::before {
        background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M16.5%206v11.5a4%204%200%201%201-8%200V5a2.5%202.5%200%200%201%205%200v10.5a1%201%200%201%201-2%200V6H10v9.5a2.5%202.5%200%200%200%205%200V5a4%204%200%201%200-8%200v12.5a5.5%205.5%200%200%200%2011%200V6h-1.5z%22%2F%3E%3C%2Fsvg%3E);
        top: 8%;
        bottom: 4%; 
    }
    custom-toolbar .trix-button--icon-bold::before {
        background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M15.6%2011.8c1-.7%201.6-1.8%201.6-2.8a4%204%200%200%200-4-4H7v14h7c2.1%200%203.7-1.7%203.7-3.8%200-1.5-.8-2.8-2.1-3.4zM10%207.5h3a1.5%201.5%200%201%201%200%203h-3v-3zm3.5%209H10v-3h3.5a1.5%201.5%200%201%201%200%203z%22%2F%3E%3C%2Fsvg%3E); 
    }
    custom-toolbar .trix-button--icon-italic::before {
        background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M10%205v3h2.2l-3.4%208H6v3h8v-3h-2.2l3.4-8H18V5h-8z%22%2F%3E%3C%2Fsvg%3E); 
    }
    custom-toolbar .trix-button--icon-link::before {
        background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M9.88%2013.7a4.3%204.3%200%200%201%200-6.07l3.37-3.37a4.26%204.26%200%200%201%206.07%200%204.3%204.3%200%200%201%200%206.06l-1.96%201.72a.91.91%200%201%201-1.3-1.3l1.97-1.71a2.46%202.46%200%200%200-3.48-3.48l-3.38%203.37a2.46%202.46%200%200%200%200%203.48.91.91%200%201%201-1.3%201.3z%22%2F%3E%3Cpath%20d%3D%22M4.25%2019.46a4.3%204.3%200%200%201%200-6.07l1.93-1.9a.91.91%200%201%201%201.3%201.3l-1.93%201.9a2.46%202.46%200%200%200%203.48%203.48l3.37-3.38c.96-.96.96-2.52%200-3.48a.91.91%200%201%201%201.3-1.3%204.3%204.3%200%200%201%200%206.07l-3.38%203.38a4.26%204.26%200%200%201-6.07%200z%22%2F%3E%3C%2Fsvg%3E); 
    }
    custom-toolbar .trix-button--icon-strike::before {
        background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.73%2014l.28.14c.26.15.45.3.57.44.12.14.18.3.18.5%200%20.3-.15.56-.44.75-.3.2-.76.3-1.39.3A13.52%2013.52%200%200%201%207%2014.95v3.37a10.64%2010.64%200%200%200%204.84.88c1.26%200%202.35-.19%203.28-.56.93-.37%201.64-.9%202.14-1.57s.74-1.45.74-2.32c0-.26-.02-.51-.06-.75h-5.21zm-5.5-4c-.08-.34-.12-.7-.12-1.1%200-1.29.52-2.3%201.58-3.02%201.05-.72%202.5-1.08%204.34-1.08%201.62%200%203.28.34%204.97%201l-1.3%202.93c-1.47-.6-2.73-.9-3.8-.9-.55%200-.96.08-1.2.26-.26.17-.38.38-.38.64%200%20.27.16.52.48.74.17.12.53.3%201.05.53H7.23zM3%2013h18v-2H3v2z%22%2F%3E%3C%2Fsvg%3E); 
    }
    custom-toolbar .trix-button--icon-quote::before {
        background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M6%2017h3l2-4V7H5v6h3zm8%200h3l2-4V7h-6v6h3z%22%2F%3E%3C%2Fsvg%3E); 
    }
    custom-toolbar .trix-button--icon-heading-1::before {
        background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12%209v3H9v7H6v-7H3V9h9zM8%204h14v3h-6v12h-3V7H8V4z%22%2F%3E%3C%2Fsvg%3E); 
    }
    custom-toolbar .trix-button--icon-code::before {
        background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.2%2012L15%2015.2l1.4%201.4L21%2012l-4.6-4.6L15%208.8l3.2%203.2zM5.8%2012L9%208.8%207.6%207.4%203%2012l4.6%204.6L9%2015.2%205.8%2012z%22%2F%3E%3C%2Fsvg%3E); 
    }
    custom-toolbar .trix-button--icon-bullet-list::before {
        background-image: url(data:image/svg+xml,%3Csvg%20version%3D%221%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M4%204a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm0%206a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm0%206a2%202%200%201%200%200%204%202%202%200%200%200%200-4zm4%203h14v-2H8v2zm0-6h14v-2H8v2zm0-8v2h14V5H8z%22%2F%3E%3C%2Fsvg%3E); 
    }
    custom-toolbar .trix-button--icon-number-list::before {
        background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M2%2017h2v.5H3v1h1v.5H2v1h3v-4H2v1zm1-9h1V4H2v1h1v3zm-1%203h1.8L2%2013.1v.9h3v-1H3.2L5%2010.9V10H2v1zm5-6v2h14V5H7zm0%2014h14v-2H7v2zm0-6h14v-2H7v2z%22%2F%3E%3C%2Fsvg%3E); 
    }
    custom-toolbar .trix-button--icon-undo::before {
        background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M12.5%208c-2.6%200-5%201-6.9%202.6L2%207v9h9l-3.6-3.6A8%208%200%200%201%2020%2016l2.4-.8a10.5%2010.5%200%200%200-10-7.2z%22%2F%3E%3C%2Fsvg%3E); 
    }
    custom-toolbar .trix-button--icon-redo::before {
        background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M18.4%2010.6a10.5%2010.5%200%200%200-16.9%204.6L4%2016a8%208%200%200%201%2012.7-3.6L13%2016h9V7l-3.6%203.6z%22%2F%3E%3C%2Fsvg%3E); 
    }
    custom-toolbar .trix-button--icon-decrease-nesting-level::before {
        background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M3%2019h19v-2H3v2zm7-6h12v-2H10v2zm-8.3-.3l2.8%202.9L6%2014.2%204%2012l2-2-1.4-1.5L1%2012l.7.7zM3%205v2h19V5H3z%22%2F%3E%3C%2Fsvg%3E); 
    }
    custom-toolbar .trix-button--icon-increase-nesting-level::before {
        background-image: url(data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2224%22%20height%3D%2224%22%3E%3Cpath%20d%3D%22M3%2019h19v-2H3v2zm7-6h12v-2H10v2zm-6.9-1L1%2014.2l1.4%201.4L6%2012l-.7-.7-2.8-2.8L1%209.9%203.1%2012zM3%205v2h19V5H3z%22%2F%3E%3C%2Fsvg%3E); 
    }
    custom-toolbar .trix-dialogs {
        position: relative; 
    }
    custom-toolbar .trix-dialog {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        font-size: 0.75em;
        padding: 15px 10px;
        background: #fff;
        box-shadow: 0 0.3em 1em #ccc;
        border-top: 2px solid #888;
        border-radius: 5px;
        z-index: 5; 
    }
    custom-toolbar .trix-input--dialog {
        font-size: inherit;
        font-weight: normal;
        padding: 0.5em 0.8em;
        margin: 0 10px 0 0;
        border-radius: 3px;
        border: 1px solid #bbb;
        background-color: #fff;
        box-shadow: none;
        outline: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    custom-toolbar .trix-input--dialog.validate:invalid {
        box-shadow: #F00 0px 0px 1.5px 1px; 
    }
    custom-toolbar .trix-button--dialog {
        font-size: inherit;
        padding: 0.5em;
        border-bottom: none; 
    }
    custom-toolbar .trix-dialog--link {
        max-width: 600px; 
    }
    custom-toolbar .trix-dialog__link-fields {
        display: flex;
        align-items: baseline;
    }
    custom-toolbar .trix-dialog__link-fields .trix-input {
        flex: 1; 
    }
    custom-toolbar .trix-dialog__link-fields .trix-button-group {
        flex: 0 0 content;
        margin: 0; 
    }
</style>

<div style="padding: 0.5em;">
    <form>
        {#if thread}
            <input type="text" class="thread" placeholder="Thread Name" bind:value={threadTitle} autofocus on:keypress={handleEnter}/>
        {/if}

        <custom-toolbar id="customtoolbar">
            <div class="trix-button-row">
                <span class="trix-button-group trix-button-group--text-tools" data-trix-button-group="text-tools">
                    <button type="button" class="trix-button trix-button--icon trix-button--icon-bold" data-trix-attribute="bold" data-trix-key="b" use:tooltip={"Bold"} tabindex="-1">Bold</button>
                    <button type="button" class="trix-button trix-button--icon trix-button--icon-italic" data-trix-attribute="italic" data-trix-key="i" use:tooltip={"Italic"} tabindex="-1">Italic</button>
                    <button type="button" class="trix-button trix-button--icon trix-button--icon-strike" data-trix-attribute="strike" use:tooltip={"Strikethrough"} tabindex="-1">Strikethrough</button>
                    <!-- <button type="button" class="trix-button trix-button--icon trix-button--icon-link" data-trix-attribute="href" data-trix-action="link" data-trix-key="k" use:tooltip={"Link"} tabindex="-1">Link</button> -->
                </span>
            
                <span class="trix-button-group trix-button-group--block-tools" data-trix-button-group="block-tools">
                    <button type="button" class="trix-button trix-button--icon trix-button--icon-heading-1" data-trix-attribute="heading1" use:tooltip={"Heading"} tabindex="-1">Heading</button>
                    <button type="button" class="trix-button trix-button--icon trix-button--icon-quote" data-trix-attribute="quote" use:tooltip={"Quote"} tabindex="-1">Quote</button>
                    <!-- <button type="button" class="trix-button trix-button--icon trix-button--icon-code" data-trix-attribute="code" use:tooltip={"Code"} tabindex="-1">Code</button> -->
                    <button type="button" class="trix-button trix-button--icon trix-button--icon-bullet-list" data-trix-attribute="bullet" use:tooltip={"Bullets"} tabindex="-1">Bullets</button>
                    <button type="button" class="trix-button trix-button--icon trix-button--icon-number-list" data-trix-attribute="number" use:tooltip={"Numbers"} tabindex="-1">Numbers</button>
                    <!-- <button type="button" class="trix-button trix-button--icon trix-button--icon-decrease-nesting-level" data-trix-action="decreaseNestingLevel" use:tooltip={"Decrease Level"} tabindex="-1">Decrease Level</button>
                    <button type="button" class="trix-button trix-button--icon trix-button--icon-increase-nesting-level" data-trix-action="increaseNestingLevel" use:tooltip={"Increase Level"} tabindex="-1">Increase Level</button> -->
                </span>
            
                <span class="trix-button-group trix-button-group--file-tools" data-trix-button-group="file-tools">
                    <!-- <button type="button" class="trix-button trix-button--icon trix-button--icon-attach" data-trix-action="attachFiles" use:tooltip={"Attach Files"} tabindex="-1">Attach Files</button> -->
                </span>
            
                <span class="trix-button-group-spacer"></span>
            
                <span class="trix-button-group trix-button-group--history-tools" data-trix-button-group="history-tools">
                    <button type="button" class="trix-button trix-button--icon trix-button--icon-undo" data-trix-action="undo" data-trix-key="z" use:tooltip={"Undo"} tabindex="-1">Undo</button>
                    <button type="button" class="trix-button trix-button--icon trix-button--icon-redo" data-trix-action="redo" data-trix-key="shift+z" use:tooltip={"Redo"} tabindex="-1">Redo</button>
                </span>
            </div>
        </custom-toolbar>

        <input id="x" type="hidden" name="content" bind:this={inputElement} value={text} use:mentionListener/>
        <trix-editor bind:this={trix} toolbar="customtoolbar" input="x" placeholder="Post Contents" on:keydown={handleKeydown} use:mentionListener></trix-editor>
    </form>

    <button on:click={sendData}>Post</button>
</div>
