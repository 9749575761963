<script>
    import { accessToken } from '../stores.js';
    import { Link } from 'svelte-routing';
  import { isDef } from '../utils.js';

    export let tablename = undefined;
    export let pk = undefined;
    export let column = undefined;
    export let page = 1;
    export let itemsPerPage = 25;

    async function getTableMeta(tablename) {
        const response = await fetch(`http://localhost:8000/api/admin/table-meta/${tablename}`, {
            headers: {
                'Authorization': 'Bearer ' + $accessToken
            }
        });

        if (response.ok) {
            return await response.json();
        }
    }

    async function getTable(tablename, pk, column, page, itemsPerPage) {
        if (isDef(pk) && pk != 'all') {
            if (column == undefined) {
                console.log('table, pk')
                return await getTableWithPk(tablename, pk)
            } else {
                console.log('table, pk, column')
                return await getTableWithPkColumn(tablename, pk, column, page, itemsPerPage)
            }
        } else {
            console.log('table')
            return await getTableAllItems(tablename, page, itemsPerPage)
        }
    }

    async function getTableAllItems(tablename, page, itemsPerPage) {
        console.log(tablename);
        const response = await fetch(`http://localhost:8000/api/admin/table/${tablename}?page=${page}&items_per_page=${itemsPerPage}`, {
            headers: {
                'Authorization': 'Bearer ' + $accessToken
            }
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            return data;
        }
    }

    async function getTableWithPk(tablename, pk) {
        console.log(tablename, pk);
        const response = await fetch(`http://localhost:8000/api/admin/table/${tablename}/${pk}`, {
            headers: {
                'Authorization': 'Bearer ' + $accessToken
            }
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            return data;
        }
    }

    async function getTableWithPkColumn(tablename, pk, column, page, itemsPerPage) {
        console.log(tablename, pk, column);
        const response = await fetch(`http://localhost:8000/api/admin/table/${tablename}/${column}/${pk}?page=${page}&items_per_page=${itemsPerPage}`, {
            headers: {
                'Authorization': 'Bearer ' + $accessToken
            }
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            return data;
        }
    }

    async function getTableCount(tablename, pk, column) {
        const url = (pk && column) ? `http://localhost:8000/api/admin/table-count/${tablename}/${column}/${pk}` : `http://localhost:8000/api/admin/table-count/${tablename}`;
        const response = await fetch(url, {
            headers: {
                'Authorization': 'Bearer ' + $accessToken
            }
        });

        if (response.ok) {
            const data = await response.json();
            console.log(data);
            return data;
        }
    }
</script>

<style>
    table {
        width: 100%;
        overflow-x: scroll;
    }
    td, tr, th {
        text-align: center;
    }
    .gray {
        color: #888;
    }
</style>

{#await getTableMeta(tablename)}
    Loading...
{:then dataMeta}
    {#await getTable(tablename, pk, column, page, itemsPerPage)}
        Loading...
    {:then data}
        {#if data && data.length}
            <div class="table-wrapper overflow-scroll">
                <table>
                    <thead>
                        <tr>
                            {#each Object.keys(data[0]) as key}
                                <th>{key}</th>
                            {/each}
                        </tr>
                    </thead>
                    <tbody class="monospace">
                        {#each data as item}
                            <tr>
                                {#each Object.keys(item) as key}
                                    {#if key in dataMeta.indices && dataMeta.indices[key].pk}
                                        <td><Link to="/admin/{tablename}/{item[key]}">{item[key]}</Link></td>
                                    {:else if key in dataMeta.foreign_keys && isDef(item[key])}
                                        <td><Link to="/admin/{dataMeta.foreign_keys[key].primary_tablename}/{item[key]}">{item[key]}</Link></td>
                                    {:else}
                                        {#if isDef(item[key]) && item[key].length > 100}
                                            <td class:gray={["null", null].includes(item[key])}>
                                                {item[key].substring(0, 100)}
                                                <button class="button" on:click={(e) => alert("TODO MODAL FULL SCREEN")}>+</button>
                                            </td>
                                        {:else}
                                            <td class:gray={["null", null].includes(item[key])}>{item[key]}</td>
                                        {/if}
                                    {/if}
                                {/each}
                            </tr>
                        {/each}
                    </tbody>
                </table>
            </div>

            <!-- {#if !('id' in data[0]) || data[0].id != pk} -->
                {#await getTableCount(tablename, pk, column)}
                    ...
                {:then data}
                    {#if data.count > itemsPerPage}
                        <div class="center pagination overflow-scroll">
                            {#each {length: data.count / itemsPerPage} as _, pageNo}
                                <button class="button" on:click={() => page=pageNo+1} class:current={pageNo+1 == page}>{pageNo+1}</button>
                            {/each}
                        </div>
                    {/if}
                {/await}
            <!-- {/if} -->
        {:else}
            <em>No records</em>
        {/if}        
    {/await}
    {#if pk && pk != 'all' && column == undefined}
        {#each dataMeta.referencing_fks as table}
            <hr class="drawer"/>
            <h3>{table.foreign_tablename}</h3>
            <svelte:self tablename={table.foreign_tablename} pk={pk} column={table.fk_column}/>
        {/each}
    {/if}
{/await}