<script>
    import {tooltip} from './Tooltip.js';
    import DemoButton from './DemoButton.svelte';
    import UserLink from './UserLink.svelte'

    import {Link} from 'svelte-routing';

    import { MaxValueTracker, isDef } from '../utils.js';
    import {pingColor} from '../pages/gameserver/data.js';
    import TimeSince from './TimeSince.svelte';

    export let categories = undefined;
    export let headings = undefined;
    export let footings = undefined;
    export let rows = undefined;
    export let sortCol = null;
    export let sortDescending = true;

    let maxValues = new MaxValueTracker();

    $: sortedRows = sortRow(rows, sortCol, sortDescending);

    function sortTable(col) {
        if (col == sortCol) {
            sortDescending = !sortDescending;
        } else {
            sortCol = col;
            sortDescending = true;
        }
    }

    function sortRow(rows, col, sortDescending) {
        var sortDir = sortDescending ? -1 : 1;
        var sortFn;
        if (typeof(rows[0][col]) == 'object') {
            var sortKey = 'sortKey' in rows[0][col] ? 'sortKey' : 'col'
            sortFn = (a, b) => a[col][sortKey] > b[col][sortKey] ? 1 * sortDir : -1 * sortDir;
        } else {
            sortFn = (a, b) => a[col] > b[col] ? 1 * sortDir : -1 * sortDir;
        }
        return rows = rows.sort(sortFn);
    }

    function isLeft(index) {
        return ['Player', 'Season'].includes(headings[index]);
    }
</script>

{#if rows}
    <table>
        {#if headings}
            <thead>
                {#if categories}
                    <tr class="category">
                        {#each Object.entries(categories) as [category, colspan]}
                            <th colspan={colspan}>{category}</th>
                        {/each}
                    </tr>
                {/if}
                <tr class="headings">
                    {#each headings as heading, i}
                        <th class:center={heading != 'Player'} 
                            class="sortable nowrap"
                            class:selected={sortCol == i}
                            class:asc={!sortDescending}
                            class:desc={sortDescending}
                            on:click={() => sortTable(i)}
                            colspan={typeof heading == 'object' && 'colspan' in heading ? heading['colspan'] : 1}
                        >
                            {#if typeof heading == 'object'}
                                <span use:tooltip={heading['tooltip']}>
                                    {heading['col']}
                                </span>
                            {:else}
                                {heading}
                            {/if}
                        </th>
                    {/each}
                </tr>
            </thead>
        {/if}

        <tbody>
            {#each sortedRows as row}
                <tr>
                    {#each row as cell, i}
                        {#key row}
                            {#if typeof cell == 'object'}
                                {#if cell['type'] == 'Link'}
                                    <td>
                                        <div class:left={isLeft(i) || cell['left']} class="nowrap">
                                            <Link to={cell['to']}>
                                                <span class:tooltip={isDef(cell['tooltip'])} data:tooltip={cell['tooltip']} class:monospace={typeof(cell['col']) == 'number'}>
                                                    {@html cell['col']}
                                                </span>
                                            </Link>
                                        </div>
                                    </td>
                                {:else if cell['type'] == 'UserLink'}
                                    <td>
                                        <div class:left={isLeft(i)} class="nowrap">
                                            {#if 'col' in cell}{@html cell['col']}{/if}
                                            <UserLink avatar={cell['avatar']} online={cell['online']} username={cell['username']} userId={cell['userId']} anon={cell['anon']} avatarOnly={cell['avatarOnly']}/>
                                        </div>
                                    </td>
                                {:else if cell['type'] == 'DemoButton'}
                                    <td use:tooltip={cell['tooltip']}><span class="demo-button"><DemoButton fileHash={cell['demo']['file_hash']} gameId={cell['gameId']} gameName={cell['gameName']}/></span></td>
                                {:else if cell['type'] == 'ping'}
                                    <td class="monospace" style="color: {pingColor(cell['col'])};">{cell['col']}</td>
                                {:else if cell['type'] == 'TimeSince'}
                                    <td style="text-align:left;"><TimeSince dateString={cell['dateString']} short/></td>
                                {:else}
                                    <td use:tooltip={cell['tooltip']}>{@html cell['col']}</td>
                                {/if}
                            {:else}
                                <td class:left={isLeft(i)} class:nowrap={isLeft(i)} class:max={i != 0 && maxValues.isMax(i, cell)} class:zero={cell == 0} class:monospace={typeof cell == 'number'}>
                                    {@html i != 0 ? maxValues.memoizeMaximum(i, cell) : cell}{#if typeof headings[i] == 'object' && 'tooltip' in headings[i] && headings[i].tooltip.includes('Percent')}%{/if}
                                </td>
                            {/if}
                        {/key}
                    {/each}
                </tr>
            {/each}
        </tbody>

        {#if footings}
            <tfoot>
                <tr class="footings">
                    {#each footings as footing}
                        <td>{footing}</td>
                    {/each}
                </tr>
            </tfoot>
        {/if}
    </table>
{/if}
