<script>
    import {OnlineStatus} from '../models/Enums.ts';
    import {tooltip} from './Tooltip.ts'

    export let status = undefined;

    function statusToColor(status) {
        switch(status) {
            case OnlineStatus.Live:
            case OnlineStatus.Online:
                return "lime";
            case OnlineStatus.Expired:
            case OnlineStatus.Disconnected:
                return "red";
            case OnlineStatus.Offline:
                return "grey";
            case OnlineStatus.Spawning:
            case OnlineStatus.Away:
            case OnlineStatus.Warmup:
                return "yellow";
            case OnlineStatus.Active:
                return "dodgerblue"
        }
    }
</script>

{#if status !== undefined}
    <span use:tooltip={status} style="color:{statusToColor(status)}; font-size: 0.6em; margin: 0.25em; user-select: none;">&#11044;</span>
{/if}
