<script>
    import {RELATIVE_PATH, IS_ELECTRON, ipc, isMobile} from '../stores.js';

    import { onMount } from "svelte";

    let loginFailure = null;

	onMount(async () => {
        if ($IS_ELECTRON) {
            $ipc.on('login-failed', () => {
                loginFailure = "Login Error"
            });
        }
    });

    function electronLogin() {
        console.log("electronLogin")
        $ipc.send('discord-login', true)
    }
</script>

<style>
    .discord {
        background-color: #5865F2;
        vertical-align: middle;
        margin: 0.5em;
        display:inline-block;
        color: white;
        padding: 0.2em 0.5em;
        font-size: 16px;
        font-weight: 600;
    }

    .discord > * {
        display: inline-block;
        vertical-align: middle;
    }
</style>

{#if $IS_ELECTRON}
    <div class="discord border" on:click={electronLogin} style="cursor: pointer; display: flex; justify-content: center; padding: 0.5em;">
        <img src={$RELATIVE_PATH + "/logos/Discord-Logo-White.svg"} alt="Discord Logo" style="display: block; text-align: center; vertical-align: middle; width: 4em; margin: 0.5em;">
        <p style="font-size: 1.5em;">Login with Discord</p>
    </div>
    {#if loginFailure !== null}
        <p>{loginFailure}, try again.</p>
    {/if}
{:else}
    <a href="DISCORD_LOGIN_URL">
        <div class="discord border">
            <img src={$RELATIVE_PATH + "/logos/Discord-Logo-White.svg"} alt="Discord Logo" height="16">
            {#if !($isMobile)}
                <div>Login with Discord</div>
            {/if}
        </div>
    </a>
{/if}
