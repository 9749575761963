<script>
    import {accessToken, RELATIVE_PATH} from '../../stores.js';
	import {navigate} from 'svelte-routing'
	import {getServerLocations, pingColor} from './data.js';
	import NavLink from '../../components/NavLink.svelte';
	import Loading from '../../components/Loading.svelte';
	import Ping from './Ping.svelte';

	let location = null, publicServer = null, gameType = null, rconpassword = null, serverpassword=null;
	let loading = false;

	$: disabled = !(location != null && gameType != null && rconpassword != null && (publicServer === 'public' || (publicServer === 'private' && serverpassword != null)))

	let pingMap = {};

	async function onSubmit(e) {
		e.preventDefault();

		const postBody = {
			'dcid': location,
			'gametype': gameType,
			'rcon': rconpassword,
			'public': publicServer === 'public',
		}
		if (publicServer === 'private') {
			postBody['password'] = serverpassword
		}

		loading = true;
		const response = await fetch('http://localhost:8000/api/gameserver', {
			method: 'POST',
			headers: {
				'Authorization': `Bearer ${$accessToken}`,
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(postBody)
		});
		loading = false;
		if (response.status == 200) {
			navigate('/gameservers')
		} else {
			console.log("err")
		}
	}
</script>

<style>
	input[type=radio], input[type="submit"] {
		display: none;
	}

	input[type=radio]:checked + label {
		background-color: #a5dc86;
		box-shadow: none;
		text-shadow: 0px 0px 1px black;
		color: #222;
	}

	img {
		margin: 0.5em;
	}

	.submitLabel {
		cursor: not-allowed;
		color: grey;
	}
</style>

{#if loading}
	<Loading/>
{/if}
<NavLink to="/gameservers"><span>⬅️ Back to gameservers</span></NavLink>

<h1>Create Server</h1>

<h2>Location</h2>
<Ping bind:pingMap/>
<form on:submit={onSubmit}>
	{#await getServerLocations($accessToken)}
		<div>Loading</div>
	{:then data}
		<div class="cards">
			{#each Object.keys(data) as key}
				<input id={data[key]['id']} type="radio" bind:group={location} value={data[key]['id']}>
				<label class="card" for={data[key]['id']}>
					<img src="{$RELATIVE_PATH}/countries/{data[key]['country']}.svg" alt="{data[key]['country']} flag" width="48" height="48" align="left">
					<div>
						{data[key]['city']}, {data[key]['country']}
					<!-- <br> {data[key]['continent']} -->
						{data[key]['id']}
						{#if data[key]['id'] in pingMap}
							<div style="color: {pingColor(pingMap[data[key]['id']])}">{pingMap[data[key]['id']]}ms</div>
						{:else}
							<Loading inline/>
						{/if}
					</div>
				</label>
			{/each}
		</div>
	{:catch err}
		<div>Error: Unable to get servers</div>
	{/await}

	<h2>Game Type</h2>
	<div class="cards">
		<input id="ts-server" type="radio" bind:group={gameType} value="4">
		<label for="ts-server" class="card"><img src="{$RELATIVE_PATH}/icons/gun.svg" width="24" height="24" alt="Rifle Icon">Team Survivor</label>
		<input id="ctf-server" type="radio" bind:group={gameType} value="7">
		<label for="ctf-server" class="card"><img src="{$RELATIVE_PATH}/icons/flag.svg" width="24" height="24" alt="Flag Icon">Capture the Flag</label>
	</div>

	<h2>RCON</h2>
	<div class="cards">
		<label class="card">
			<img src="{$RELATIVE_PATH}/icons/key.svg" width="18" height="18" alt="Key Icon"><input type="text" placeholder="Set RCON" bind:value={rconpassword} required>
		</label>
	</div>

	<h2>Type</h2>
	<div class="cards">
		<input id="public-server" type="radio" bind:group={publicServer} value="public">
		<label for="public-server" class="card"><img src="{$RELATIVE_PATH}/icons/unlock.svg" width="18" height="18" alt="Unlocked Icon">Public</label>
		<input id="private-server" type="radio" bind:group={publicServer} value="private">
		<label for="private-server" class="card"><img src="{$RELATIVE_PATH}/icons/lock.svg" width="18" height="18" alt="Locked Icon">Private</label>
		{#if publicServer == "private"}
			<label style="padding-top: 0; padding-bottom: 0;" class="card">
				<img src="{$RELATIVE_PATH}/icons/key.svg" width="18" height="18" alt="Key Icon"><input type="text" placeholder="Set password" required bind:value={serverpassword}>
			</label>
		{/if}
	</div>

	<div class="cards">
		<label class="card" class:submitLabel={disabled}>
			<input id="submit" type="submit" disabled={disabled}/>
			Create Server
		</label>
	</div>
</form>