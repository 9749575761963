<script lang="ts">
	import { Router, Route, navigate, useLocation } from 'svelte-routing';
	import { accessToken, accessTokenBody, validAccessToken, games, gameTypes, userData, IS_ELECTRON, RELATIVE_PATH, electronUpdate, ipc, currentLobby, isMobile, selectedGameId, onlyPage } from './stores.js';
	import { validateAccessToken } from './Auth.js';
	import {isDef} from './utils.js';
	
	// Components
	import Initialize from "./components/Initialize.svelte";
	import Theme from './components/Theme.svelte';
	import SocketHandler from './components/SocketHandler.svelte';
	import DownloadOrUpdate from './components/DownloadOrUpdate.svelte';
	import ShareButton from './components/ShareButton.svelte';
	import SearchButton from './components/SearchButton.svelte';
	import BotButton from './components/BotButton.svelte';
	import UserButton from './components/UserButton.svelte';
	import Notifications from './components/Notifications.svelte';
	import MobileCheck from './components/MobileCheck.svelte';
	import Sidebar from './components/Sidebar.svelte';
	import Login from './pages/Login.svelte';
	import DiscordLogin from './components/DiscordLogin.svelte';
	import LobbyButton from './components/LobbyButton.svelte';
	import PingSave from './pages/gameserver/PingSave.svelte';
	import ContentContainer from './components/ContentContainer.svelte'
	import Navbar from './components/Navbar.svelte';
	import LoadingGlobalAnimation from './components/LoadingGlobalAnimation.svelte';
	import Dropdown from "./components/Dropdown.svelte";
  	import FeatureFlag from "./components/FeatureFlag.svelte";
	import MentionAutoComplete from './components/MentionAutoComplete.svelte';

	// Site imports
	import Page404 from './pages/Page404.svelte';
	import Admin from './pages/Admin.svelte';
	import Home from './pages/Home.svelte';
	import Forum from './pages/forum/Forum.svelte';
	import ForumSection from './pages/forum/Section.svelte';
	import ForumThread from './pages/forum/Thread.svelte';
	import Game from './pages/Game.svelte';
	import Library from './pages/Library.svelte';
	import League from './pages/League.svelte';
	import Match from './pages/match/Match.svelte';
	import Settings from './pages/Settings.svelte';
	import Team from './pages/Team.svelte';
	import User from './pages/User.svelte';
	import Stats from './pages/Stats.svelte';
	import Bot from './pages/Bot.svelte';
	import Recent from './pages/Recent.svelte';
	import Ping from './pages/Ping.svelte';

	// Electron
	import Titlebar from './components/electron/Titlebar.svelte';

	import GameserverList from './pages/gameserver/List.svelte';
	import GameserverCreate from './pages/gameserver/Create.svelte';
	import GameserverGet from './pages/gameserver/Get.svelte';
	import GameserverBrowser from './pages/gameserver/Browse.svelte';
	import LaunchHandler from './components/electron/LaunchHandler.svelte';
	
	import { onMount } from "svelte";

	if(!window.location.pathname.startsWith('/ping/')) {
		onlyPage.set(false);
	}

	onMount(async () => {
		var userAgent = navigator.userAgent.toLowerCase();
		IS_ELECTRON.set(userAgent.indexOf(' electron/') > -1);

		if($IS_ELECTRON) {
			if ('ENVIRONMENT' != 'local') {
				RELATIVE_PATH.set(".");
			}
			// Tell electron process we're ready
			const {ipcRenderer} = require('electron')
			ipc.set(ipcRenderer);
			$ipc.send('getSetting', 'appPath');
			$ipc.on('update-downloaded', () => {
				if(!isDef($accessToken) || !$validAccessToken) {
					$ipc.send('do-update');
				} else {
					electronUpdate.set(true);
				}
			})
			$ipc.on('login-token', (event, token) => {
				window.localStorage.setItem('accessToken', atob(String(token)));
				accessToken.set(window.localStorage.getItem('accessToken') || null);
				navigate('/', { replace: true });
			})

			try {
				Promise.all([ $games, $gameTypes ]).then(() => {
					$ipc.send('ready', $games, $gameTypes, $userData);
				});
			} catch (exception) {
				$ipc.send('ready')
				console.log(exception);
			}

			// Navigate to login or home
			if (!$validAccessToken) {
				navigate('/login', { replace: true });
			} else {
				navigate('/', { replace: true });
			}
		}
	});

	const basepath = /^\/?[a-zA-Z]+:/.test(window.location.pathname)
		? window.location.pathname.substr(0, window.location.pathname.indexOf(":") + 1)
		: "/";
	
	export let url: string = "";

	const queryString = window.location.search;
	const urlParams = new URLSearchParams(queryString);
	const loginToken = urlParams.get('login_token');
	if (isDef(loginToken) && loginToken.length > 0) {
		window.localStorage.setItem('accessToken', atob(String(loginToken)));
		window.history.replaceState(null, null, window.location.pathname);
	}

	// Pull access token out of localStorage
	accessToken.set(window.localStorage.getItem('accessToken') || null);
	$: validAccessToken.set(validateAccessToken($accessToken));

	$: if ($validAccessToken) {
		accessTokenBody.set(JSON.parse(window.atob($accessToken.split('.')[1])))
		if ($IS_ELECTRON) {
			$ipc.send('setUserID', $accessTokenBody['uid']);
		}
	} else {
		accessTokenBody.set(null)
	}

	// Set localStorage for specific variables
	$: window.localStorage.setItem('currentLobby', JSON.stringify($currentLobby));

	// load from localStorage
	if ($accessToken != null) {
		try {
			currentLobby.set(JSON.parse(window.localStorage.getItem('currentLobby')));
		} catch (exception) {
			currentLobby.set(null);
		}
	}

	let initializing = false;
</script>

<style>
	:global(.loginForm) {
		width: 400px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 20vh;
	}

	:global(.loginForm input, .loginForm button) {
		line-height: 1.6;
		font-weight: 600;
		width: 100%;
		display: block;
	}

	:global(.loginForm > a) {
		margin-top: 1em;
		text-align: center;
		display: block;
	}

	@media (max-width: 767px) {
		:global(.loginForm) {
			width: 100%;
		}
	}

	:global(.active) {
		font-weight: 600;
	}
	:global(.error) {
		color: red;
		font-weight: 600;
		text-align: center;
	}
	:global(.right) {
		float: right;
	}
	:global(.right-align) {
		text-align: right;
	}
	:global(.left) {
		float: left;
	}
	:global(.border) {
		border: var(--input-border-width) solid var(--input-border-color);
		border-radius: 3px;
	}
	:global(.button) {
		border: var(--input-border-width) solid var(--input-border-color);
		border-radius: 2px;
		text-align: center !important;
		padding: 0.5em;
		margin: 0.5em;
		cursor: pointer;
	}
	:global(.badge) {
		background-color: #fa3e3e;
		border-radius: 1em;
		color: white;
		
		padding: 1px 3px;
		font-size: 12px;
		
		position: absolute; /* Position the badge within the relatively positioned button */
		top: 0;
		right: 0;
	}
</style>

<LoadingGlobalAnimation/>

<!-- This needs to finish before displaying the app -->
<Initialize on:finishInitialize={() => initializing = false}/>

<!-- Global Components here -->
<MobileCheck/>
<Theme/>

{#if history !== null && !initializing}
	<Router url={url} basepath={basepath}>
		{#if $IS_ELECTRON}
			<Titlebar/>
			<LaunchHandler/>
		{/if}

		{#if !$onlyPage && (!$IS_ELECTRON || $validAccessToken)}
			<MentionAutoComplete/>
			<SocketHandler/>
			<Sidebar/>

			<!-- Top Nav -->
			<Navbar>
				<svelte:fragment slot="left">
					<LobbyButton/>
				</svelte:fragment>

				<svelte:fragment slot="right">
					{#if !$isMobile}
						<BotButton/>
						<ShareButton/>
						<DownloadOrUpdate/>
						<SearchButton/>
					{:else}
						<SearchButton/>
						<Dropdown label="more" icon="more" iconOnly={true}>
							<BotButton/>
							<ShareButton/>
						</Dropdown>
					{/if}

					{#if !$validAccessToken}
						<DiscordLogin/>
					{:else}
						{#if 'ENVIRONMENT' != 'local'}
							<PingSave/>
						{/if}
						{#if !$isMobile}
							<FeatureFlag flag="notifications">
								<Notifications/>
							</FeatureFlag>
						{/if}
						<UserButton/>
						{#if $isMobile}
							<FeatureFlag flag="notifications">
								<Notifications/>
							</FeatureFlag>
						{/if}
					{/if}
				</svelte:fragment>
			</Navbar>
		{/if}

		<ContentContainer>
			<!-- Authed -->
			<Route path="/" component={Home}/>
			<Route path="settings" component={Settings}/>

			<!-- Public -->
			<Route path="bot" component={Bot}/>
			<Route path="leagues/:gameName" component={Game}/>
			<Route path="leagues/:gameName/:gameType/:season" component={League}/>
			<Route path="match/:matchId" component={Match}/>
			<Route path="match/:matchId/:page" component={Match}/>			
			<Route path="recent/:gameName" component={Recent}/>
			<Route path="team/:teamSlug" component={Team}/>
			<Route path="team/:teamSlug/:page" component={Team}/>
			<Route path="user/:username" component={User}/>

			<!-- Token Pages -->
			<Route path="ping/:token" component={Ping}/>

			<!-- Electron -->
			{#if $IS_ELECTRON}
				<Route path="login" component={Login}/>
			{/if}

			<!-- Gameserver -->
			<!-- TODO: Use Page components here -->
			<Route path="gameservers" component={GameserverList}/>
			<Route path="gameservers/create" component={GameserverCreate}/>
			<Route path="gameservers/browse/:gameName" component={GameserverBrowser}/>
			<Route path="gameservers/:id" component={GameserverGet}/>

			<!-- Forum -->
			<!-- TODO: Use Page components here -->
			<Route path="forum" component={Forum}/>
			<Route path="forum/:gameSlug/:sectionSlug" component={ForumSection}/>
			<Route path="forum/:gameSlug/:sectionSlug/:threadSlug" component={ForumThread}/>

			<!-- admin in user.role -->
			<Route path="admin" component={Admin}/> <!-- reroute with table = dashboard -->
			<Route path="admin/:table" component={Admin}/> <!-- reroute with id = all -->
			<Route path="admin/:table/:id" component={Admin}/>
			<Route component={Page404}/>

			<!-- Feature Flagged -->
			<Route path="library" component={Library}/> 
			<Route path="stats/:gameSlug" component={Stats}/>
		</ContentContainer>
	</Router>
{/if}
