<script>
    import {tooltip} from '../../components/Tooltip.ts';
    import {games, IS_ELECTRON, ipc, servers, serverPings, likedServers, RELATIVE_PATH, validAccessToken, accessToken, panePage} from '../../stores.js';
    import {pingColor, countryMap} from '../gameserver/data.js';
    
    import ClickToCopy from '../../components/ClickToCopy.svelte';
    import GameLaunch from '../../components/electron/GameLaunch.svelte';
    import Loading from '../../components/Loading.svelte';
    import { onDestroy, onMount } from 'svelte';
    import Page from '../../components/Page.svelte';
    import {isDef, getGameType, ipOnly} from '../../utils.js';

    import { PanePages, setPanePage } from '../../components/PanelLayout.svelte';

    import { fade} from 'svelte/transition';

    import localforage from 'localforage';


    export let gameName;

    let ready = false;
    let loading = false;

    // Filters
    let favoritesOnly = false;
    let showEmpty = false;
    let includeBots = true;
    let visibilty = 'all';
    const gameTypeOrder = [7, 4, 2, 0, 1, 3, 5, 6, 8, 9, 10, 11];
    let gameTypes = gameTypeOrder.reduce((obj, x) => {
        obj[x] = true;
        return obj;
    }, {});

    $: if (ready) localforage.setItem('browser:favoritesOnly', favoritesOnly)
    $: if (ready) localforage.setItem('browser:empty', showEmpty)
    $: if (ready) localforage.setItem('browser:includeBots', includeBots)
    $: if (ready) localforage.setItem('browser:visibility', visibilty)
    $: if (ready) localforage.setItem('browser:gameTypes', gameTypes)

    $: game = $games.filter(game => game.slug === gameName)[0] || null;
    $: gameId = game && game['id'];
    $: getServers(gameId, lastRefresh, showEmpty, includeBots, visibilty, $likedServers);

    let lastRefresh = new Date();

    let autoRefresh;
    let timeToRefresh = 60;
    let delayedPing;

    onMount(async () => {
        autoRefresh = setInterval(() => {
            timeToRefresh--;
            if (timeToRefresh == 0) {
                refresh();
            }
        }, 1000);

        // localforage cache
        const cachedServerPings = await localforage.getItem('serverPings');
        if (isDef(cachedServerPings)) {
            serverPings.set(cachedServerPings);
        }
        favoritesOnly = await localforage.getItem('browser:favoritesOnly') || false;
        showEmpty = await localforage.getItem('browser:empty') || false;
        includeBots = await localforage.getItem('browser:includeBots') || false;
        visibilty = await localforage.getItem('browser:visibility') || 'all';
        gameTypes = await localforage.getItem('browser:gameTypes') || gameTypeOrder.reduce((obj, x) => {
            obj[x] = true;
            return obj;
        }, {});

        if ($IS_ELECTRON) {
            $ipc.on('ping',(_, pings) => {
                if (pings && pings.length > 0) {
                    const split = pings.split(',')

                    var updated = false;
                    var split2, address, ping;
                    split.forEach((p) => {
                        split2 = p.split(';')
                        address = ipOnly(split2[0])
                        ping = parseInt(split2[1])
                        if (ping < 600) {
                            $serverPings[address] = ping;
                            updated = true
                        }
                    });
                    if (updated) {
                        localforage.setItem('serverPings', $serverPings);
                    }
                }
            });
        }

        ready = true;

        await getlikes();
    });

    onDestroy(() => {
        clearInterval(autoRefresh);
    });

    async function getlikes() {
        if (!$validAccessToken) {
            likedServers.set({})
            return;
        }

        const response = await fetch(`http://localhost:8000/api/like/servers`, {
            headers: {
                'Authorization': `Bearer ${$accessToken}`
            }
        })
        if (response.ok) {
            likedServers.set(await response.json());
        }
    }


    async function getServers(gameId, _, showEmpty, includeBots, visibilty, likes) {
        let requestUrl = `https://ftwgl.net/api/master/v2/servers/${gameId}?players=${showEmpty ? 0 : 1}`;
        if (!includeBots) {
            requestUrl += '&bots=0';
        }

        if (visibilty == 'public') {
            requestUrl += '&password=0';
        } else if (visibilty == 'private') {
            requestUrl += '&password=1';
        }

        let include_servers = []
        if (likes && Object.keys(likes).length > 0) {
            include_servers.push(...Object.keys(likes));
        }

        if ($panePage && $panePage.page == PanePages.GameServer) {
            include_servers.push($panePage.data.serverAddress);
        }

        if (include_servers.length > 0) {
            requestUrl += '&include=' + include_servers.join(',');
        }

        loading = true;
        const response = await fetch(requestUrl)
        if (response.ok) {
            if (isDef(delayedPing)) clearTimeout(delayedPing);

            const json = await response.json()
            if (isDef($panePage) && $panePage.page == PanePages.GameServer && $panePage.data.serverIndex > Object.keys(json).length - 1) {
                panePage.set(null);
            }
            if (json) {
                servers.set(Object.values(json['servers']));

                if ($IS_ELECTRON) {
                    getPings()
                    delayedPing = setTimeout(() => {
                        getPings();
                        if (isDef(delayedPing)) clearTimeout(delayedPing);
                    }, 15000); // Second request after 15 seconds
                }
            }
        }
        loading = false;
    }

    function refresh(_) {
        lastRefresh = null;
        lastRefresh = new Date();
        timeToRefresh = 60;
    }

    function getPings() {
        const s = $servers.filter(s => !(s['address'] in $serverPings) || $serverPings[s['address']] >= 600).map(s => s['address']).join(',');
        $ipc.send('ping', gameId, s);
    }

    function compare(a, b) {
        if (gameTypeOrder.indexOf(a) !== -1 && gameTypeOrder.indexOf(b) !== -1) {
            // both elements are in the reference list, so sort based on their position in the list
            return gameTypeOrder.indexOf(a) - gameTypeOrder.indexOf(b);
        } else if (gameTypeOrder.indexOf(a) !== -1) {
            // a is in the reference list, so it should come before b
            return -1;
        } else if (gameTypeOrder.indexOf(b) !== -1) {
            // b is in the reference list, so it should come before a
            return 1;
        } else {
            // both elements are not in the reference list, so sort based on their values
            return a - b;
        }
    }
</script>

<style>
    .likes:nth-child(even) {
        background-color: rgba(255,192,203,0.1)
    }
    .likes:nth-child(odd) {
        background-color: rgba(255,192,203,0.2)
    }
    strong {
        font-weight: 600;
    }
</style>

<Page title="Server Browser">
    {#if gameId && ready}
        {#if $servers == null}
            <Loading/>
            <div class="skeleton" style="height: 35px"></div>
            <div class="skeleton" style="height: calc(100% - 100px);"></div>
        {:else}
            {#if loading}
                <Loading/>
            {/if}
            <div style="display: flex; flex-direction: row; justify-content: space-between;">
                <span style="margin: 0.5em;">
                    <span class="button-group">
                        <input id="favoritesOnly" type="checkbox" bind:checked={favoritesOnly} hidden/>
                        <label for="favoritesOnly" class="tag" use:tooltip={"Favorites"}><strong>❤️</strong></label>

                        <input id="showEmpty" type="checkbox" bind:checked={showEmpty} hidden/>
                        <label for="showEmpty" class="tag" use:tooltip={"Empty"}><strong>0️⃣</strong></label>

                        <input id="includeBots" type="checkbox" bind:checked={includeBots} hidden/>
                        <label for="includeBots" class="tag" use:tooltip={"Bots"}><strong>🤖</strong></label>
                    </span>

                    <span class="button-group">
                        <input type="radio" bind:group={visibilty} value="all" checked id="server-all"/><label class="inline" for="server-all"><strong>All</strong></label><input id="server-public" type="radio" bind:group={visibilty} value="public"/><label for="server-public" class="inline" use:tooltip={"Public"}><strong>🔓</strong></label><input id="server-private" type="radio" bind:group={visibilty} value="private"/><label for="server-private" class="inline" use:tooltip={"Private"}><strong>🔒</strong></label>
                    </span>

                    <span class="button-group">
                        {#each [...new Set($servers.map(s => parseInt(s.config['g_gametype'])))].sort(compare) as i}
                            <input id="gt-{i}" type="checkbox" bind:checked={gameTypes[i]} use:tooltip={"Click to show only liked servers"} value={i}/><label for="gt-{i}" class="inline" use:tooltip={getGameType(i)[0]}><strong>{getGameType(i)[1]}</strong></label>
                        {/each}
                    </span>
                </span>

                <span>
                    <button on:click={refresh} use:tooltip={"Click to refresh"}><strong>Refresh</strong> ({timeToRefresh}s)</button>
                </span>
            </div>
            {#if $servers['length']}
                <div class="table-wrapper overflow-scroll">
                    <table>
                        <thead>
                            <tr class="headings nowrap">
                                {#if $IS_ELECTRON}
                                    <th>&nbsp</th>
                                {/if}
                                <th>Name</th>
                                <th>Country</th>
                                <th>Players</th>
                                <th>Map</th>
                                <th>Game Type</th>
                                <th>Address</th>
                                <th>Bots</th>
                                {#if $IS_ELECTRON}
                                    <th>Ping</th>
                                {/if}
                            </tr>
                        </thead>
                        <tbody>
                            {#key $likedServers}
                                {#each $servers.sort((a,b) => {
                                    if(isDef($likedServers)) {
                                        if (a['address'] in $likedServers && b['address'] in $likedServers)
                                            return a['number_players'] > b['number_players'] ? -1 : 1
                                        else if (!(a['address'] in $likedServers) && !(b['address'] in $likedServers)) {
                                            return a['number_players'] > b['number_players'] ? -1 : 1
                                        } else {
                                            return a['address'] in $likedServers ? -1 : 1
                                        }
                                    } else {
                                        return a['number_players'] > b['number_players'] ? -1 : 1
                                    }
                                }).filter(s => s.config['g_gametype'] in gameTypes && gameTypes[s.config['g_gametype']]) as server, i}
                                    {@const liked = (isDef($likedServers) && typeof($likedServers) == 'object' && server['address'] in $likedServers && $likedServers[server['address']]) || server['liked']}
                                    {#if !favoritesOnly || (favoritesOnly && liked)}
                                        <tr class="nowrap" class:likes={liked}
                                                on:click={setPanePage({page: PanePages.GameServer, data: {serverAddress: server['address'], ping: $serverPings[server['address']], ...{game: game}}})}>
                                            {#if $IS_ELECTRON}
                                                <td>
                                                    <GameLaunch gameId={gameId} gameName={game.game_name} address={server['address']}/>
                                                </td>
                                            {/if}
                                            <td style="text-align: left;">
                                                <span use:tooltip={"Click for more info"}>{#if liked}❤️{:else}➕{/if}</span> <strong>{server['config']['sv_hostname'].replace(/\^+\d/g, '')}</strong>
                                            </td>
                                            <td>
                                                {#key server}
                                                    <img width="18" src="{$RELATIVE_PATH}/countries/{server.country}.svg"
                                                    alt={server.country}
                                                    use:tooltip={`${isDef(server.city) ? server.city + ', ' : ''}${countryMap[server.country]}`}/>
                                                {/key}
                                            </td>
                                            <td class="monospace"><strong>{server['number_players']}</strong> / {server['config']['sv_maxclients']}</td>
                                            <td>{server['config']['mapname']}</td>
                                            <td>
                                                <span use:tooltip={getGameType(parseInt(server['config']['g_gametype']))[0]}>
                                                    {getGameType(parseInt(server['config']['g_gametype']))[1]}
                                                </span>
                                            </td>
                                            <td>{server['address']} <ClickToCopy text={server['address']} buttonOnly={true}/></td>
                                            <td class="monospace">{server['number_bots']}</td>
                                            {#if $IS_ELECTRON}
                                                <td class="monospace">
                                                    {#if !(ipOnly($servers[i]['address']) in $serverPings) || $serverPings[ipOnly($servers[i]['address'])] == null || $serverPings[ipOnly($servers[i]['address'])] == undefined && $serverPings[ipOnly($servers[i]['address'])] > 600}
                                                        <span><Loading inline={true}/></span>
                                                    {:else}
                                                        <span in:fade|global style="color: {pingColor($serverPings[ipOnly($servers[i]['address'])])}">{$serverPings[ipOnly($servers[i]['address'])]}</span>
                                                    {/if}
                                                </td>
                                            {/if}
                                        </tr>
                                    {/if}
                                {/each}
                            {/key}
                        </tbody>
                    </table>
                </div>
            {/if}
        {/if}
    {/if}
</Page>
