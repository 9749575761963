<script>
  import { Link } from 'svelte-routing';
  import { RELATIVE_PATH } from "../stores";

  export let item = 'page';
</script>

<style>
  .box {
    width: 100%;
    margin: unset;
  }
  .box p {
    text-align: center;
    font-size: 1.25em;
  }
</style>

<div class="box">
  <img src={`${$RELATIVE_PATH}/icons/404.svg`} alt="404 Not Found" width="250"/>
  <p>We couldn't find the {item} you're looking for.</p>
  <Link to='/'>Go Home</Link>
</div>
