<script>
    import { titlecase } from '../utils.js';

    export let user_id = undefined;

    async function getStatsGamesPlayed(userId) {
        const response = await fetch(`http://localhost:8000/api/stats/user/${userId}/games-played`)
        if (response.ok) {
        return await response.json();
        }
    }
</script>

<style>
    h2 {
        margin: 0.5em;
    }
</style>

{#if user_id}
    {#await getStatsGamesPlayed(user_id)}
        <div class="skeleton" style="height: 112px"></div>
    {:then gp}
        <h2>Games Played</h2>
        <div class="box-container overflow-scroll">
            {#each Object.keys(gp) as key}
            <div class="card inline-card">
                <strong>{titlecase(key)}</strong>
                <h2>{gp[key]}</h2>
            </div>
            {/each}
        </div>
    {/await}
{/if}
