<script>
    import {RELATIVE_PATH, ipc} from '../../stores.js';

    async function minimize() {
        await $ipc.send('minimize');
    }
    
    async function maximize() {
        await $ipc.send('maximize');
    }

    async function close() {
        await $ipc.send('close');
    }
</script>

<style>
    .titlebar {
		background-color: var(--dark-color);
		width: 100%;
        color: #fff;
        position: fixed;
        top: 0;
        height: 25px;
        user-select: none;
        -webkit-app-region: drag;
        margin: none;
        border-top: none;
        display: grid;
        grid-auto-flow: column;
        justify-content: space-between;
	}

    .titlebar .image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 72px;
        height: 25px;
    }

    .titlebar .image-wrapper img {
        display: block;
    }

    button {
        -webkit-app-region: no-drag;
        background: none;
        border: none;
        height: var(--electron-titlebar-height);
        color: #ccc;
        padding: 0 0.65em;
        cursor: pointer;
        margin: 0px;
        min-height: unset;
    }
    button:hover {
        background-color: rgba(255,255,255,0.1);
        color: white;
    }
    button:active {
        background-color: rgba(255,255,255,0.2);
    }
    .buttons {
        display: flex;
        align-items: center;
        text-align: center;
    }
</style>

<!-- <div class="resize"></div> -->
<div class="titlebar">
    <span class="image-wrapper">
    </span>
    <span class="buttons">
        <button on:click={() => minimize()}>
            <svg aria-hidden="false" width="12" height="12" viewBox="0 0 12 12"><rect fill="currentColor" width="10" height="1" x="1" y="6"></rect></svg>
        </button>
        <button on:click={() => maximize()}>
            <svg aria-hidden="false" width="12" height="12" viewBox="0 0 12 12"><rect width="9" height="9" x="1.5" y="1.5" fill="none" stroke="currentColor"></rect></svg>
        </button>
        <button on:click={() => close()}>
            <svg aria-hidden="false" width="12" height="12" viewBox="0 0 12 12"><polygon fill="currentColor" fill-rule="evenodd" points="11 1.576 6.583 6 11 10.424 10.424 11 6 6.583 1.576 11 1 10.424 5.417 6 1 1.576 1.576 1 6 5.417 10.424 1"></polygon></svg>
        </button>
    </span>
</div>
