<script>
    import {selectedGameId} from '../../stores.js'
    import Loading from '../Loading.svelte';
    import OnlineIndicator from '../OnlineIndicator.svelte';
    import {tooltip} from '../Tooltip.ts';
    import SortableTable from '../SortableTable.svelte';
    import {isDef} from '../../utils.js';

    let gameModeID = null;

    async function getGameModes() {
        const response = await fetch(`http://localhost:8000/api/games/${$selectedGameId}/modes`);
        if (response.ok) {
            let data = await response.json();
            gameModeID = data[0].id
            return data
        }
    }

    async function getStats(gameModeID) {
        const response = await fetch(`http://localhost:8000/api/stats/maps?game_id=${$selectedGameId}&game_mode_id=${gameModeID}`);
        if (response.ok) {
            return await response.json();
        }
    }

    function statisticsToHeadings(statistics) {
        let headings = statistics.map(stat => {
            return {
                col: stat.acronym,
                tooltip: stat.stat
            }
        });
        headings.unshift({
            col: 'GP',
            tooltip: 'Games Played'
        });
        headings.unshift({
            col: 'Map',
            tooltip: 'Map'
        });
        console.log("Headings", headings)
        return headings
    }

    function getRows(mapStats) {
        let rows = [];
        for (const [map_id, map] of Object.entries(mapStats['maps'])) {
            let row = [
                map.map,
                map.games_played
            ];
            for (const stat of mapStats['statistics']) {
                row.push(map[stat.id])
            }
            rows.push(row);
        }
        console.log("Rows", rows)
        return rows;
    }
</script>

<h2>Maps</h2>
{#await getGameModes()}
    <Loading/>
    <div class="skeleton" style="height: 180px"/>
    <div class="skeleton" style="height: 36px; margin-top: 2em;"/>
{:then gameModes}
    <div style="display:flex; margin-bottom: 3px;">
        <span style="margin-left: auto; float:right;">
            <span class="button-group">
                {#each gameModes as gameMode}
                    {#if isDef(gameMode.statistic_ids) && gameMode.statistic_ids.length > 0}
                        <input id={`gm:${gameMode.id}`} type="radio" bind:group={gameModeID} value={gameMode.id} hidden/>
                        <label for={`gm:${gameMode.id}`} class="tag" use:tooltip={gameMode['name']}>{gameMode['abbreviation']}</label>
                    {/if}
                {/each}
            </span>
        </span>
    </div>
    {#await getStats(gameModeID)}
        <div class="skeleton" style="height: 290px;"/>
    {:then data}
        <div class="table-wrapper overflow-scroll">
            <SortableTable headings={statisticsToHeadings(data.statistics)}
                           rows={getRows(data)} sortCol=1/>
        </div>
    {/await}
{/await}
