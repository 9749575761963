<script>
    import {tooltip} from './Tooltip'
    import {RELATIVE_PATH, leagueUsers} from '../stores.js';
    import { isDef } from '../utils.js'

    export let avatar = null;
    export let userId = null;
    export let small = false;
    export let inline = false;
    export let online = false;
    export let glow = false;
    export let noPresence = false;
    export let username = null;

    const colors = [
        '#00916E',
        '#191970',
        '#4D6CFA',
        '#2E5266',
        '#A30B37',
        '#725AC1'
    ];

    function getStyle(avatar, userId) {
        if (isDef(avatar)) {
            if (small) {
                return `background-image: url(FTW_STATIC_HOST/avatars/${userId}_32.webp)`
            } else {
                return `background-image: url(FTW_STATIC_HOST/avatars/${userId}_64.webp)`
            }
        }

        const color = isDef(userId) ? colors[userId % colors.length] : 'rgba(0, 0, 0, 0)';
        if (isDef(color)) {
            return `background-image: url("${$RELATIVE_PATH}/bullets.svg"); background-color: ${color};`;
        } else {
            return `background-image: url("${$RELATIVE_PATH}/bullets.svg")`;
        }
    }

    $: style = getStyle(avatar, userId);

    $: isLeagueUser = userId && $leagueUsers && $leagueUsers[userId];
</script>

<style>
    div {
        position: relative; /* Needed for absolute positioning of the SVG */
        width: var(--sidebar-button);
        height: var(--sidebar-button);
        border-radius: 50%;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        display: inline-block;
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.5);
        transition: box-shadow 0.3s;
    }

    div:hover {
        box-shadow: 0 1px 5px rgba(0, 0, 0, 0.8);
    }

    .glow {
        outline: 1px dashed var(--link-color);
        outline-offset: 3px;
    }

    .small {
        width: 1.2em;
        height: 1.2em;
    }

    .inline {
        display: inline-block;
        vertical-align: sub !important;
        margin: 0;
        padding: 0;
    }
    
    .status-indicator {
        position: absolute;
        bottom: 0px; /* Adjust as needed */
        right: 0px; /* Adjust as needed */
        width: 10px; /* Size of the status circle */
        height: 10px; /* Size of the status circle */
    }

    .small .status-indicator {
        bottom: -1px; /* Adjust as needed */
        right: -1px; /* Adjust as needed */
        width: 6px; /* Size of the status circle */
        height: 6px; /* Size of the status circle */
    }

    div.online .status-indicator circle {
        fill: lime;
    }

    div .status-indicator circle {
        fill: gray;
    }
</style>

<div class:small class:inline class:glow class:online={online && !noPresence &&!isLeagueUser} class:offline={!online && !noPresence && !isLeagueUser} {style} use:tooltip={username}>
    {#if !isLeagueUser && !noPresence}
        <svg class="status-indicator" viewBox="0 0 10 10" xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="5" r="5" />
        </svg>
    {/if}
</div>
