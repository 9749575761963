<script>
    import {IS_ELECTRON, RELATIVE_PATH, electronUpdate, ipc, isMobile, userKV} from '../stores.js';
    import {tooltip} from './Tooltip.ts';

    async function getDownloadLink() {
        const response = await fetch('http://localhost:8000/api/download')

        if (response.status === 200) {
            return await response.json()
        } else {
            const error = await response.json()
            throw error.detail
        }
    }

    function updateApp(e) {
        e.preventDefault();
        $ipc.send('do-update');
    }
</script>

{#if !$IS_ELECTRON}
    {#await getDownloadLink()}
        ...
    {:then data}
        <a href={data['url']} download>
            <button class="border" use:tooltip={`Download for ${data['os']}`}>
                <span class="nav-button">
                    <img src="{$RELATIVE_PATH}/icons/download.svg" alt="Download App" width="16" height="22"/>
                    {#if !$isMobile && !$userKV.hideButtonLabels}
                        Download
                    {/if}
                </span>
            </button>
        </a>
    {:catch err}
        <span use:tooltip={err}>
            <button class="border" disabled>
                <span class="nav-button" style="opacity: 0.6">
                    <img src="{$RELATIVE_PATH}/icons/download.svg" alt="Download App" width="16" height="22"/>
                    {#if !$isMobile && !$userKV.hideButtonLabels}
                        Download
                    {/if}
                </span>
            </button>
        </span>
    {/await}
{:else if $electronUpdate === true}
    <button class="border" use:tooltip={"Update App"} on:click={updateApp}>
        <span class="nav-button">
            <img src="{$RELATIVE_PATH}/icons/update.svg" alt="Update App" width="16" height="22" style="translate: rotate(90deg) !important;"/>
            {#if !$isMobile}
                Update
            {/if}
        </span>
    </button>
{/if}
