<script>
    import Chart from '../Chart.svelte';
    import Filters from "./Filters.svelte";
    import Loading from "../Loading.svelte";
    import {applyFiltersToURL} from './Filters.svelte';
    import { isDef } from "../../utils";

    export let userIds = null;
    export let username = null;

    let gameMode = null;
    let filters = null;

    async function getStats(username, gameMode, filters) {
        if (!isDef(username) || !isDef(gameMode) || !isDef(filters)) {
            return;
        }

        let request_url = `http://localhost:8000/api/user/${username}/stats?f=1`

        request_url = applyFiltersToURL(request_url, null, gameMode, filters);
    
        const response = await fetch(request_url);
        if (response.ok) {
            return await response.json();
        }
    }

    function statChart(stat, stats) {
        const letter_grades = stats.stats_letter_grades.find(s => s.stat == stat)
        if (letter_grades == undefined || letter_grades['95p'] == null) {
            console.log(stat, "undefined")
            return {}
        }
        let max_stat = letter_grades['95p'].toFixed(2);

        if (stat == 'CP') {
            max_stat = stats.stats_avg_all_players.find(s => s.stat == stat).avg.toFixed(2) * 2
        }

        let data = [];

        if (stats.stats_30_days[0].avg) {
            data.push({
                value: stats.stats_30_days.find(s => s.stat == stat).avg,
                name: '30 Days',
                title: {
                    offsetCenter: ['0%', '0%']
                },
                detail: {
                    offsetCenter: ['0%', '-10%']
                }
            })
        }
        if (stats.stats_all_time[0].avg) {
            data.push({
                value: stats.stats_all_time.find(s => s.stat == stat).avg,
                name: 'All Time',
                title: {
                    offsetCenter: ['40%', '0%']
                },
                detail: {
                    offsetCenter: ['40%', '-10%']
                }
            })
        }
        if (stats.stats_avg_all_players[0].avg) {
            data.push({
                value: stats.stats_avg_all_players.find(s => s.stat == stat).avg,
                name: "All Players",
                title: {
                    offsetCenter: ['-40%', '0%']
                },
                detail: {
                    offsetCenter: ['-40%', '-10%']
                }
            })
        }

        return {
            title: {
                text: stat,
                show: true,
                left: 'center',
                top: 'center',
                width: 20,
                fontSize: 15,
                textStyle: {
                    color: '#fff'
                },
                offsetCenter: ['-20%', '-20%']
            },
            series: [
                {
                    type: 'gauge',
                    startAngle: 180,
                    endAngle: 0,
                    center: ['50%', '75%'],
                    radius: '90%',
                    min: 0,
                    max: max_stat,
                    splitNumber: 8,
                    axisLine: {
                        lineStyle: {
                        width: 6,
                        color: [
                            [0.2, '#FF6E76'],
                            [0.4, '#FEA66B'],
                            [0.6, '#FDDD60'],
                            [0.8, '#7CFFB2'],
                            [1.0, '#58D9F9'],
                        ]
                        }
                    },
                    pointer: {
                        icon: 'path://M12.8,0.7l12,40.1H0.7L12.8,0.7z',
                        length: '12%',
                        width: 20,
                        offsetCenter: [0, '-60%'],
                        itemStyle: {
                            color: 'auto'
                        }
                    },
                    axisTick: {
                        length: 12,
                        lineStyle: {
                        color: 'auto',
                        width: 2
                        }
                    },
                    splitLine: {
                        length: 20,
                        lineStyle: {
                        color: 'auto',
                        width: 5
                        }
                    },
                    axisLabel: {
                        color: '#fff',
                        fontSize: 16,
                        distance: -50,
                        rotate: 'tangential',
                        formatter: function (value) {
                            if (value === max_stat) {
                                return 'A';
                            } else if (value === max_stat * .75) {
                                return 'B';
                            } else if (value === max_stat * .5) {
                                return 'C';
                            } else if (value === max_stat * 0.25 ) {
                                return 'D';
                            } else if (value === 0) {
                                return 'F';
                            }else {
                                return '';                                
                            }
                        }
                    },
                    title: {
                        offsetCenter: [0, '-20%'],
                        fontSize: 10,
                        textStyle: {
                            color: '#fff'
                        }
                    },
                    detail: {
                        fontSize: 15,
                        offsetCenter: [0, '-45%'],
                        valueAnimation: true,
                        formatter: function (value) {
                            let newValue = value % 1 !== 0 ? value.toFixed(2) : value;
                            if (newValue > 1000) {
                                newValue /= 1000
                                return `${newValue.toFixed(2)}K`;
                            }
                            return newValue;
                        },
                        color: 'auto'
                    },
                    data: data
                }
            ]
        };
    }
</script>

<h2>Stats</h2>

<Filters {userIds}
        on:gameMode={(gm) => gameMode = gm.detail}
        on:filterChanged={(f) => filters = f.detail}/>

{#await getStats(username, gameMode, filters)}
    <Loading/>
    <div class="skeleton" style="height: 300px;"></div>
    <div class="skeleton" style="height: 300px;"></div>
    <div class="skeleton" style="height: 300px;"></div>
{:then stats}
    {#if isDef(stats)}
        <div class="grid center" style="margin-left: auto; margin-right: auto; float: center;">
            {#each stats.stats_30_days as stat}
                {#if Object.keys(statChart(stat.stat, stats)).length > 0}
                    <div class="card inline-card col-4 center" style="min-width: 220px; margin: 5px; padding: 5px;">
                        <Chart options={statChart(stat.stat, stats)}/>
                    </div>
                {/if}
            {/each}
        </div>
    {/if}
{/await}
