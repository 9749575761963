<script>
    import {accessTokenBody} from '../stores.js';

    $: admin = $accessTokenBody && $accessTokenBody.r == 'admin';
</script>

{#if admin}
    <slot></slot>
{:else}
    <slot name="else"></slot>
{/if}
