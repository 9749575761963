<script lang="ts">
    import {onMount, onDestroy} from 'svelte';
    import {accessToken, socket, RELATIVE_PATH, games, lobbyMessages, userData, currentLobby, ticket} from '../../stores.js';
    import Loading from '../../components/Loading.svelte';
    import Avatar from '../../components/Avatar.svelte';
    import LobbyReadyButton from './LobbyReadyButton.svelte';
    import {getServerLocations, pingColor, serverIdMap} from '../gameserver/data.js';
    import { navigate } from 'svelte-routing';
    import GameServer from '../../components/match/lobby/GameServer.svelte';
    import { slugify } from '../../utils.js';
    
    export let match: any;
    export let leader;

    let matchId: number;
    $: matchId = match.match.id
    let teams = [];
    let lastGameServerUpdate = null;
    let gameServerRefreshInterval = null;
    let matchServers = [];

    $: getServers(matchId) // initial load

    async function getTeam(teamName) {
        const response = await fetch('http://localhost:8000/api/team/' + slugify(teamName));
        if (response.ok) {
            return await response.json()
        }
    }

    async function getServers(matchId) {
        const response = await fetch(`http://localhost:8000/api/match/${matchId}/gameservers`, {
            headers: {
                'Authorization': 'Bearer ' + $accessToken
            }
        })
        if (response.ok) {
            matchServers = await response.json()
        }
    }

    enum LobbyState {
        NOT_READY = 1,
        ALL_READY = 2,
        SPAWNING = 3,
        LIVE = 4,
    }

    let selectedServer = null;

    let averagePings = {}; // all player average
    let teamPings = {}; // team averages team_id -> dcid -> ping

    let dcids: Array<string> = [];
    let teamDcids: { [teamId: number] : string } = {};
    
    let teamReady: { [teamId: number] : boolean } = {};
    let lobbyState: LobbyState = LobbyState.NOT_READY;

    let lobbyPlayers = [];

    let selectedTeamDcids: Array<string>;
    $: selectedTeamDcids = [...new Set(Object.values(teamDcids))];
    $: console.log(dcids);

    onMount(async () => {
        lobbyPlayers = [];

        $socket.emit('lobbyp', matchId, {p: 'join-lobby'});

        teams = await Promise.all(match.teams.map(async (team) => await getTeam(team.team_name)));

        currentLobby.set({'match': match})

        gameServerRefreshInterval = setInterval(async () => {
            const d = Date.now();
            if (lastGameServerUpdate == null || d - lastGameServerUpdate > 2000) {
                await getServers(matchId);
                lastGameServerUpdate = d;
            }
        }, 2000);
    })

    onDestroy(async () => {
        clearInterval(gameServerRefreshInterval);
    })

    function quitLobby() {
        if ($socket && $socket !== null)
            $socket.emit('lobbyp', matchId, {p: 'leave-lobby'});
            
        lobbyPlayers = [];

        currentLobby.set(null)

        navigate(`/match/${matchId}`)
    }

    $: handleLobbyMessage($lobbyMessages);

    function handleLobbyMessage(_) {
        while ($lobbyMessages.length > 0) {
            const message = $lobbyMessages.pop();
            console.log(message)
            switch(message.p) {
                case 'ping':
                    console.log(message.d.dcids)
                    averagePings = message.d.avg;
                    teamPings = message.d.teams;
                    dcids = message.d.dcids;
                    console.log(dcids)
                    break;
                case 'ss':
                    for (const [teamId, dcid] of Object.entries(message.d)) {
                        teamDcids[teamId] = dcid;
                    }
                    break;
                case 'ready':
                    for (const [teamId, ready] of Object.entries(message.d)) {
                        teamReady[teamId] = ready;
                    }
                    break;
                case 'lobby-players':
                    lobbyPlayers = [...message.d];
                    break;

                case 'enter-lobby':
                    lobbyPlayers = [...lobbyPlayers, message.d];
                    break;

                case 'leave-lobby':
                    lobbyPlayers = lobbyPlayers.filter(id => id !== message.d);
                    break;

                case 'lobby-state':
                    lobbyState = message.d as LobbyState;
                    break;
                case 'connect':
                    ticket.set(message.d.ticket);
                    console.log(message)
                    break;
            }
        }
    }

    $: console.log(selectedServer)
    $: $socket && selectedServer && $socket.emit('lobbyp', matchId, {p: 'ss', d: selectedServer})
</script>

<style>
    .players {
        display: flex;
    }
    .players div {
        flex: 50%;
    }
    .player {
        font-weight: 600;
        /* border: 2px solid green; */
        display: flex;
        align-items: center;
        margin: 0.25em;
        /* justify-content: center; */
    }

    .offline {
        opacity: 0.5;
    }

    img {
		margin: 0.5em;
	}

    #server {
        display: none;
    }
    input[type=radio] {
        display: none;
    }
    input[type=radio] + label {
        cursor: pointer;
    }

    input[type=radio]:disabled + label {
        opacity: 0.5;
        cursor: not-allowed;
    }
    /* input[type=radio]:checked + label {
		background-color: #a5dc86;
		box-shadow: none;
		text-shadow: 0px 0px 1px black;
		color: #222;
	} */

    .cards:before {
        writing-mode: vertical-lr;
        display: inline-block;
        text-align: center;
        font-weight: 600;
        x-index: 1;
    }

    .cards:nth-child(2):before {
        content: 'Servers';
    }

    .cards:nth-child(1):before {
        /* content: 'Maps'; */
    }

    .horizonal-scroll {
        /* display: flex; */
        /* columns: 3 !important; */
        /* display: contents; */
        /* flex-shrink: 3; */
        flex-wrap: nowrap !important;
        overflow-x: auto !important;
    }

    .inline-loading {
        display: flex;
        align-items: center;
        height: 2em; 
        width: 2em;
    }
</style>

<div style="margin:0.5em;">

    <!-- {#if lobbyState == LobbyState.LIVE}
        <GameLaunch gameId={match.match.game_id} gameName={match.match.game_name} matchId={matchId}/>
    {:else if lobbyState == LobbyState.SPAWNING}
        <Loading inline inlineSize="1.5em">Loading Server</Loading>
    {/if} -->

    {#if teams.length > 0}
        <!-- <button on:click={()=>navigate(`/match/${matchId}`)}>📃 Match Page</button> -->
        <button on:click={quitLobby}>❌ Leave Lobby</button>
        <div class="servers">
            {#await getServerLocations($accessToken)}
                &nbsp;
            {:then dcids}
                <div class="cards horizonal-scroll overflow-scroll">
                    {#each matchServers as server}
                        <GameServer {server} {dcids} {match}/>
                    {/each}
                </div>
            {/await}

            {#await getServerLocations($accessToken)}
                &nbsp;
            {:then servers}
                {#if lobbyPlayers.length > 0}
                    {#if dcids.length > 0}
                        <div class="cards horizonal-scroll overflow-scroll">
                            
                            {#each selectedTeamDcids as dcid}
                                <input id={`server:${dcid}`} type="radio" bind:group={selectedServer} value={dcid} disabled={!leader}/>
                                <label class="card" for={`server:${dcid}`}>
                                    <img src="{$RELATIVE_PATH}/countries/{servers[dcid]['country']}.svg" alt="{servers[dcid]['country']} flag" width="48" height="48">
                                    <div>
                                        {servers[dcid]['city']}, {servers[dcid]['country']}
                                        {#if dcid in averagePings}
                                            {#each match.teams as pingTeam}
                                                <div style="color: {pingColor(teamPings[pingTeam['id'].toString()][dcid])}">
                                                    {#if pingTeam['id'] in teamDcids && teamDcids[pingTeam['id']] === dcid}
                                                        &#x2714;&#xFE0F;
                                                    {/if}
                                                    {teamPings[pingTeam['id'].toString()][dcid]}ms {pingTeam['tag']}
                                                </div>
                                            {/each}
                                            <div style="color: {pingColor(averagePings[dcid])}">
                                                {averagePings[dcid]}ms avg
                                            </div>
                                        {/if}
                                    </div>
                                </label>
                            {/each}

                            {#each dcids as dcid}
                                {#if !selectedTeamDcids.includes(dcid)}
                                    <input id={`server:${dcid}`} type="radio" bind:group={selectedServer} value={dcid} disabled={!leader}/>
                                    <label class="card" for={`server:${dcid}`}>
                                        <img src="{$RELATIVE_PATH}/countries/{servers[dcid]['country']}.svg" alt="{servers[dcid]['country']} flag" width="48" height="48">
                                        <div>
                                            {servers[dcid]['name']}, {servers[dcid]['country']}
                                            {#if dcid in averagePings}
                                                {#each match.teams as pingTeam}
                                                    <div style="color: {pingColor(teamPings[pingTeam['id'].toString()][dcid])}">
                                                        {#if pingTeam['id'] in teamDcids && teamDcids[pingTeam['id']] === dcid}
                                                            &#x2714;&#xFE0F;
                                                        {/if}
                                                        {teamPings[pingTeam['id'].toString()][dcid]}ms {pingTeam['tag']}
                                                    </div>
                                                {/each}
                                                <div style="color: {pingColor(averagePings[dcid])}">
                                                    {averagePings[dcid]}ms avg
                                                </div>
                                            {/if}
                                        </div>
                                    </label>
                                {/if}
                            {/each}

                            {#each Object.keys(averagePings) as dcid}
                                {#if !selectedTeamDcids.includes(dcid) && !dcids.includes(dcid)}
                                    <input id={`server:${dcid}`} type="radio" bind:group={selectedServer} value={dcid} disabled/>
                                    <label class="card" for={`server:${dcid}`} disabled>
                                        <img src="{$RELATIVE_PATH}/countries/{servers[dcid]['country']}.svg" alt="{servers[dcid]['country']} flag" width="48" height="48">
                                        <div>
                                            {servers[dcid]['city']}, {servers[dcid]['country']}
                                            {#if dcid in averagePings}
                                                {#each match.teams as pingTeam}
                                                    <div style="color: {pingColor(teamPings[pingTeam['id'].toString()][dcid])}">
                                                        {#if pingTeam['id'] in teamDcids && teamDcids[pingTeam['id']] === dcid}
                                                            &#x2714;&#xFE0F;
                                                        {/if}
                                                        {teamPings[pingTeam['id'].toString()][dcid]}ms {pingTeam['tag']}
                                                    </div>
                                                {/each}
                                                <div style="color: {pingColor(averagePings[dcid])}">
                                                    {averagePings[dcid]}ms avg
                                                </div>
                                            {/if}
                                        </div>
                                    </label>
                                {/if}
                            {/each}
                        </div>
                    {/if}
                {/if}
            {/await}
        </div>

        <div class="players">
            {#each teams as team}
                <div>
                    {#await team}
                        <Loading/>
                    {:then data}
                        <h1>{data.team.team_name}</h1>
                        <!-- {#if lobbyState === LobbyState.NOT_READY}
                            <LobbyReadyButton 
                                numberTeamMembers={team.members.map(m => m.user_id).filter(uid =>lobbyPlayers.includes(uid)).length}
                                minimumRosterSize={match.match.minimum_roster_size}
                                disabled={!$userData.teams.map(t => t.id).includes(data.team.id)}
                                bind:ready={teamReady[data.team.id]}
                                {matchId}
                                dcid={teamDcids[data.team.id]}
                            />
                        {/if} -->
                        {#each data.members as member}
                            {#if lobbyPlayers.includes(member['user_id'])}
                                <div class="player">
                                    <Avatar color={member['avatar_color']} avatar={member['avatar']} online small={true}/> &nbsp;
                                    {member['username']}
                                </div>
                            {/if}
                        {/each}
                        {#each data.members as member}
                            {#if !lobbyPlayers.includes(member['user_id'])}
                                <div class="player offline">
                                    <Avatar color={member['avatar_color']} avatar={member['avatar']} small={true}/> &nbsp;
                                    {member['username']}
                                </div>
                            {/if}
                        {/each}
                    {/await}
                </div>
            {/each}
        </div>
    {/if}

    <!-- <div>
        {#each chat as message}
            content here
        {/each}
        <input type="text" placeholder="chat"/>
    </div> -->
</div>