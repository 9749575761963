<script>
    import {RELATIVE_PATH, IS_ELECTRON} from '../stores.js'

    import Page from '../components/Page.svelte'

    const botURL = "https://discord.com/api/oauth2/authorize?client_id=779141499748483092&permissions=2048&scope=bot"

    function clickHandler(e) {
        if ($IS_ELECTRON) {
            e.preventDefault();
            require('electron').shell.openExternal(botURL);
        }
    }
</script>

<Page title="Discord Bot">
    <div class="center-pane">
        <img src="{$RELATIVE_PATH}/icons/bot.svg" alt="Add Bot to Discord" height="48" width="48"/>
        <p>Add the <img src="{$RELATIVE_PATH}/logo.svg" height="13" alt="FTW" style="margin: 0 0.15em;"> Bot to your own Discord server to:</p>
        <ul style="text-align: left;">
            <li>Easily rent gameservers</li>
            <li>Track stats from played games</li>
            <li>More to come! 🥴</li>
        </ul>
        <a href={botURL} target="_blank" class="discord" on:click={clickHandler}>
            <img src={$RELATIVE_PATH + "/logos/Discord-Logo-White.svg"} alt="Discord Logo" height="16">
            Add Bot!
        </a>
    </div>
</Page>
