<script>
	import {accessToken, RELATIVE_PATH} from '../../stores.js';
	import {validateAccessToken} from '../../Auth.js';
	import {getServerLocations, getServers} from './data.js'

	import AdminOnly from '../../components/AdminOnly.svelte';
	import ClickToCopy from '../../components/ClickToCopy.svelte';
	import NavLink from '../../components/NavLink.svelte';
	import Loading from '../../components/Loading.svelte';
	import TimeSince from '../../components/TimeSince.svelte';
	import UserLink from '../../components/UserLink.svelte';
</script>

<style>
	img {
		margin: 0.5em;
	}
</style>

<!-- <svelte:head>
	<title>Sapper project template</title>
</svelte:head> -->

<h1>Servers</h1>

{#if validateAccessToken($accessToken)}
	<AdminOnly>
		<NavLink to="/gameservers/create">
			<div class="card">
				<img src="{$RELATIVE_PATH}/icons/globe.svg" alt="Globe" width="48" height="48">
				Create Server
			</div>
		</NavLink>
	</AdminOnly>
	<div class="cards">
		{#await getServerLocations($accessToken)}
			<Loading/>
		{:then locations}
			{#await getServers($accessToken)}
				<Loading/>
			{:then data}
				{#if 'servers' in data && data.servers.length}
					{#each data.servers as server}
						<NavLink to="/gameservers/{server.id}">
							<div class="card">
								<img src="{$RELATIVE_PATH}/countries/{locations[server.config.dcid].country}.svg" alt="{locations[server.config.dcid].country} flag" width="48" height="48" align="left">
								<ul style="list-style: none; padding: 0;">
									<li>
										{#if 'ip' in server.config}
											IP: <ClickToCopy text={server.config.ip}/>
										{:else}
											loading...
										{/if}
									</li>
									<li>&#128205;{locations[server.config.dcid].city}, {locations[server.config.dcid].country}</li>
									<li><TimeSince dateString={server.date_created}/></li>
									<AdminOnly>
										<li><UserLink avatar={server.avatar} username={server.username} userId={server.user_id} inline small/></li>
									</AdminOnly>
								</ul>
							</div>
						</NavLink>
					{/each}
				{:else}
					<div class="card" style="background-color: var(--background-dark);">
						<strong>No Active Servers</strong>
					</div>
				{/if}
			{/await}
		{/await}
	</div>
{:else}
	<!-- <p><NavLink to="/signup"><strong>Signup</strong></NavLink>  to start spawning your own servers!</p> -->
	<div class="card" style="background-color: var(--background-dark);">
		<strong>⚠️Login to manage your rentals</strong>
	</div>
{/if}
