<script>
    import { onMount } from 'svelte';

    import NavLink from './NavLink.svelte';

    import {tooltip, TooltipPosition} from './Tooltip.ts';

    import {RELATIVE_PATH, games, selectedGameId} from '../stores.js';

    onMount(() => {
        const sg = window.localStorage.getItem('selectedGameId')
        console.log("found", sg)
        if (sg) {
            selectedGameId.set(parseInt(sg))
        }
    })
</script>

<style>
    div.sidebar-button {
        margin-bottom: 0.5em;
    }
</style>

{#if $games.length}
    <NavLink to="library">
        <div class="sidebar-button" use:tooltip={{text: "Games", pos: TooltipPosition.Right}}>
            <div class="sidebar-button" style={`background-image: url(${$RELATIVE_PATH}/icons/grid.svg);`}></div>
        </div>
    </NavLink>
{/if}
