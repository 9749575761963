<script>
    import {onMount, createEventDispatcher} from 'svelte';
    import {getServerIdMap} from './data.js';

	const dispatch = createEventDispatcher();

    export let pingMap = {}

    export let startIn = 0;

    async function refreshPing() {
        const serverIdMap = await getServerIdMap();

        for (const [dcid, url] of Object.entries(serverIdMap).reverse()) {
            let retries = 3
            let latencies = await ping(url, 5);
            while (latencies.reduce((a,b) => a+b) / latencies.length > 400 && retries > 0) {
                latencies = await ping(url, 5);
                retries--;
            }
            latencies.sort((a, b) => a - b);
            latencies = latencies.slice(1, latencies.length - 1);

            if (latencies.length === 0) {
                pingMap[dcid] = 999
                continue
            }
            const avgLatency = latencies.reduce((a, b) => a + b) / latencies.length;
            pingMap[dcid] = parseInt(avgLatency)
        }
    }

    async function warmup() {
        const serverIdMap = await getServerIdMap();

        let warmups = []
        for (const [dcid, url] of Object.entries(serverIdMap)) {
            warmups.push(ping(url, 1));
        }
        Promise.all(warmups);
    }

    function ping(url, numPings) {
    return new Promise(resolve => {
        const latencies = [];
        let i = 0;
        var img = new Image();

        function next() {
        if (i === numPings) {
            resolve(latencies);
            return;
        }
        const startTime = Date.now();
        img.onload = () => {
            latencies.push(Date.now() - startTime);
            i++;
            next();
        };

        img.onerror = img.onload;
        img.src = `${url}/ping?x=${Math.random()}`;
        }

        next();
    });
    }

    onMount(async () => {
        // await test()
        setTimeout(async () => {
            await warmup()
            await refreshPing()
            dispatch('finish');
        }, startIn)
    })
</script>
