<script lang="ts">
    import {IS_ELECTRON, RELATIVE_PATH} from '../../../stores.js';
    import {tooltip} from '../../../components/Tooltip'
    import GameLaunch from '../../../components/electron/GameLaunch.svelte';

    export let server;
    export let dcids;
    export let match;

    function getTooltipText(server) {
        if (server.active) {
            if ('ip' in server.config) {
                return "Active"
            } else {
                return "Spawning"
            }
        } else {
            return "Expired"
        }
    }
</script>

<style>
    .info {
        margin-left: 0.5em;
    }
</style>

<span class="card" class:disabled={!server.active} use:tooltip={getTooltipText(server)}>
    <img src="{$RELATIVE_PATH}/countries/{dcids[server.config.dcid].country}.svg" alt="{dcids[server.config.dcid]} flag" width="48" height="48">
    <div class="info">
        {dcids[server.config.dcid].city}
        {#if server.active}
            {#if 'ip' in server.config}
                {#if 'status' in server && server.status}
                    <br/>
                    {#if server.status.Players && Object.keys(server.status.Players).length > 0}
                        {Object.keys(server.status.Players).length} players connected
                    {:else}
                        No players connected
                    {/if}
                    {#if server.status.Map}
                        <br/>
                        <em>{server.status.Map}</em>
                    {/if}
                {/if}
                <br/>
                {#if $IS_ELECTRON}
                    <GameLaunch gameId={match.match.game_id} gameName={match.match.game_name} matchId={match.match.id} gameserverId={server.id}/>
                {/if}
            {:else}
                <em>Spawning...</em>
            {/if}
        {/if}
    </div>
</span>
