<script>
    import {isDef} from '../utils.js';

    export let title = 'FTW - For The Win';
    export let description = null;
    export let keywords = [];
    let default_keywords = ['FTW', 'For The Win', 'Gaming League']
    export let image = null;
    export let follow = false;
</script>

<svelte:head>
    {#if isDef(title)}
        <title>{'FTW | ' + title}</title>
        <meta property="og:title" content={title} />
    {/if}
  
    {#if isDef (description)}
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
    {/if}
  
    <meta name="keywords" content={default_keywords.concat(keywords).join(', ')} />

    {#if follow}
        <meta name="robots" content="index, follow" />
    {:else}
        <meta name="robots" content="index, nofollow" />
    {/if}

    {#if isDef(image)}
        <meta property="og:image" content={image} />
    {/if}
</svelte:head>

<slot></slot>

<!-- Empty space to give users more spacing -->
<br/>
