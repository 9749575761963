<script>
	import { useLocation } from 'svelte-routing';
	import {IS_ELECTRON, RELATIVE_PATH, currentLocation, panePage, isMobile, validAccessToken} from '../stores.js'
	import PanelLayout from "./PanelLayout.svelte";

	const location = useLocation();
	let rootElement;
	let showPanel;

	const background_paths = [
		"login",
		"/bot",
		"/ping"
	];

	function isBackgroundPath(path) {
		return background_paths.some(p => path.includes(p));
	}

	$: useScrollingBackground = $location && isBackgroundPath($location.pathname);

	$: currentLocation.set($location);

	$: rootElement && rootElement.style.setProperty('--login-background', `url("${$RELATIVE_PATH}/bg.jpg")`);
</script>

<style>
	.container {
		padding: var(--nav-height) 0 0 0;
		height: 100vh;
		overflow-y: hidden;
		width: auto;
		display: flex;
	}

	.container.electron {
		margin-top: calc(var(--electron-titlebar-height)) !important;
		min-height: calc(100vh - (var(--nav-height) - var(--electron-titlebar-height) - 7px)) !important;
		max-height: calc(100vh - (var(--nav-height) - var(--electron-titlebar-height) - 7px)) !important;
	}

	.content {
		display: flex;
		flex-direction: column;
		overflow-y: auto !important;
		margin: 0;
		padding: 0;
	}

	.content-wrapper {
		height: 100%;
		width: 100%;
	}

	.container-desktop-offset {
		margin-left: var(--sidebar-width);
	}
</style>

<div bind:this={rootElement}
	class:electron={$IS_ELECTRON}
	class:container={!useScrollingBackground}
	class:overflow-scroll={!useScrollingBackground}
	class:scrolling-bg={useScrollingBackground}
	class:container-desktop-offset={!useScrollingBackground && !$isMobile && (!$IS_ELECTRON || ($IS_ELECTRON && $validAccessToken))}>
    <div class="content overflow-scroll" style="width: calc(100% - {showPanel}px)">
		<div class="content-wrapper">
			<slot></slot>
		</div>
	</div>
	<PanelLayout bind:width={showPanel} page={$panePage}/>
</div>
