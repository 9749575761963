<script>
    import UserLink from './UserLink.svelte'
    import {tooltip} from './Tooltip'
    export let users = [];
    export let count = 10;
</script>

<style>
    img {
        border-radius: 100%;
    }
    .avatar-stack {
        margin: 10px 0 5px 0;
    }

    .avatar-stack-more {
        display: inline;
        background-color: var(--background-highlight);
        border-radius: 100%;
        width: 14px;
        height: 14px;
        font-size: 0.8em;
        padding: 0.25em;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: left;
        vertical-align: middle;
        color: white;
        text-align: left;
    }
</style>

<div class="avatar-stack">
    {#each users.slice(0, count) as user}
        <UserLink userId={user.user_id} avatar={user.avatar} online={user.online} username={user.username || user.auths[0]} avatarOnly/>
    {/each}
    {#if users.length > count}
        <span style="display: inline-block;" use:tooltip={users.slice(count, users.length).map(u => u.username || u.auths[0].auth).join(", ")}>
            <span class="avatar-stack-more">
                +{users.length - count}
            </span>
        </span>
    {/if}
</div>
