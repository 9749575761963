<script>
    import { userData } from '../../stores.js';
    import { deslugify, slugify, titlecase, parseMatchRound, isDef, uppercase } from '../../utils.js';
    import { Link } from 'svelte-routing';
    import DevelopersOnly from '../../components/DevelopersOnly.svelte';

    import Page404 from '../Page404.svelte';
    import Page from '../../components/Page.svelte';

    import Loading from '../../components/Loading.svelte';
    import NavLink from '../../components/NavLink.svelte';
    import Dropdown from '../../components/Dropdown.svelte';
    import LikeButton from '../../components/LikeButton.svelte';

    import Lobby from './Lobby.svelte';
    import Statistics from './Statistics.svelte';
    import SubmitScores from './SubmitScores.svelte';

    // import Editor from '../../components/forum/Editor.svelte';

    export let matchId = null;
    export let page = null;

    $: console.log(page);

    let match = null;

    async function getMatch(matchId) {
        matchId = parseInt(matchId)
        if (matchId == NaN) {
            match = null
            return
        }

        const response = await fetch(`http://localhost:8000/api/match/${matchId}`)
        if (response.ok) {
            const matchResp = await response.json();

            match = matchResp;
            console.log(match);

            setSeoVars(matchResp);
        } else {
            match = undefined;
            return;
        }
    }

    /* SEO */
    let title;
    let description;
    let keywords;

    function setSeoVars(match) {
        let pickup = false
        match.teams.forEach(team => {
            if (team.pickup) {
                pickup = true
            }
        })

        let match_type = isDef(match.match.match_type) ? uppercase(deslugify(match.match.match_type)) : `Match ${match.match.id}`
        title = `${pickup 
            ? `${match.match.game_abbreviation} ${match.match.gt_abbreviation}: Pickup #${match.match.id} (${match.match.season})`
            : `${match.teams[0].tag} vs ${match.teams[1].tag} - ${match.match.gt_abbreviation}: ${match_type}`} (${match.match.season})`;

        
        keywords = []
        keywords.push(match.match.league, match.match.game_name, match.match.game_abbreviation, match.match.game_type)
        if (isDef(match.match.match_type)) keywords.push(uppercase(deslugify(match.match.match_type)))
        match.teams.forEach(team => { // team names and tags
            if (!team.pickup) {
                keywords.push(team.team_name)
                keywords.push(team.tag)
            }
        })
        match.rounds.forEach(round => keywords.push(round.map)) // map names
        keywords.push(...[...new Set(match.demos.flatMap(demo => demo.username))]) // player names

        description = `${match.match.game_name}: `
        if (pickup) {
            description += `${match.match.gt_abbreviation}: Pickup #${match.match.id}\n`
        } else {
            description += `${match.match.game_type} - ${match_type}. \n`
            description += `${match.teams[0].team_name} (${match.teams[0].tag}) vs. ${match.teams[1].team_name} ${match.teams[1].tag}. \n`
        }
        description += `Map(s) Played: ${match.rounds.map(round => round.map).join(', ')}.`
    }

    /* END SEO */


    $: getMatch(matchId);

    $: teamsLeaderOn = $userData != null && $userData.teams.filter(team => 'role' in team && team.role == 'leader').map(t => t.id);
    $: matchTeamIds = match && match.teams.flatMap(t => t.id);
    $: leader = isInTeam(matchTeamIds, teamsLeaderOn);
    $: participant = $userData != null && isInTeam(matchTeamIds, $userData.teams.map(t => t.id));

    function isInTeam(matchTeams, ownTeamIds) {
        if (matchTeams && ownTeamIds) {
            var inTeam = false;
            ownTeamIds.forEach(teamId => {
                if (matchTeams.includes(teamId)) {
                    inTeam = true;
                }
            });
            return inTeam;
        } else {
            return false;
        }
    }
</script>

<style>
    .match {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .team {
        flex: 1;
        padding: 0.5em;
        /* display: inline-block; */
    }
    .score {
        font-size: 2em;
        text-align: center;
        display: block;
    }
    .grey {
        color: #888;
    }
</style>

<Page title={title} keywords={keywords} description={description} follow={isDef(match)}>
    {#if match !== null && match !== undefined}
        {#if page == null}
            <div style="margin-left: 0.25em">
                <Link to="/leagues/{slugify(match.match.game_name)}">{match.match.game_name}</Link> /
                {titlecase(deslugify(match.match.game_type))} / 
                <Link to="/leagues/{slugify(match.match.game_name)}/{slugify(match.match.game_type)}/{slugify(match.match.season)}">{match.match.season}</Link> / 
                {#if match.match_round}
                    <strong>{parseMatchRound(match.match.round)}</strong>
                {:else}
                    <strong>{match.match.id}</strong>
                {/if}
            </div>
            
            <!-- {#if leader}
                <Dropdown label="Leader">
                    <span class="dropdown-menu">
                        <NavLink to="/match/{matchId}/submit-scores">Submit Scores</NavLink>
                        <div>Reschedule Match</div>
                    </span>
                </Dropdown>
            {/if} -->
            {#if participant}
                <NavLink to="/match/{matchId}/lobby"><button class="button">Match Lobby</button></NavLink>
            {/if}

            <div class="match">
                <!-- <span class="team" class:right-align={(match.rounds && match.rounds.length > 0)}>
                    <span class="grey">W-L-D</span><br>
                    {#each match.teams as team}
                        <span style="display: block;">
                            <Link to="/team/{slugify(team.team_name)}">{team.team_name} ({team.tag})</Link>
                            <br><span>0-0-0</span>
                        </span><br>
                    {/each}
                </span> -->

                {#if match.rounds && match.rounds.length > 0}
                    {#if ['AIM', 'SKEET'].includes(match.match.gt_abbreviation)}
                        <span class="team" style="text-align: center;">
                            <br>
                            {#each match.players as player}
                                <span class="score"><Link to="/user/{encodeURIComponent(player.username)}">{player.username}</Link></span><br>
                            {/each}
                        </span>
                        {#each match.rounds.sort((a, b) => (a.round > b.round) ? 1 : -1) as round}
                            <span class="team" style="text-align: center;">
                                Round {round.round + 1} ({round.map})<br>

                                {#each match.teams as team}
                                    <!-- <span class="score" class:grey={!(round.scores.indexOf(Math.max(...round.scores)) == i)}> -->
                                    {@const score = match.scores.find(s => s.team_id == team.id && s.match_round_id == round.id)}
                                    <span class="score">
                                        {#if isDef(score)}
                                            {score.score}
                                        {/if}
                                    </span>
                                {/each}
                            </span>
                        {/each}
                    {:else}
                        <span class="team" style="text-align: center;">
                            <br>
                            {#if false && match.match.game_type == '1 on 1' && 'players' in match}
                                {#each match.players as player}
                                    <span class="score"><Link to="/user/{encodeURIComponent(player.username)}">{player.username}</Link></span><br>
                                {/each}
                            {:else}
                                {#each match.teams as team}
                                    {#if team.tag == null}
                                        <span class="score">Pickup</span><br>
                                    {:else}
                                        <span class="score"><Link to="/team/{slugify(team.team_name)}">{team.tag}</Link></span><br>
                                    {/if}
                                {/each}
                            {/if}
                        </span>
                        {#each match.rounds.sort((a, b) => (a.round > b.round) ? 1 : -1) as round}
                            <span class="team" style="text-align: center;">
                                Round {round.round + 1} ({round.map})<br>

                                {#each match.teams as team}
                                    <!-- <span class="score" class:grey={!(round.scores.indexOf(Math.max(...round.scores)) == i)}> -->
                                    {@const score = match.scores.find(s => s.team_id == team.id && s.match_round_id == round.id)}
                                    <span class="score">
                                        {#if isDef(score)}
                                            {score.score}
                                        {:else}
                                            0
                                        {/if}
                                    </span><br>
                                {/each}
                            </span>
                        {/each}
                    {/if}
                {:else if match.rounds.length == 0}
                        <div class="empty">No rounds played yet...</div>
                {/if}
            </div>

            {#if match.demos && match.demos.length > 0}
                {#each match.rounds as round}
                    <h2 style="margin-left: 0.25em;">Round {round.round + 1} <DevelopersOnly value={round.id} tooltipText="Match Round ID"/> <LikeButton likeType={"match_round"} likeId={round.id}/></h2>
                    <Statistics match={match.match} gameId={match.match.game_id} gameName={match.match.game_name} matchRound={round} demos={match.demos} avatars={match.avatars}/>
                    <hr/>
                {/each}
            {/if}

            <!-- <h1>Comments</h1>
            <Editor/> -->
        {:else if page == 'submit-scores'}
            <SubmitScores {matchId} {match}/>
        {:else if page == 'lobby'}
            <Lobby {match} {leader}/>
        {:else}
            <Page404 item='match'/>
        {/if}
        
    {:else if match === null}
        <Loading/>
    {:else}
        <Page404 item="match"/>
    {/if}
</Page>