<script>
    import {currentLobby} from '../stores.js';
    import {tooltip} from './Tooltip.ts'
	import { navigate, useLocation } from 'svelte-routing';
    import OnlineIndicator from './OnlineIndicator.svelte';
    import {OnlineStatus} from '../models/Enums.ts';

    const location = useLocation();
</script>

<style>
    div {
        margin-left: 0.5em;
        background-color: var(--button-background-color);
        padding: 0.25em;
        cursor: pointer;
    }

    .lobby-button {
        outline: none;
        color: #fff;
        background: #111;
        cursor: pointer;
        position: relative;
        z-index: 0;
        vertical-align: middle;
        display: flex;
        align-items: center;
    }

    .glow:before {
        content: '';
        background: radial-gradient(dodgerblue, blue);
        position: absolute;
        top: -1px;
        left: -1px;
        z-index: -1;
        filter: blur(3px);
        opacity: 0.3;
        background-size: 300%;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        animation: glowing 20s linear infinite;
        transition: opacity .3s ease-in-out;
    }

    .noglow:before {
        opacity: 0;
        transition: opacity .3s ease-in-out;
    }

    .glow:hover:before {
        opacity: 0.65;
    }

    .glow:after {
        z-index: -1;
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #111;
        border-radius: 2px;
        left: 0;
        top: 0;
    }

    @keyframes glowing {
        0% { background-position: 0 0; }
        50% { background-position: 400% 0; }
        100% { background-position: 0 0; }
    }
</style>

{#if $currentLobby !== null && $currentLobby.match && $currentLobby.match.teams}
    <div class="button lobby-button" class:glow={!($location.pathname.includes("lobby"))}
         use:tooltip={"Current Lobby"}
         on:click={() => navigate(`/match/${$currentLobby.match.match.id}/lobby`)}>
         <OnlineIndicator status={OnlineStatus.Active}/>
         {#each $currentLobby.match.teams as team, i}
            {#if i % 2 == 1}&nbsp;vs.{/if}
            {team.tag}
        {/each}
    </div>
{/if}
