<script>
    import { onMount } from 'svelte';
    import {accessToken, RELATIVE_PATH, validAccessToken} from '../stores'
    import {tooltip} from './Tooltip.ts'
    import {isDef} from '../utils.js'
    import { fade } from 'svelte/transition';
    import {createEventDispatcher} from 'svelte'

    export let likeType;
    export let likeId;
    export let manual = false;
    export let isLiked = false;
    export let totalLikes = null;

    let dispatch = new createEventDispatcher()

    onMount(async () => {
        if (manual) {
            return
        }

        // Get the current like count and whether the user has already liked the item
        let headers = {}
        if ($validAccessToken) {
            headers['Authorization'] = `Bearer ${$accessToken}`
        }

        const response = await fetch(`http://localhost:8000/api/like/${likeType}/${likeId}`, {
            headers: headers
        });

        const data = await response.json();
        totalLikes = data.totalLikes;
        isLiked = data.isLiked;
    });

    function handleLikeClick() {
        if (!$validAccessToken) {
            location.href = 'DISCORD_LOGIN_URL'
        }
        if (isLiked) {
            // If the user has already liked the item, send a DELETE request to unlike it
            fetch(`http://localhost:8000/api/like/${likeType}/${likeId}`, {
                method: 'DELETE',
                headers: {
                'Authorization': `Bearer ${$accessToken}`
                }
            }).then(() => {
                // Update the like count and isLiked flag when the DELETE request is successful
                totalLikes--;
                isLiked = false;
                dispatch('unliked', likeId)
        });
        } else {
            // If the user has not already liked the item, send a POST request to like it
            fetch(`http://localhost:8000/api/like/${likeType}/${likeId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${$accessToken}`
                }
            }).then(() => {
                // Update the like count and isLiked flag when the POST request is successful
                totalLikes++;
                isLiked = true;
                const detail = {}
                detail[likeId] = totalLikes
                dispatch('liked', detail)
            });
        }
    }

    function tooltipText() {
        if ($validAccessToken) {
            if (isLiked) {
                return 'Unlike';
            } else {
                return 'Like';
            }
        } else {
            return 'Login to like';
        }
    }
</script>

<style>
    /* Make the like count subtle by using a lighter color */
    .like-count {
      color: white;
      z-index: 2;
      display: inline-block;
      position: absolute;
      right: -8px;
      bottom: -2px;
      background-color: rgba(255,255,255,0.35);
      border-radius: 100%;
      width: 20px;
      height: 20px;
      text-align: center;
      vertical-align: middle;
    }

    /* Add some style to the like button */
    button {
      font-size: 14px;
      /* padding: 5px 10px; */
      border: none;
      border-radius: 3px;
      background-color: rgba(0, 0, 0, 0);
      cursor: pointer;
      /* display: flex; */
      vertical-align: middle;
      text-align: center;
      display: inline-block;
      padding: 0;
      margin: 0 0.5em;
      height: 28px;
      width: 28px;
    }

    .favorite__icon {
        opacity: 0;
        transition: opacity 0.3s, transform 0.3s;
        transform: scale(.6);
        position: absolute;
        right: 0px;
        bottom: 2px;
        z-index: 1;
    }

    .favorite--enable {
        opacity: 1;
        transform: scale(1);
    }

    .favorite--not {
        opacity: 1;
        transform: scale(1);
    }
</style>


<button on:click={handleLikeClick} use:tooltip={tooltipText()}>
    {#if isDef(totalLikes)}
        <span class="like-count" in:fade|global>{totalLikes}</span>
    {/if}
    <span>
        <svg class="favorite__icon" class:favorite--enable={isLiked} height="28px" aria-hidden="true" focusable="false" data-prefix="far" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
            <path fill="red" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path>
        </svg>
        <svg class="favorite__icon" class:favorite--not={!isLiked} height="28px" aria-hidden="true" focusable="false" data-prefix="far" data-icon="heart" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg="">
            <path fill="red" d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"></path>
        </svg>
    </span>
</button>
