<script lang="ts">
    import {games, leagueUsers, selectedGameId} from '../../stores.js'
    import {isDef, slugify} from '../../utils.js';

    import Loading from '../Loading.svelte';

    import Filters from './Filters.svelte';
    import {applyFiltersToURL} from './Filters.svelte';

    import SortableTable from '../SortableTable.svelte';

    export let userIds: any = 'all';
    export let leagueId: any = null;
    export let teamId: any = null;

    let gameMode = null;
    let statisticMap = null;
    let filters = null;

    async function getStats(user_ids, gameMode, filters) {
        if (!isDef(gameMode) || !isDef(filters)) {
            return
        }

        let request_url = `http://localhost:8000/api/stats/users?f=1`

        if (user_ids != 'all' && user_ids.length > 0) {
            request_url += `&user_ids=${user_ids}`
        }

        if (isDef(leagueId)) {
            request_url += `&league_id=${leagueId}`
        }

        if (isDef(teamId)) {
            request_url += `&team_id=${teamId}`
        }

        request_url = applyFiltersToURL(request_url, null, gameMode, filters);

        const response = await fetch(request_url);
        if (response.ok) {
            return await response.json();
        }
    }

    function getHeadings() {
        if (isDef(leagueId) || isDef(teamId)) {
            return [
                "Player",
                {col: "GP", tooltip: "Games Played"}
            ]
        } else {
            return [
                {col: "L", tooltip: "League"},
                {col: "Season"},
                {col: "LM", tooltip: "Last Match"},
                {col: "GP", tooltip: "Games Played"}
            ]
        }
    }

    function getData(seasons, statistic_ids) {
        let data = [];

        for (const index in seasons.stats) {
            let stats = seasons.stats[index]
            let row = []

            if (isDef(leagueId) || isDef(teamId)) {
                row.push(
                    {
                        type: 'UserLink',
                        username: stats['username'],
                        userId: stats['user_id'],
                        avatar: stats['avatar'],
                        sortKey: stats['username']
                    },
                    stats.gp
                )
            } else {
                const league = seasons.leagues.find(l => l.league_id == stats['league_id'])
                const leagueUser = $leagueUsers[league['league_user_id']]
                row.push(
                    {
                        type: 'UserLink',
                        username: leagueUser['username'],
                        userId: leagueUser['id'],
                        avatar: leagueUser['avatar'],
                        avatarOnly: true,
                        sortKey: leagueUser['username']
                    },
                    {
                        type: 'Link',
                        to: `/leagues/${slugify($games.find(g => g.id == $selectedGameId)['game_name'])}/${slugify(league['game_type'])}/${slugify(league['season'])}`,
                        col: `${league['abbreviation']}: ${league['season']}`,
                        left: true
                    }, 
                    {
                        type: 'TimeSince',
                        dateString: league['latest_match'],
                        sortKey: league['latest_match']
                    },
                    stats['gp']
                )
            }

            for (const statistic_id of statistic_ids) {
                let statVal = stats[statistic_id]
                if (isDef(statVal)) {
                    statVal = statVal.toFixed(statisticMap[statistic_id]['decimals'])
                }
                row.push(parseFloat(statVal))
            }

            data.push(row)
        }

        return data;
    }

    function getCategories() {
        if (!isDef(gameMode.category_spans)) {
            return {"Seasons":3}
        }
        let seasons = 4
        if (isDef(leagueId) || isDef(teamId)) seasons = 2
        return Object.assign({"Seasons": seasons}, gameMode.category_spans.reduce((acc, item, idx) => ({ ...acc, [gameMode.category_names[idx]]: item}), {}))
    }
</script>

<div>
    <h2>Season Averages</h2>

    <Filters {userIds} {leagueId} {teamId} disableGameTypeFilter={!isDef(teamId)} disableLeagueFilter={!isDef(teamId)}
        on:gameMode={(gm) => gameMode = gm.detail}
        on:statisticMap={(sm) => statisticMap = sm.detail}
        on:filterChanged={(f) => filters = f.detail}/>

    {#await getStats(userIds, gameMode, filters)}
        <Loading/>
        <div class="skeleton"></div>
        <div class="skeleton" style="height: 505px;"></div>
    {:then seasons}
        {#if isDef(seasons) && seasons.stats.length > 0}
            <div class="table-wrapper overflow-scroll">
                <SortableTable categories={getCategories()}
                                headings={
                                    getHeadings().concat(
                                        gameMode.statistic_ids.map(statistic_id => {
                                            return {
                                                col: statisticMap[statistic_id]['acronym'],
                                                tooltip: statisticMap[statistic_id]['stat']
                                            }
                                        })
                                    )
                                }
                                rows={getData(seasons, gameMode.statistic_ids)}
                />
            </div>
        {:else}
            <div>No Seasons Found</div>
        {/if}
    {/await}
</div>
