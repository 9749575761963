import { accessToken, validAccessToken, IS_ELECTRON, userKV, defaultUserKV } from './stores.js';
import { get } from 'svelte/store';
import { navigate } from 'svelte-routing';


export function validateAccessToken(accessToken) {
	window.localStorage.setItem('accessToken', accessToken);
	if (accessToken === null || accessToken === "") {
		return false;
	} else {
		try {
			let split = accessToken.split('.')
			if (split.length != 3) {
				return false;
			}

			let body = window.atob(split[1]);
			let now = new Date().getTime() / 1000;
			if (now > body['exp']) {
				return false;
			}
			return true;
		} catch (e) {
			return false;
		}
	}
}

export function logout(e) {
	if (e !== null && e !== undefined) e.preventDefault();
	window.localStorage.clear();
	validAccessToken.set(false);
	accessToken.set(null);
	userKV.set(defaultUserKV);
	if (get(IS_ELECTRON)) {
		navigate('/login', {replace: true});
	} else {
		navigate('/', {replace: true});
	}
}
