<script>
    import { accessToken, notifications, RELATIVE_PATH, userData } from '../stores.js';
    import { validateAccessToken } from '../Auth.js';
    import { tooltip } from './Tooltip.ts';
    import {slugify} from '../utils.js';

    import Dropdown from './Dropdown.svelte';

    import { Link } from 'svelte-routing';

    let notificationDiv = null;

    $: if ($accessToken && validateAccessToken($accessToken)) {
        getNotifications();
    };
    $: lastChecked = $userData && $userData.notification_last_check;
    $: unreadCount = ($userData != null && $notifications && $notifications.filter(n => n.d > lastChecked).length) || 0;
    $: console.log(unreadCount);

    async function getNotifications() {
        console.log("get notifications")
        const response = await fetch('http://localhost:8000/api/notifications', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + $accessToken
            }
        });

        if (response.ok) {
            const body = await response.json();
            notifications.set(body);
        }
    }

    function clearNotifications() {
        notifications.set([]);
        fetch('http://localhost:8000/api/notifications/clear', {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + $accessToken
            }
        });
    }

    function closeHandler(event) {
        event.preventDefault();

        updateLastClicked();
        scrollNotificationsBackToTop();
    }

    function scrollNotificationsBackToTop() {
        if (notificationDiv == null) {
            notificationDiv = document.getElementById("notifications");
        }
        notificationDiv.scrollTop = 0;
    }

    function updateLastClicked() {
        lastChecked = new Date().getTime();
        console.log(lastChecked);
        fetch('http://localhost:8000/api/notifications/check', {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + $accessToken
            }
        });
    }
</script>

<style>
    ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    #notifications {
        overflow-y: auto;
        max-height: 350px;
    }
    li {
        padding: 1em;
        border-top: 1px solid #333;
        border-left: 3px solid white;
        text-align: left;
    }

    li:hover {
        background-color: #333;
    }

    .new {
        border-left: 3px solid red;
    }
    #clear {
        display: inline-block;
        margin: 0;
        padding: 0;
        background-color: #333;
        border: none;
        cursor: pointer;
        min-height: unset;
    }

    #clear > span {
        padding: 0.2em;
    }

    em {
        text-decoration-color: #eee;
    }
</style>

<Dropdown label="🔔" badge="{unreadCount}" right wide on:close={closeHandler}>
    <span class="dropdown-menu">
        <div class="dropdown-title">
            Notifications
            {#if $notifications.length > 0}
                <button id="clear" class="right" on:click={clearNotifications}><span>clear all</span></button>
            {/if}
        </div>
        <div id="notifications">
            {#if $notifications.length > 0 && lastChecked}
                {#each $notifications as notification}
                    <ul>
                        <Link to={`/${notification.lt}/${notification.lt === "team" ? slugify(notification.l) : notification.l}`}>
                            <li class:new={notification.d > lastChecked} use:tooltip={new Date(notification.d).toString()}>
                                {@html notification.m}<br>
                            </li>
                        </Link>
                    </ul>
                {/each}
            {:else}
                <em style="margin:2em 0; display:block;">
                    <img width="100" src="{$RELATIVE_PATH}/icons/bell.svg" alt="No notifications" style="opacity: 0.75; text-align: center; margin-left: auto; margin-right: auto; margin: 1em auto"/>
                    <span style="margin-bottom: 2em">You currently have no notifications</span>
                </em>
            {/if}
        </div>
    </span>
</Dropdown>
