<script>
    import NavLink from './NavLink.svelte';
    import {tooltip, TooltipPosition} from './Tooltip.ts';

    export let name = null;
    export let image = null;
    export let url = null;
</script>

<style>
    .noImage {
        border-radius: 50%;
        background-color: rgba(255,255,255,0.1);
    }
</style>

{#if name !== null && url !== null}
    <NavLink to={url}>
        <div class="sidebar-button"
            use:tooltip={{text: name, pos: TooltipPosition.Right}} 
            class:noImage={image === null}
            style={`background-image: ${image === null ? 'none' : `url(${image})`}`}>
        </div>
    </NavLink>
{/if}
