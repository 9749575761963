<script context="module" lang="ts">
    import {panePage} from '../stores.js'

    export enum PanePages {
        GameServer
    }

    export function setPanePage(pp: PanePage) {
        panePage.set(pp);
    }

    export type PanePage = {
        page: PanePages;
        data: object;
    };

    export function closePage(event) {
        panePage.set(null)
    }
</script>

<script>
    // Panel Components
    import GameServer from './panels/GameServer.svelte';

    import {paneWidth, isMobile} from '../stores.js';

    export let width = 0;
    export let page;

    let isMouseDown, windowWidth;

    $: pageChange(page);

    function pageChange(page) {
        if (page == null) {
            width = 0;
        } else {
            width = $paneWidth;
        }
    }


    function handleMouseMove(e) {
		if (isMouseDown) {
            console.log(windowWidth, e.clientX)
			paneWidth.set(windowWidth - e.clientX)
            width = $paneWidth;
		}
	}

	function handleMouseDown(e) {
		isMouseDown = true
	}

	function handleMouseUp(){
		isMouseDown = false
	}
</script>

<style>
    .container {
        display: flex;
        flex-direction: column;
    }
    .resize {
        /* cursor: col-resize; */
        height: 100vh !important;
        width: 12px;
        background: var(--background-dark);
        margin: 0;
        padding: 0;
        user-select: none; /* Standard syntax */
    }
</style>

<svelte:window
    bind:outerWidth={windowWidth}
	on:mousemove={handleMouseMove}
	on:mouseup={handleMouseUp}
/>

{#if page}
    {#if !$isMobile}
        <div class="resize container" style="cursor: e-resize;" on:mousedown={handleMouseDown}></div>
    {:else}
        <div class="resize container"></div>
    {/if}
    <div class="container" style="width: {$paneWidth}px">
        {#if $panePage.page == PanePages.GameServer}
            <GameServer data={$panePage.data}/>
        {/if}
    </div>
{/if}