<script context="module" lang="ts">
    import { mention, mentionCallback, mentionString } from '../../stores.js';
    import { get } from 'svelte/store';

    let currentPosition = 0;
    let cancelled = false; // via escape

    const breakingChars = [32, 160, 10, 13, 9, 8211, 8212, 44, 46]; // Add more as needed

    export function mentionListener(node) {
        const handleInput = event => {
            currentPosition = event.target.editorController.editor.getPosition()
            let currentCharacter = innerText()[currentPosition]

            const inMention = get(mention)
            // Check for start of mention
            if (inMention) {
                if (breakingChars.includes(currentCharacter)) {
                    clearMention('breaking change')
                } else {
                    mentionString.set(getMentionSubstring(innerText(), currentPosition))
                }
            }
            
            if (currentCharacter === '@') {
                if (!inMention) {
                    setMention(event.target);
                }
            }
        };

        function callback(mentioned: any) {
            let mentionUserEl = '';

            if ('team_name' in mentioned) {
                mentionUserEl = `<post-mention type="team" id="${mentioned.id}" display="${mentioned.tag}"/>`
            } else if ('username' in mentioned) {
                mentionUserEl = `<post-mention type="user" id="${mentioned.id}" display="${mentioned.username}"/>`
            } else {
                return;
            }

            let attachment = new Trix.Attachment({
                content: mentionUserEl,
                contentType: 'application/post-mention'
            });

            let [selectionStart, selectionEnd] = node.editor.getSelectedRange()
            
            for (let i = selectionEnd; i >= 0; i--) {
                if (innerText()[i] === '@') {
                    selectionStart = i;
                    break;
                }
            }

            if (selectionStart === -1) {
                return null; // No '@' found before the current position
            }

            selectionEnd = selectionStart + get(mentionString).length + 1

            node.editor.setSelectedRange([selectionStart, selectionEnd])
            node.editor.deleteInDirection('backward')
            node.editor.insertAttachment(attachment);
            node.editor.insertHTML("&nbsp;")

            mention.set(false)
        }

        function setMention(target) {
            cancelled = false;
            mention.set(target)
            mentionString.set(getMentionSubstring(innerText(), currentPosition))
            mentionCallback.set(callback);
        }

        function clearMention(reason) {
            cancelled = false;
            mention.set(false)
            mentionString.set("");
            mentionCallback.set(null);
        }

        function charCount(text: String) {
            let count = 0
            for (let i = 0; i < text.length; i++) {
                if (text.charCodeAt(i) != 65279) {
                    count++
                }
            }
            return count;
        }

        function innerText(): String {
            let t = ""
            for (let i = 0; i < node.innerText.length; i++) {
                if (node.innerText.charCodeAt(i) != 65279) {
                    t += node.innerText[i]
                }
            }
            return t
        }

        function getMentionSubstring(innerText, currentPosition) {
            let start = -1;
            let len = charCount(innerText);
            let end = len;

            // Search backwards for '@'
            for (let i = currentPosition; i >= 0; i--) {
                if (innerText[i] === '@') {
                    start = i;
                    break;
                }
            }

            if (start === -1) {
                return null; // No '@' found before the current position
            }

            // Search forward for the end of the mention (whitespace or end of string)
            for (let i = start + 1; i < len; i++) {
                if (breakingChars.includes(innerText.charCodeAt(i))) { 
                    end = i;
                    break;
                }
            }

            return innerText.substring(start+1, end); // Extract the mention substring (without the '@')
        }

        const handleKeydown = event => {
            // Handle arrow keys and backspace
            if (['ArrowDown', 'ArrowUp', 'Enter'].includes(event.key) && get(mention) !== false) {
                event.preventDefault()
            }

            if (['ArrowLeft', 'ArrowRight', 'ArrowDown', 'ArrowUp'].includes(event.key) && cancelled) {
                cancelled = false;
            }

            if (event.key == 'Escape' && get(mention) !== false) {
                cancelled = true;
                mention.set(false)
            }
        };

        const checkMentionContext = target => {
            currentPosition = target.editorController.editor.getPosition();

            // if in a space we exit
            if (innerText()[currentPosition] == ' ') {
                clearMention('blank cp');
            }

            if (cancelled) {
                return
            }

            // check backwards until we get to @, if we get to ' ' exit
            for (let i = currentPosition; i > 0; i--) {
                let charCode = innerText().charCodeAt([i-1])
                let notInMention = breakingChars.includes(charCode)
                
                if (notInMention) {
                    clearMention('not in mention')
                    return
                }
                if (innerText()[i-1] == '@') {
                    if (!get(mention)) {
                        setMention(target)
                    }
                    let mentionSearchTerm = getMentionSubstring(innerText(), currentPosition)
                    mentionString.set(mentionSearchTerm)
                    return
                } 
            }

            // if we get to the start of the string and there is no @, exit
            clearMention('end search')
            return
        };

        const tsc = event => {
            checkMentionContext(event.target);
        }

        const handleFocus = event => {
            checkMentionContext(event.target);
        };

        const handleBlur = () => {
            clearMention('blur');
        };

        node.addEventListener('trix-change', handleInput);
        node.addEventListener('trix-selection-change', tsc);
        node.addEventListener('focus', handleFocus);
        node.addEventListener('blur', handleBlur);
        node.addEventListener('keydown', handleKeydown);

        return {
            destroy() {
                node.removeEventListener('trix-change', handleInput);
                node.removeEventListener('trix-selection-change', tsc);
                node.removeEventListener('focus', handleFocus);
                node.removeEventListener('blur', handleBlur);
                node.removeEventListener('keydown', handleKeydown);
            }
        };
    }
</script>

<svelte:options customElement={{
    tag: "post-mention",
    props: {
        display: { reflect: true, type: 'String', attribute: 'display' }
    }
}}/>

<script lang="ts">
    import {onMount} from 'svelte';
    import {isDef} from '../../utils.js'

    export let type: String;
    export let id: Number;
    export let display: String;

    let body;

    onMount(async () => {
        if (!isDef(display)) {
            const resp = await fetch(`http://localhost:8000/api/${type}_id/${id}`)
            if (resp.ok) {
                body = await resp.json()

                if ('tag' in body) {
                    display = body.tag;
                } else if ('username' in body) {
                    display = body.username;
                }
            }
        }
    })

    function clicked(e) {
        console.log('clicked LOL');
        e.preventDefault();
    }

    function hovered(e) {
        console.log("HOVVERED")
    }
</script>

<style>
    a {
        color: var(--link-color);
	    text-decoration: none;
    }

    .mention {
        font-weight: 600;
        background-color: var(--mention-background);
        padding: 1px 2px;
        cursor: pointer;
    }

    .mention:hover {
        background-color: var(--mention-hover);
    }
</style>

<span on:click={clicked} on:mouseover={hovered} class="mention">@{display}</span>
