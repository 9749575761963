<script>
    import GamePath from '../components/electron/GamePath.svelte';
    import { accessToken, userData, games, IS_ELECTRON, userKV, RELATIVE_PATH, styles } from '../stores.js';
    import {styleDefaults} from '../components/Theme.svelte';
    import {onMount} from 'svelte';
    import Page from '../components/Page.svelte';
    import FeatureFlag from '../components/FeatureFlag.svelte';
    import {isDef, titlecase} from '../utils.js';
    import ButtonLabel from '../components/ButtonLabel.svelte';
    import Loading from '../components/Loading.svelte';
    import {tooltip} from '../components/Tooltip';
    import AdminOnly from '../components/AdminOnly.svelte';

    let username = '';
    let error = undefined;

    $: urbanTerror = $games.find(g => g.game_name == 'Urban Terror')

    let selectedClient = {}
    let ready = false;

    async function changeUsername(e) {
        e.preventDefault();

        console.log($accessToken);
        const response = await fetch('http://localhost:8000/api/me/username', {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + $accessToken,
            },
            body: JSON.stringify({username: username})
        });

        if (response.ok) {
            console.log(username)
            userData.update(userData => {
                userData.username = username;
                console.log(userData.alias)
                return userData;
            });
            console.log($userData)
        } else {
            const body = await response.json();
            error = body.detail || "Unable to update username";
        }
    }

    onMount(async () => {
        Object.keys($userKV).forEach(key => {
            if (key.startsWith('client:')) {
                selectedClient[key] = $userKV[key]
            }
        })
        ready = true;
    });

    async function updateKV(key, val) {
        const response = await fetch('http://localhost:8000/api/user/kv', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + $accessToken,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                key: key,
                value: val
            })
        })
        if (response.ok) {
            console.log(`Updated ${key}`)
            $userKV[key] = val
        } else {
            console.log(`Failed to update ${key}`)
        }
    }

    async function getGameClients(gameId) {
        const response = await fetch(`http://localhost:8000/api/gameclients/${gameId}`, {
            headers: {
                'Authorization': `Bearer ${$accessToken}`
            }
        })
        if (response.ok) {
            const body = await response.json();
            if (!(`client:${gameId}` in $userKV)) {
                const clientId = body.find(c => c.default).id;
                console.log("CLIENT_ID", clientId)
                selectedClient[gameId] = clientId;
            } else {
                selectedClient[gameId] = $userKV[`client:${gameId}`]
            }
            return body
        }
    }

    async function getClient(clients, id) {
        const client = clients.find(c => c.id == id)
        return Promise.resolve(client)
    }

    function cssVarToTitle(name) {
        return titlecase(name.replace("--", "").replace("-", " "))
    }
</script>

<style>
    .setting {
        background-color: rgba(0,0,0,0.2);
        padding: 1em;
        margin: 0.5em;
        border-radius: 3px;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1em;
        /* justify-content: center; */
        vertical-align: middle;
        align-items: center;
    }
    .setting-item {
        flex-grow: 1;
        flex-basis: 50%;
    }

    .setting-item:after {
        content: "";
        flex: auto;
    }
    h2, h3 {
        display: inline-block;
        margin-left: 0.5em;
        margin-bottom: 0em;
    }

    label:hover {
        background-color: var(--background-highlight);
        cursor: pointer;
    }

    hr {
        border: none;
        outline: none;
        width: 20%;
        border-bottom: 3px solid var(--background-dark);
        margin: 2em auto;
    }

    .tag {
        font-weight: 600;
    }
</style>

<Page title="Settings">
    <h1>Settings</h1>
    {#if $userData != null && $userData.username == null}
        <form class="loginForm" on:submit={changeUsername}>
            <h2>Set Username</h2>
            <input type="text" placeholder="Username" bind:value={username} minlength="2" maxlength="32" required autofocus/>
            <button type="submit">Save username</button>
            {#if error != undefined}
                <p class:error>{error}</p>
            {/if}
        </form>
    {:else}
        <h2>
            {#if $IS_ELECTRON}<img src="{$RELATIVE_PATH}/bullets.svg" alt={'FTW Bullets'} width="28"/>{/if}
            App
        </h2><br/>
        <div class="setting">
            <div class="split" style="align-items: center; justify-content: center;">
                <span class="button-group">
                    <input id="buttonLabels" type="checkbox" bind:checked={$userKV.hideButtonLabels} on:change={() => updateKV('hideButtonLabels', $userKV.hideButtonLabels)} hidden/>
                    <label for="buttonLabels" class="tag" style="vertical-align: middle; justify-content: center; align-items: center; margin-right: 0.5em;">🏷️ Hide Button Labels</label>
                </span>

                <span style="margin-left: auto; border-left: 2px solid rgba(255,255,255,0.1)">
                    <button><ButtonLabel label="Preview">🤔</ButtonLabel></button>
                </span>
            </div>
        </div>

        <div class="setting">
            <div class="split" style="align-items: center; justify-content: center;">
                <span style="margin-right: 0.25em;" class="button-group">
                    <input id="experimentalFeatures" type="checkbox" bind:checked={$userKV.beta} on:change={() => updateKV('beta', $userKV.beta)} hidden/>
                    <label for="experimentalFeatures" class="tag">🧪 Early Access</label>
                </span>
                <span style="margin-left: auto; border-left: 2px solid rgba(255,255,255,0.1)">
                    <p style="margin: 0.25em; margin-left: 0.5em;">Opt in to <strong>Early Access</strong> features.<br/><em>Why wait? Be an early adopter!</em></p>
                </span>
            </div>
        </div>

        <FeatureFlag flag="theme-editor">
            <div>
                {#each Object.keys($styles) as css}
                    {#if $styles[css].startsWith('#')}
                        <div>{cssVarToTitle(css)} 
                            <input type="color" bind:value={$styles[css]} />
                            {$styles[css]}
                            {#if $styles[css] != styleDefaults[css]}
                                <button on:click={() => $styles[css] = styleDefaults[css]}>Default</button>
                            {/if}
                        </div><br/>
                    {/if}
                {/each}
            </div>
        </FeatureFlag>


        <AdminOnly>
            <div class="setting">
                <div class="split" style="align-items: center; justify-content: center;">
                    <span style="margin-right: 0.25em;" class="button-group">
                        <input id="devMode" type="checkbox" bind:checked={$userKV.devMode} on:change={() => updateKV('devMode', $userKV.devMode)} hidden/>
                        <label for="devMode" class="tag">🛠️ Developer Mode</label>
                    </span>
                    <span style="margin-left: auto; border-left: 2px solid rgba(255,255,255,0.1)">
                        <p style="margin: 0.25em; margin-left: 0.5em;">Enables developer mode</p>
                    </span>
                </div>
            </div>
        </AdminOnly>

        {#if $IS_ELECTRON && ready}
            <hr/>
            <h2>
                <img src="{$RELATIVE_PATH}/logos/urban-terror.png" alt={urbanTerror.game_name} width="28" style="vertical-align: sub;"/>
                Urban Terror
            </h2><br/>
            <h3>Set Game Installation Folder</h3>
            <div class="setting" use:tooltip={'ex: C:\\UrbanTerror43'}>
                <FeatureFlag flag="game-install">
                    <span>
                        <div class="setting-item"><GamePath gameId="all"/></div>
                    </span>
                    <span slot="else">
                        {#if isDef(urbanTerror)}
                            <div class="setting-item"><GamePath gameId={urbanTerror.id}/></div>
                        {/if}
                    </span>
                </FeatureFlag>
            </div>

            {#each $games as game}
                {#await getGameClients(game.id)}
                    <Loading/>
                {:then gameClients}
                    <h3>Select Client</h3>
                    <div class="setting">
                        <div class="split" style="align-items: center; justify-content: center;">
                            <span style="margin-right: 1em;">
                                <span class="button-group">
                                    {#each gameClients as gameClient}
                                        <input id="client-{game.id}-{gameClient.id}" type="radio"
                                               bind:group={selectedClient[game.id]} value={gameClient.id} hidden
                                               on:change={() => updateKV(`client:${game.id}`, selectedClient[game.id])} />
                                        <label for="client-{game.id}-{gameClient.id}" use:tooltip={selectedClient[game.id] == gameClient.id ? 'Current Client' : `Change to ${gameClient.name}`}>
                                            {gameClient.name}
                                        </label>
                                    {/each}
                                </span>
                            </span>

                            <span style="margin-left: auto;">
                                {#await getClient(gameClients, selectedClient[game.id])}
                                    <Loading/>
                                {:then client}
                                    {#if isDef(client)}
                                        {#if isDef(client.name) && isDef(client.publisher)}
                                            <h4>{client.name} {#if client.name != client.publisher}by {client.publisher}{/if}</h4>
                                        {/if}

                                        {#if isDef(client.description)}
                                            <p>{client.description}</p>
                                        {/if}
                                    {/if}
                                {/await}
                            </span>
                        </div>
                    </div>
                {/await}
            {/each}
        {/if}
    {/if}
</Page>
