<script>
    import {onMount, createEventDispatcher} from 'svelte';
    import {makeGamePathKey} from '../../utils.js';
    import {ipc} from '../../stores.js';

    export let gameId = null;
    export let path = null
    export let hidePath = false;

    const dispatch = createEventDispatcher()

    $: key = makeGamePathKey(gameId);

    function getPath(filepath) {
        const path = require('path');
        return path.dirname(filepath)
    }

    async function updatePath(e) {
        path = getPath(e.target.files[0].path)
        const setting = {
            key: key,
            value: path
        };
        await $ipc.send('setSetting', setting);
        dispatch('set');
    }

    onMount(async () => {
        if (gameId) {
            path = await $ipc.send('getSetting', key);
        }
        $ipc.on(key, async (_, value) => {
            path = value;
        });
    });
</script>

<label style="display: inline;">
    <span>
        <span class="monospace">{#if !hidePath}{path}{/if}</span>
        <span class="button" style="background-color:#111;">Edit</span></span>
    <input type="file" webkitdirectory="true" directory on:change={updatePath} hidden/>
</label>
