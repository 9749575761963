<script>
    import { games, gameTypes } from '../stores.js';
    import Loading from '../components/Loading.svelte'
    import MatchScroller from '../components/MatchScroller.svelte'
    import Page from '../components/Page.svelte'
    import {isDef, slugify} from '../utils.js'
    import TimeSince from '../components/TimeSince.svelte'
    import OnlineIndicator from '../components/OnlineIndicator.svelte';
    import FeatureFlag from '../components/FeatureFlag.svelte';
    import AvatarStack from '../components/AvatarStack.svelte';

    export let gameName;

    $: game = $games.filter(game => slugify(game.game_name) === gameName)[0] || null;

    async function getLiveMatches() {
      const response = await fetch('http://localhost:8000/api/gameserver/active')
      console.log(response.status)
      if (response.ok) {
        const servers = await response.json();
        return servers;
      }
    }
</script>

<style>
    h1:first-of-type {
        margin-top: 0;
    }
    h1, h2 {
        margin-top: 1em;
        margin-left: 0.25em;
    }
</style>

<Page title="Recent Matches {isDef(game) ? `(${game.abbreviation})` : ''}" description="Recent matches played on FTW.">
  {#if $games.length == 0 || $gameTypes.length == 0 || !isDef(game)}
    <Loading/>
  {:else}
    <FeatureFlag flag="live-matches">
      {#await getLiveMatches()}
        <Loading/>
      {:then servers}
        {#if servers && Object.keys(servers).length > 0}
        <h1>Live Matches</h1>
        <div class="box-container overflow-scroll">
          {#each Object.values(servers) as server}
            <div class="box">
              <OnlineIndicator status={server.status.BlueReady === true && server.status.RedReady === true ? "Live" : "Warmup"}/>
              <strong>{server.status.GameType}</strong>
              {#if isDef(server.status.Started)}
                <TimeSince short date={new Date(server.status.Started)}/>
              {/if}
              <br/>
              {server.config.ip} {server.config.port}
              {server.status.BlueScore} to {server.status.RedScore}<br/>
              {server.status.Map}<br/>
              <AvatarStack users={server.avatars}/>
            </div>
          {/each}
        </div>
        {/if}
      {/await}
    </FeatureFlag>

    <h1>Recent Matches</h1>
    {#each $gameTypes.filter(gt => gt.game_id == game.id) as gameType}
        {#if gameType['latest_match']}
          <h2>{gameType.game_type}</h2>
          <MatchScroller gameType={gameType} matchType="recent"/>
        {/if}
    {/each}
  {/if}
</Page>
