<script>
    import {userKV} from '../stores.js';
    import { tooltip } from './Tooltip.js';
    import ClickToCopy from './ClickToCopy.svelte';

    export let tooltipText = ""
    export let value = ""
</script>

<style>
    span {
        cursor: pointer;
        font-weight: 200;
        padding: 1px;
    }
</style>

{#if $userKV.devMode && value && String(value).length > 0}
    <span class="tag" use:tooltip={tooltipText}>
        <ClickToCopy text={String(value)} {tooltipText}/>
    </span>
{/if}
