<script>
    import {userKV} from '../stores.js'
    import {tooltip} from './Tooltip';

    export let label;
</script>

<span use:tooltip={$userKV.hideButtonLabels ? label : null}>
    <slot/>
    {#if !$userKV.hideButtonLabels} {label}{/if}
</span>
