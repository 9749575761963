<script>
    import Avatar from './Avatar.svelte';
    import {mention, mentionString, mentionCallback} from '../stores.js';
    import {isDef} from '../utils.js'

    let results = []
    let selection = 0

    async function search(searchTerm) {
        if (!isDef(searchTerm) || searchTerm.length == 0) {
            return []
        }
        const response = await fetch(`http://localhost:8000/api/search/${searchTerm}`)
        if (response.ok) {
            const res = await response.json();
            if (selection > res.users.length) selection = 0
            results = res.users.concat(res.teams)
            return results
        } else {
            return []
        }
    }

    function clickHandler(event) {
        event.preventDefault();
        $mentionCallback(results[selection])
    }

    function keydownHandler(event) {
        if ($mention === false) {
            return;
        }

        switch (event.keyCode) {
            case 40: // down
            if (selection == undefined)
                selection = 0;
            else {
                selection++
                if (selection >= results.length) selection = 0
            }
            break;

            case 38: // up
            if (selection == undefined)
                selection = results.length - 1;
            else {
                selection--;
                if (selection < 0) selection = results.length - 1
            }
            break

            case 13: // enter
                $mentionCallback(results[selection])
            break;
        }
    }
</script>

<style>
    .container {
        position: fixed;
        z-index: 9999999999999;
        display: block;
        border: 2px solid var(--background-mid);
        border-radius: 5px;
        background-color: var(--background-color);
        /* padding: 0.25em; */
    }

    .row {
        padding: 0.25em;
        cursor: pointer;
    }

    .row:not(:last-child) {
        border-bottom: 2px solid var(--background-mid);
    }

    .selected {
        outline: 2px solid orange !important;
        border-radius: 3px;
    }
</style>

<svelte:window on:keydown={keydownHandler}/>

{#if $mention !== false}
    {#await search($mentionString)}
        {#if results.length}
            <div class="container" style="left: {$mention.offsetLeft}px; top: 0; width: {$mention.offsetWidth}px;">
                {#each results as row, i}
                    {#if 'team_name' in row}
                        <div class="row" class:selected={i == selection} on:pointerdown={clickHandler} on:mouseover={() => selection = i}>{row.team_name} ({row.tag})</div>
                    {:else}
                        <div class="row" class:selected={i == selection} on:pointerdown={clickHandler} on:mouseover={() => selection = i}><Avatar userId={row.id} username={row.username} avatar={row.avatar} online={row.online} small/> {row.username}</div>
                    {/if}
                {/each}
            </div>
        {/if}
    {:then results}
        {#if results.length}
            <div class="container" style="left: {$mention.offsetLeft}px; top: 0; width: {$mention.offsetWidth}px;">
                {#each results as row, i}
                    {#if 'team_name' in row}
                        <div class="row" class:selected={i == selection} on:pointerdown={clickHandler} on:mouseover={() => selection = i}>{row.team_name} ({row.tag})</div>
                    {:else}
                        <div class="row" class:selected={i == selection} on:pointerdown={clickHandler} on:mouseover={() => selection = i}><Avatar userId={row.id} username={row.username} avatar={row.avatar} online={row.online} small/> {row.username}</div>
                    {/if}
                {/each}
            </div>
        {/if}
    {/await}
{/if}
