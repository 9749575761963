<script>
    import {RELATIVE_PATH, accessToken, inGameId, launch, electronUpdate, ipc} from '../../stores.js'
    import {tooltip, TooltipPosition} from '../Tooltip.ts';
    import {isDef, slugify} from '../../utils.js';

    export let game = null;

    async function launchGame() {
        if ($electronUpdate) {
            $ipc.send('do-update');
        }

        const response = await fetch(`http://localhost:8000/api/games/${game.id}/launch`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${$accessToken}`,
                'Content-Type': 'application/json'
            }
        });
        if (!response.ok) {
            return;
        }
        inGameId.set(game.id);
        launch.set(true);
    }

    function tooltipText(inGameId, electronUpdate) {
        if (inGameId) {
            return 'Already in game';
        }
        if (electronUpdate) {
            return 'Update Launcher';
        }
        return `Launch ${game.game_name}`;
    }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
{#key $inGameId}
    <div on:click={() => launchGame()} class="sidebar-button"
        style="cursor: {$inGameId == null && !$electronUpdate ? 'pointer' : 'not-allowed'};"
        use:tooltip={{text: tooltipText($inGameId, $electronUpdate), pos: TooltipPosition.Right}}>
        <div class="sidebar-button" style={`background-image: url(${$RELATIVE_PATH}/logos/${slugify(game.game_name)}.png)`}></div>
    </div>
{/key}
