export function slugify(input) {
  if (!isDef(input)) {
    return '';
  }
  // Remove all non-alphanumeric characters and replace them with hyphens
  input = input.replace(/[^a-zA-Z0-9]/g, '-');

  // Replace multiple hyphens with a single hyphen
  input = input.replace(/-+/g, '-');

  // Trim string
  input = input.trim('-');

  // Remove any leading/trailing hyphens
  input = input.replace(/-$/, '');
  input = input.replace(/^-/, '');

  // Convert the input string to lowercase
  input = input.toLowerCase();

  return input;
}

export function deslugify(name) {
  if (!isDef(name)) {
    return '';
  }
  return name.replaceAll('-', ' ').replaceAll('_', ' ');
}

export function titlecase(str) {
    return str.replace(/\w\S*/g, function(txt){
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }).replace('_', ' ');
}

export function uppercase(string) {
  const words = string.split(' ');
  const transformedWords = words.map((word) => {
    const firstChar = word[0].toUpperCase();
    const rest = word.slice(1).toLowerCase();
    return firstChar + rest;
  });
  return transformedWords.join(' ');
}

export const zeroPad = (num, places) => String(num).padStart(places, '0')

export function metricToGrade(value, max_stat) {
  if (value > max_stat) {
    return 'S';
  } else if (value > max_stat * 0.75) {
      return 'A';
  } else if (value > max_stat * .5) {
      return 'B';
  } else if (value > max_stat * .25) {
      return 'C';
  } else if (value > 0 ) {
      return 'D';
  } else if (value <= 0) {
      return 'F';
  }else {
      return '';                                
  }
}

export function metricToGradeInverse(value, max_stat, min_stat) {
  if (value < min_stat) {
    return 'S';
  } else if (value < min_stat / 0.75) {
      return 'A';
  } else if (value > min_stat / .5) {
      return 'B';
  } else if (value > min_stat / .25) {
      return 'C';
  } else if (value > min_stat / 0.5 ) {
      return 'D';
  } else if (value > max_stat) {
      return 'F';
  } else {
    return  '';
  }
}

export function parseMatchRound(round) {
    if (/^\+?(0|[1-9]\d*)$/.test(round)) {
        return `Week ${round}`
    } else {
        switch(round.toLowerCase()) {
            case 'q':
                return "Quarter Finals"
            case 's':
                return "Semi Finals"
            case 'f':
                return "Finals"
            case 'g':
                return "Grand Finals"
            default:
                return round
        }
    }
}

const MONTH_NAMES = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

export function fieldExistsOrZero(dict, key) {
  if (!(key in dict)) {
      return 0;
  } else {
      return dict[key];
  }
}
  
export function getFormattedDate(date, prefomattedDate = false, hideYear = false) {
    const day = date.getDate();
    const month = MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();
  
    if (minutes < 10) {
      // Adding leading zero to minutes
      minutes = `0${ minutes }`;
    }
  
    if (prefomattedDate) {
      // Today at 10:20
      // Yesterday at 10:20
      return `${ prefomattedDate } at ${ hours }:${ minutes }`;
    }
  
    if (hideYear) {
      // 10. January at 10:20
      return `${ day }. ${ month } at ${ hours }:${ minutes }`;
    }
  
    // 10. January 2017. at 10:20
    return `${ day }. ${ month } ${ year }. at ${ hours }:${ minutes }`;
  }
  
  
  // --- Main function
  export function timeAgo(dateParam) {
    if (!dateParam) {
      return null;
    }
  
    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();
  
  
    if (seconds < 5) {
      return 'now';
    } else if (seconds < 60) {
      return `${ seconds } seconds ago`;
    } else if (seconds < 90) {
      return 'about a minute ago';
    } else if (minutes < 60) {
      return `${ minutes } minutes ago`;
    } else if (isToday) {
      return getFormattedDate(date, 'Today'); // Today at 10:20
    } else if (isYesterday) {
      return getFormattedDate(date, 'Yesterday'); // Yesterday at 10:20
    } else if (isThisYear) {
      return getFormattedDate(date, false, true); // 10. January at 10:20
    }
  
    return getFormattedDate(date); // 10. January 2017. at 10:20
  }

export function makeGamePathKey(gameId) {
  return `gamePath:${gameId}`
}

export class MaxValueTracker {
  constructor() {
      this.memoizedMaxStat = {}
  }
  
  reset() {
      this.memoizedMaxStat = {}
  }

  memoizeMaximum(key, value, toFixedValue=null) {
      if (value <= 0) {
        return value;
      }
      if ((!(key in this.memoizedMaxStat) && value > 0) || value > this.memoizedMaxStat[key]) {
          this.memoizedMaxStat[key] = value
      }
      if (isDef(value) && toFixedValue != null && toFixedValue >= 0) {
        value = value.toFixed(toFixedValue)
      }
      if (typeof value == 'number') {
          if (isNaN(value)) value = 0
          return Number.isInteger(value) ? value : value.toFixed(2)
      } else {
          return value
      }
  }

  isMax(key, value) {
      if (this.memoizedMaxStat[key] == value) {
          return true
      } else {
          return false
      }
  }
}

export function isDef(value) {
  return value && value != null && value != undefined
}

export function readURLParams() {
  // Get the current URL
  let url = window.location.href;

  // Extract the query string from the URL
  let queryString = url.split('?')[1];

  // If there is no query string, return an empty object
  if (!queryString) {
    return {};
  }

  // Split the query string into an array of key-value pairs
  let params = queryString.split('&');

  // Create an object to store the parameters
  let paramMap = {};

  // Iterate over the array of key-value pairs
  params.forEach(param => {
    // Split the pair into a key and a value
    let [key, value] = param.split('=');

    // Decode the key and value
    key = decodeURIComponent(key);
    value = decodeURIComponent(value);

    // Set the value in the paramMap object
    paramMap[key] = value;
  });

  // Return the paramMap object
  return paramMap;
}

export function getGameType(num) {
  switch(num) {
      case 0:
          return ['Free For All', 'FFA']
      case 1:
          return ['Last Man Standing', 'LMS']
      case 2:
          return ['Free for All', 'FFA']
      case 3:
          return ['Team Deathmatch', 'TDM']
      case 4:
          return ['Team Survivor', 'TS']
      case 5:
          return ['Follow the Leader', 'FTL']
      case 6:
          return ['Capture & Hold', 'C&H']
      case 7:
          return ['Capture the Flag', 'CTF']
      case 8:
          return ['Bomb & Defuse', 'Bomb']
      case 9:
          return ['Jump Mode', 'Jump']
      case 10:
          return ['Freeze Tag', 'Freeze']
      case 11:
          return ['Gun Game', 'Gun Game']
      default:
          return ['','']
  }
}

export function ipOnly(ip) {
  //strips port
  return ip.split(':')[0]
}

export function generateRandomString(length) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function formatDateToUTC(date) { // ISO 8601
  let year = date.getUTCFullYear();
  let month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  let day = date.getUTCDate().toString().padStart(2, '0');
  let hours = date.getUTCHours().toString().padStart(2, '0');
  let minutes = date.getUTCMinutes().toString().padStart(2, '0');
  let seconds = date.getUTCSeconds().toString().padStart(2, '0');
  let milliseconds = date.getUTCMilliseconds().toString().padStart(6, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
}

export function search(needle, haystack) {
  var tlen = haystack.length;
  var qlen = needle.length;
  if (qlen > tlen) {
      return false;
  }
  if (qlen === tlen) {
      return needle === haystack;
  }
  outer: for (var i = 0, j = 0; i < qlen; i++) {
      var nch = needle.charCodeAt(i);
      while (j < tlen) {
      if (haystack.charCodeAt(j++) === nch) {
          continue outer;
      }
      }
      return false;
  }
  return true;
}
