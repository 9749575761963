<script>
    import {RELATIVE_PATH} from '../stores.js'
    import {slugify, isDef} from '../utils.js'
    import {tooltip} from './Tooltip'

    import Loading from './Loading.svelte'
    import LeagueTypeBadge from './LeagueTypeBadge.svelte'
    import TimeSince from './TimeSince.svelte'
    import AvatarStack from './AvatarStack.svelte'

    import { Link } from 'svelte-routing';

    let loading = true;

    export let gameType = undefined;
    export let userId = undefined;
    export let teamId = undefined;
    export let matchType = undefined;

    async function getMatches(gameType, userId, teamId) {
        if (gameType) {
            return await getRecentMatches(gameType['id'])
        } else if (userId) {
            return await getUserMatches(userId)
        } else if (teamId) {
            return await getTeamMatches(teamId)
        }
    }

    async function getUserMatches(userId, page=0) {
        const response = await fetch(`http://localhost:8000/api/match/user/${matchType}/${userId}/${page}`)

        loading = false;

        if (response.ok) {
            const matches = await response.json();
            console.log(matches)
            return matches;
        } else {
            throw "Failed to get userMatches"
        }
    }

    async function getTeamMatches(teamId, page=0) {
        const response = await fetch(`http://localhost:8000/api/match/team/${matchType}/${teamId}/${page}`)

        loading = false;

        if (response.ok) {
            const matches = await response.json();
            console.log(matches)
            return matches;
        } else {
            throw "Failed to get teamMatches"
        }
    }

    async function getRecentMatches(gameTypeId, page=0) {
        const response = await fetch(`http://localhost:8000/api/match/gametype/${matchType}/${gameTypeId}/${page}`);

        loading = false;

        if (response.ok) {
            const matches = await response.json();
            console.log(matches)
            return matches;
        } else {
            throw "Failed to get recentMatches"
        }
    }

    function getMatchUsers(user_ids, users) {
        const matchUsers = [];
        user_ids.forEach(user_id => {
            if (user_id in users) {
                matchUsers.push(users[user_id])
            }
        });
        return matchUsers.sort((a,b) => isDef(b.avatar) - isDef(a.avatar));
    }
</script>

<style>
    h2 {
    margin: 1.5em 0 0 0.5em !important;
    }
    h2::first-letter {
    text-transform:capitalize;
    }
    p {
        margin-left: 1em;
    }
    hr {
        width: 30%;
        outline: 0;
        border: 0;
        border-top: 2px solid var(--background-highlight)
    }
</style>

{#if gameType || userId || teamId && !(gameType && userId && teamId)}
    {#await getMatches(gameType, userId, teamId)}
        <Loading/>
        <div class="skeleton" style="height: 160px"></div>
    {:then matches}
        {#if matches[matchType].length > 0}
            <div class="box-container overflow-scroll">
                {#if matches[matchType].length == 0}
                    <p>No Upcoming Matches</p>
                {/if}
                {#each Object.values(matches[matchType]) as match}
                    <div class="box">
                        <Link to="/match/{match.id}">
                            <LeagueTypeBadge league_type={match.league_type} float/>
                            {#if 'players' in match}
                                {#each match.players as player, i}
                                    <strong>{player.username}</strong>
                                    {#if i != match.players.length - 1} vs.&nbsp;{/if}
                                {/each}
                            {:else}
                                {#each match.teams as team, i}
                                    <strong>{team.tag || "pickup"}</strong>
                                    {#if i != match.teams.length - 1} vs.&nbsp;{/if}
                                {/each}
                            {/if}
                            {#if 'game_name' in match}
                                <br/>
                                <span
                                    use:tooltip={match.game_name}
                                    style={`display: inline-block; vertical-align: middle; width: 20px; height: 20px; background-size: cover; background-image: ${`url(${$RELATIVE_PATH}/logos/${slugify(match.game_name)}.png)`}`}>
                                </span>
                                {match.abbreviation}: {match.season}
                            {/if}
                            {#if match.match_date}
                                <br/><TimeSince dateString={match.match_date}/>
                            {/if}
                        </Link>
                        {#if isDef(match.user_ids) && match.user_ids.length && isDef(matches.users)}
                            {#key match.user_ids}
                                <hr/>
                                <AvatarStack users={getMatchUsers(match.user_ids, matches.users)}/>
                            {/key}
                        {/if}
                    </div>
                {/each}
            </div>
        {:else}
            <p>No {matchType} matches</p>
        {/if}
    {:catch e}
        Error {e}
    {/await}
{/if}
