<script lang="ts">
    import {games, gameTypes, leagueUsers, selectedGameId} from '../../stores.js'
    import {isDef, fieldExistsOrZero, MaxValueTracker, titlecase, readURLParams} from '../../utils.js';

    import Loading from '../Loading.svelte';
    import LeagueTypeBadge from '../LeagueTypeBadge.svelte';
    import DemoButton from '../DemoButton.svelte';
    import TimeSince from '../TimeSince.svelte';
    import UserLink from '../UserLink.svelte';
    import {tooltip} from './../Tooltip.ts';
    import Filters from './Filters.svelte';
    import {applyFiltersToURL} from './Filters.svelte';

    import {Link} from 'svelte-routing';

    export let userIds: any = 'all';
    export let leagueUserId = null;
    export let leagueId = null;
    export let teamId = null;

    let matchPage = 1;
    let matchSortDesc = true;
    let matchSortCol = 'match_round_id';
    let maxValues = new MaxValueTracker()
    export let baseUrl;
    export let hidePlayerCol = false;

    let ready = false;
    let gameMode = null;
    let statisticMap = null;
    let filters = null;

    async function getStats(user_ids, page, order_by, direction, gameMode, filters, ready) {
        if (!ready || !isDef(filters) || !isDef(gameMode)) {
            return
        }

        let request_url = `http://localhost:8000/api/stats/matches?f=1`

        if (isDef(leagueId)) {
            request_url += `&league_id=${leagueId}`
        }

        if (isDef(teamId)) {
            request_url += `&team_id=${teamId}`
        }

        if (page != 1) {
            request_url += `&page=${page}`
        }
        if (order_by != 'match_round_id') {
            request_url += `&order=${order_by}`
        }
        if (user_ids != 'all' && user_ids.length > 0) {
            request_url += `&user_ids=${user_ids}`
        }

        request_url = applyFiltersToURL(request_url, leagueUserId, gameMode, filters)

        const response = await fetch(request_url);
        if (response.ok) {
            return await response.json();
        }
    }

    async function getCount(user_ids, gameMode, filters, ready) {
        if (!ready || !isDef(gameMode)) {
            return
        }
        let request_url = `http://localhost:8000/api/stats/matches/count?f=1`

        if (user_ids != 'all' && user_ids.length > 0) {
            request_url += `&user_ids=${user_ids}`
        }

        request_url = applyFiltersToURL(request_url, leagueUserId, gameMode, filters)

        const response = await fetch(request_url);
        if (response.ok) {
            return await response.json();
        }
    }

    function sortMatch(column) {
        if (matchSortCol != column) {
            matchSortCol = column
            matchSortDesc = true
            matchPage = 1
        } else {
            matchSortDesc = !matchSortDesc
        }
    }
</script>

<div>
    <h2>Game Log</h2>

    <Filters {baseUrl} {userIds} {leagueId} {teamId} {leagueUserId} 
        disableLeagueFilter={isDef(leagueId)}
        disableGameTypeFilter={isDef(leagueId)}
        on:ready={() => ready = true} 
        on:gameMode={(gm) => gameMode = gm.detail}
        on:statisticMap={(sm) => statisticMap = sm.detail}
        on:filterChanged={(f) => {
            console.log("FILTER CHANGED", f.detail)
            filters = f.detail;
        }}/>

    {#await getStats(userIds, matchPage, matchSortCol, matchSortDesc, gameMode, filters, ready)}
        <Loading/>
        <div class="skeleton"></div>
        <div class="skeleton" style="height: 505px;"></div>
    {:then matches}
        {#if isDef(matches) && isDef(gameMode) && isDef(statisticMap)}
            <div class="table-wrapper overflow-scroll">
                <table>
                    <thead>
                        {#if isDef(gameMode.category_spans) && gameMode.category_spans.length > 0}
                            <tr class="category">
                                <th colspan={hidePlayerCol ? 4 : 6}>Match</th>
                                {#each gameMode.category_spans as category_span, idx}
                                    <th colspan={category_span}>{gameMode.category_names[idx]}</th>
                                {/each}
                            </tr>
                        {/if}
                        <tr class="headings">
                            <th class="headcol sortable" colspan="3" on:click={() => sortMatch('match_round_id')} class:asc={!matchSortDesc} class:desc={matchSortDesc} class:selected={matchSortCol=='match_round_id'} on:click={() => sortMatch('match_round_id')}>Match</th>
                            {#if !hidePlayerCol}
                                <th>Player</th>
                            {/if}
                            <th use:tooltip={'Demo Replay'}>R</th>
                            <th use:tooltip={'Anticheat'}>AC</th>
                            <!-- <th style="background-color: var(--table-heading-background)">Alias</th> -->
                            {#each gameMode.statistic_ids as statistic_id}
                                {@const acronym = statisticMap[statistic_id]['acronym']}
                                <th use:tooltip={statisticMap[statistic_id]['stat']}
                                    class="sortable"
                                    class:asc={!matchSortDesc}
                                    class:desc={matchSortDesc} 
                                    class:selected={matchSortCol==acronym} 
                                    on:click={() => sortMatch(acronym)}>
                                    {acronym}
                                </th>
                            {/each}
                        </tr>
                    </thead>
                    <tbody>
                        {#each matches.matches as match}
                            {#if match['K'] !== undefined}
                                <tr>
                                    <td class="left-text nowrap">
                                        <LeagueTypeBadge league_type={match['league_type']}/>
                                        {#if match['league_user_id'] in $leagueUsers}
                                            {@const leagueUser = $leagueUsers[match['league_user_id']]}
                                            <UserLink username={leagueUser['username']} userId={leagueUser['id']} avatar={leagueUser['avatar']} avatarOnly/>
                                        {/if}
                                    </td>
                                    <td class="nowrap left-text" style="text-align: left;">
                                        <Link to="/match/{match['match_id']}">
                                            <span use:tooltip={match['map']}>{titlecase(match['map'].split('_')[1])}</span>
                                        </Link>
                                    </td>
                                    <td class="left-text"><TimeSince dateString={match['submitted']} short/></td>

                                    {#if !hidePlayerCol}
                                        <td style="text-align: left">
                                            <UserLink username={match['username']} userId={match['user_id']} avatar={match['avatar']}/>
                                        </td>
                                    {/if}

                                    <td>
                                        <span class="demo-button">
                                            <DemoButton fileHash={match['file_hash']} gameId={match['game_id']} gameName={$games.find(g => g['id'] == match['game_id'])['game_name']}/>
                                        </span>
                                    </td>
                                    <td></td>
                                    {#each gameMode.statistic_ids as statistic_id}
                                        {@const acronym = statisticMap[statistic_id]['acronym']}
                                        {@const statVal = match[acronym]}
                                        <td class:pr={statVal == fieldExistsOrZero(matches.max, acronym)}
                                            class:max={maxValues.isMax(acronym, statVal)}
                                            class:zero={statVal == 0} class="monospace">
                                            {maxValues.memoizeMaximum(acronym, statVal, statisticMap[statistic_id]['decimals'])}
                                        </td>
                                    {/each}
                                </tr>
                            {/if}
                        {/each}
                    </tbody>
                    <tfoot class="footings">
                        <tr>
                            <td></td>
                            <td></td>
                            <td style="text-align: right;" class="nowrap">Average 📊</td>
                            {#if !hidePlayerCol}
                                <td></td>
                            {/if}
                            <td></td>
                            <td></td>
                            {#each gameMode.statistic_ids as statistic_id}
                                {@const acronym = statisticMap[statistic_id]['acronym']}
                                <td class="monospace">
                                    {fieldExistsOrZero(matches.avg, acronym).toFixed(statisticMap[statistic_id]['decimals'])}
                                </td>
                            {/each}
                        </tr>
                        <tr style="border-top: 1px dotted #222;">
                            <td></td>
                            <td></td>
                            <td style="text-align: right;" class="nowrap">Record ⭐</td>
                            {#if !hidePlayerCol}
                                <td></td>
                            {/if}
                            <td></td>
                            <td></td>
                            {#each gameMode.statistic_ids as statistic_id}
                                {@const acronym = statisticMap[statistic_id]['acronym']}
                                <td class="monospace">
                                    {fieldExistsOrZero(matches.max, acronym).toFixed(statisticMap[statistic_id]['decimals'])}
                                </td>
                            {/each}
                        </tr>
                    </tfoot>
                </table>
            </div>
        {:else}
            <div class="empty">No Matches Found</div>
        {/if}
    {/await}

    {#await getCount(userIds, gameMode, filters, ready)}
        <Loading/>
        <div class="skeleton" style="height: 39px"></div>
    {:then data}
        {#if isDef(data)}
            <div class="center pagination overflow-scroll">
                {#each {length: data.count / 10} as _, pageNo}
                    <button class="button" on:click={() => matchPage=pageNo+1} class:current={pageNo+1 == matchPage}>{pageNo+1}</button>
                {/each}
            </div>
        {/if}
    {/await}
</div>
