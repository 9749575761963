<script>
    import Avatar from './Avatar.svelte'
    import HoverPane from './HoverPane.svelte';
    import {isDef, titlecase} from '../utils.js'; 
    import {Link} from 'svelte-routing';
    import Loading from './Loading.svelte';

    export let username = undefined;
    export let userId = undefined;
    export let avatar = undefined;
    export let online = false
    export let anon = undefined;
    export let avatarOnly = false;
    export let noPresence = false;

    const paneHeight = 175, paneWidth = 375;

    let paneData;

    async function getProfilePane() {
        console.log("hover")
        const response = await fetch(`http://localhost:8000/api/user/${username}/hoverpane`);

        if (response.ok) {
            paneData = response.json();
        }
    }
</script>

<style>
    div {
        padding: 0.25em;
        padding-top: 0.5em;
    }
    .vertical-align {
        display: flex;
        align-items: center;
        vertical-align: bottom;
    }
    span {
        font-weight: 600;
    }

    .pane {
        z-index: 999999999999;
    }

    .pane-row {
        display: flex;
        justify-content: space-evenly;
        margin: 0;
        padding: 0.5em;
        vertical-align: middle;
        justify-items: center;
    }

    .pane-col {
        justify-content: space-between;
        margin: 0.25em;
        padding: 0.5em;
        background-color: var(--background-highlight);
        border-radius: 5px;
        flex: 1 1 0px;
        max-height: 50px;
    }
    .pane-col:first-of-type {
        margin-left: 0;
    }
</style>

{#if isDef(username) || isDef(anon) || isDef(avatar) || isDef(userId)}
    <HoverPane height={paneHeight} width={paneWidth} hidePane={!isDef(username)} on:hover={getProfilePane}>
        {#if isDef(username)}
            <Link to="/user/{username}">
                <span class="nowrap">
                    <Avatar avatar={avatar} userId={userId} online={online} username={avatarOnly ? username : null} {noPresence} small inline/>
                    {#if !avatarOnly}&nbsp;{username}{/if}
                </span>
            </Link>
        {:else}
            <Avatar small inline/>&nbsp;
            {anon}
        {/if}

        <div slot="pane" style="height: {paneHeight}px; width: {paneWidth}px;" class="pane">
            <h1 style="margin-top: 0; margin-bottom: 0;" class="vertical-align">
                <Avatar avatar={avatar} userId={userId} online={online} glow={true}/>&nbsp;
                <Link to="/user/{username}">
                    {username}
                </Link>
            </h1>
            <hr style="width: 95%; border: 1px solid var(--background-color);"/>
            {#if isDef(paneData)}
                {#await paneData}
                    <Loading/>
                {:then data}
                    <div class="pane-row">
                        <div class="pane-col">
                            <strong>24H</strong><br/>
                            <span class="monospace">{data.gp['24_hours']}</span>
                        </div>
                        <div class="pane-col">
                            <strong>GP</strong><br/>
                            <span class="monospace">{data.gp['all_time']}</span>
                        </div>
                        {#each data.stats as stat}
                            {#if isDef(stat.avg)}
                                <div class="pane-col">
                                    <strong>{stat.stat}</strong><br/>
                                    <span class="monospace" style="text-align: center;">{stat.avg.toFixed(2)}</span>
                                </div>
                            {/if}
                        {/each}
                    </div>
                {/await}
            {/if}
        </div>
    </HoverPane>
{/if}
