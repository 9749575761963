export var UserRoles;
(function (UserRoles) {
    UserRoles["Admin"] = "admin";
    UserRoles["GameAdmin"] = "game-admin";
    UserRoles["Moderator"] = "moderator";
})(UserRoles || (UserRoles = {}));
export var OnlineStatus;
(function (OnlineStatus) {
    OnlineStatus["Online"] = "Online";
    OnlineStatus["Disconnected"] = "Disconnected";
    OnlineStatus["Offline"] = "Offline";
    OnlineStatus["Away"] = "Away";
    OnlineStatus["Active"] = "Active";
    OnlineStatus["Expired"] = "Expired";
    OnlineStatus["Spawning"] = "Spawning";
    OnlineStatus["Warmup"] = "Warmup";
    OnlineStatus["Live"] = "Live";
})(OnlineStatus || (OnlineStatus = {}));
export function checkThreadAdminPermission(userRole, permission) {
    console.log(userRole, permission);
    if (userRole !== undefined && userRole !== null && Object.values(UserRoles).includes(userRole)) {
        if (permission === null || permission === undefined || permission.length < 1) {
            return true;
        }
        else if (permission.length === 1 && permission[0]['user_role_required'] !== null) {
            return permission[0]['user_role_required'].includes(userRole);
        }
    }
    return false;
}
