import App from './App.svelte';

// Sentry
import * as Sentry from "@sentry/svelte";
Sentry.init({
	dsn: 'https://3668af21a05241479847248594f9e691@sentry.ftwgl.net/30',
	release: 'VERSION_STRING',
	environment: 'ENVIRONMENT',
	tracesSampleRate: 0.0,
	replaysSessionSampleRate: 0.0,
  	replaysOnErrorSampleRate: 0.0,
});

const app = new App({
	target: document.body
});

export default app;